import { Box, Button, Radio, RadioGroup, FormControl, FormControlLabel, Container, TextField, Snackbar, Alert } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { CreateCandidateValidationSchema } from '../../utils/Validations';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { postRequest, getRequestWithToken } from '../../utils/ApiRequests';
import { useState, useEffect } from 'react';
import "./candidates.scss";
import { useLocation } from 'react-router-dom';
const EditCandidate = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [candidateCreated, setCandidateCreated] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [formError, setFormError] = useState(false);
    const [message, setMessage] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const handleSubmit = async (values, { resetForm }) => {
        setFormSubmitted(true);
        let formData = new FormData();
        formData = {
            usertype: "job_seeker",
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            dob: values.date_of_birth,
            mobile: values.mobile_number,
            age: values.age,
            gender: values.gender,
            whatsapp_available: values.whatsapp_available,
            email: values.email_address,
            address: values.address,
            village: values.village,
            past_work_experience: values.experience,
            experience: values.experience,
            school_info: values.school_info,
            certification: values.certificate,
            preferred_job_title: values.preferred_job_title !== undefined ? values.preferred_job_title.split(',') : [" ", " "],
            preference_skills: values.job_perference_skill !== undefined ? values.job_perference_skill.split(',') : [" ", " "],
            preferred_job_location: values.preferred_job_location,
            district: values.district,
            state: values.state

        }

        try {
            let res = await postRequest('admin', formData);
            if (res.status === 'Success') {
                setCandidateCreated(true);
                setFormSubmitted(false);
                resetForm();
            }
            else {
                setFormError(true);
                setMessage(res.message);
                setFormSubmitted(false);
            }
        } catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        let getUserData = async () => {
            try {
                let response = await getRequestWithToken(`/admin/userbyid?userid=${id}&usertype=job_seeker`);
                response = response?.data?.data?.candidate;
                let initialValues = {
                    first_name: response?.first_name,
                    middle_name: response?.middle_name,
                    last_name: response?.last_name,
                    date_of_birth: response.date_of_birth,
                    mobile_number: response?.mobile,
                    age: response?.age,
                    gender: response?.gender,
                    whatsapp_available: response?.whatsapp_available,
                    email_address: response?.email,
                    address: response?.address,
                    village: response?.village,
                    panchayat: response?.panchayat,
                    district: response?.district,
                    state: response?.state,
                    educational_qualifiction: response?.school_info,
                    past_work_experience: response?.past_work_experience,
                    experience: response?.past_work_experience,
                    school_info: response?.school_info,
                    certificate: response?.certificate,
                    job_title: "",
                    job_perference_skill: "",
                    perferred_job_location: ""
                }
                setInitialValues(initialValues)
                console.log(initialValues)
            } catch (err) {
                console.log(err)
            }

        }
        id !== null && getUserData();
    }, [id]);
    return (
        <Box className="create-candidates-section" >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={candidateCreated}
                autoHideDuration={1000}
                onClose={() => setCandidateCreated(false)}
            >
                <Alert severity="success">You will successfully created the candidate.</Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={formError}
                autoHideDuration={3000}
                onClose={() => setFormError(false)}
            >
                <Alert severity="error">{message}</Alert>
            </Snackbar>

            <Container>

                <Box className="create-candidates">
                    <h1>Edit Candidate</h1>
                    <Box className="create-candidates-form-wrapper">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={CreateCandidateValidationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ errors, values, setFieldValue }) => (
                                <Form className="create-candidates-form">
                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="first_name"> First Name *</label>
                                        <Field id="first_name"
                                            type="text" placeholder="Enter First Name"
                                            name="first_name"
                                            className="form-input" />
                                        {errors.first_name && <Box className="form-error">{errors.first_name}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="middle_name"> Middle Name</label>
                                        <Field id="middle_name"
                                            type="text" placeholder="Enter Middle Name"
                                            name="middle_name"
                                            className="form-input" />
                                        {errors.middle_name && <Box className="form-error">{errors.middle_name}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="last_name">Last Name</label>
                                        <Field id="last_name"
                                            type="text" placeholder="Enter Last Name"
                                            name="last_name"
                                            className="form-input" />
                                        {errors.last_name && <Box className="form-error">{errors.last_name}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="date_of_birth">Date of Birth</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={values.date_of_birth}
                                                onChange={(newValue) => {
                                                    setFieldValue("date_of_birth", values.date_of_birth);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                        {errors.date_of_birth && <Box className="form-error">{errors.date_of_birth}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="mobile_number">Mobile Number *</label>
                                        <Field id="mobile_number"
                                            type="text" placeholder="Enter mobile number"
                                            name="mobile_number"
                                            className="form-input" />
                                        {errors.mobile_number && <Box className="form-error">{errors.mobile_number}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="age">Age</label>
                                        <Field id="age"
                                            type="text" placeholder="Enter Age"
                                            name="age"
                                            className="form-input" />
                                        {errors.age && <Box className="form-error">{errors.age}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="gender">Gender</label>
                                        <Field id="gender"
                                            type="text" placeholder="Enter Gender"
                                            name="gender"
                                            className="form-input" />
                                        {errors.gender && <Box className="form-error">{errors.gender}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="whatsapp_available">Whatsapp Availabilty</label>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="whatsapp_available"
                                                value={values.whatsapp_available}
                                                onChange={(e) => setFieldValue('whatsapp_available', e.target.value)}
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="false" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>

                                        {errors.whatsapp_available && <Box className="form-error">{errors.whatsapp_available}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="email_address">Email Address</label>
                                        <Field id="email_address"
                                            type="text" placeholder="Enter Email Address"
                                            name="email_address"
                                            className="form-input" />
                                        {errors.email_address && <Box className="form-error">{errors.email_address}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="address"> Address *</label>
                                        <Field id="address"
                                            type="text" placeholder="Enter  Address"
                                            name="address"
                                            className="form-input" />
                                        {errors.address && <Box className="form-error">{errors.address}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="village"> Village</label>
                                        <Field id="village"
                                            type="text" placeholder="Enter Village"
                                            name="village"
                                            className="form-input" />
                                        {errors.village && <Box className="form-error">{errors.village}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="panchayat"> Panchayat</label>
                                        <Field id="panchayat"
                                            type="text" placeholder="Enter Panchayat"
                                            name="panchayat"
                                            className="form-input" />
                                        {errors.panchayat && <Box className="form-error">{errors.panchayat}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="district"> District *</label>
                                        <Field id="district"
                                            type="text" placeholder="Enter district"
                                            name="district"
                                            className="form-input" />
                                        {errors.district && <Box className="form-error">{errors.district}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="state"> State *</label>
                                        <Field id="state"
                                            type="text" placeholder="Enter state"
                                            name="state"
                                            className="form-input" />
                                        {errors.state && <Box className="form-error">{errors.state}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="educational_qualifiction"> Educational Qualification</label>
                                        <Field id="educational_qualifiction"
                                            type="text" placeholder="Enter qualification"
                                            name="educational_qualifiction"
                                            className="form-input" />
                                        {errors.educational_qualifiction && <Box className="form-error">{errors.educational_qualifiction}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="past_work_experience"> Past Work Experience *</label>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="past_work_experience"
                                            value={values.past_work_experience}
                                            onChange={(e) => setFieldValue('past_work_experience', e.target.value)}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                        </RadioGroup>
                                        {errors.past_work_experience && <Box className="form-error">{errors.past_work_experience}</Box>}
                                    </Box>

                                    {values.past_work_experience === 'yes' && <Box className="form-control">
                                        <label className="form-label" htmlFor="experience"> Experience *</label>
                                        <Field id="experience"
                                            type="text" placeholder="Enter experience"
                                            name="experience"
                                            className="form-input" />
                                        {errors.experience && <Box className="form-error">{errors.experience}</Box>}
                                    </Box>}

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="school_info"> School Information</label>
                                        <Field id="experience"
                                            type="text" placeholder="Enter experience"
                                            name="school_info"
                                            className="form-input" />
                                        {errors.school_info && <Box className="form-error">{errors.school_info}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="certificate">Certificate</label>
                                        <Field id="certificate"
                                            type="text" placeholder="Enter certificate"
                                            name="certificate"
                                            className="form-input" />
                                        {errors.certificate && <Box className="form-error">{errors.certificate}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="job_title">Job Title</label>
                                        <Field id="job_title"
                                            type="text" placeholder="Enter job title"
                                            name="job_title"
                                            className="form-input" />
                                        {errors.job_title && <Box className="form-error">{errors.job_title}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="job_perference_skill">Job Perference Skill *</label>
                                        <Field id="job_perference_skill"
                                            type="text" placeholder="Enter job perferences"
                                            name="job_perference_skill"
                                            className="form-input" />
                                        {errors.job_perference_skill && <Box className="form-error">{errors.job_perference_skill}</Box>}
                                    </Box>

                                    <Box className="form-control">
                                        <label className="form-label" htmlFor="perferred_job_location">Perferred Job Location</label>
                                        <Field id="perferred_job_location"
                                            type="text" placeholder="Enter job title"
                                            name="perferred_job_location"
                                            className="form-input" />
                                        {errors.perferred_job_location && <Box className="form-error">{errors.perferred_job_location}</Box>}
                                    </Box>


                                    <Box className="form-button-section">
                                        <Button className="form-button"
                                            variant="contained"
                                            type="submit"
                                            color="success"
                                            disabled={formSubmitted}
                                        >Update</Button>

                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Container>


        </Box >
    )

}
export default EditCandidate;