import "./announcement.scss";
import { Box, Button, Select, MenuItem, Autocomplete, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { deleteRequest, getRequestWithToken, patchRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { Formik, Form, Field, } from 'formik';
// import {validationForAssignTask} from "../../utils/Validations";

const JwpDeployTask = () => {
  const [jwpDeployTaskData, setJwpDeployTaskData] = useState([]);
  const [jwpTaskDeleted, setJwpTaskDeleted] = useState(false);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const getStatus = (status) => {
    if (status === 1) return "New";
    if (status === 2) return "In Progress";
    if (status === 3) return "En Route";
    if (status === 4) return "Closed";
    if (status === 5) return "Rejected";
    if (status === 6) return "Accepted"
  }
  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 70,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'taskId',
      headerName: 'Task ID',
      width: 200,
    },
    {
      field: 'jobSahayak',
      headerName: 'JobShayak ID',
      width: 350,
    },
    {
      field: 'taskType',
      headerName: 'Type',
      width: 150,
      renderCell: (params) => {
        let typeText = '--';

        if (params.value === 1) {
          typeText = 'Send';
        } else if (params.value === 2) {
          typeText = 'Deploy';
        }

        return <span>{typeText}</span>;
      }
    },
    {
      field: 'broadcastList',
      headerName: 'Broadcasted List',
      width: 200,
      renderCell: (params) => {
        return (<span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
          onClick={() => navigate("/jwp-task/deploy/broadcasted_list", { state: params.row.taskId })}
        >View JS</span>)
      }
    },
    {
      field: 'title',
      headerName: 'Rejected List',
      width: 200,
      renderCell: (params) => {
        return (<span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
          onClick={() => navigate("/jwp-task/deploy/rejected_list", { state: params.row.taskId })}
        >View JS</span>)
      }
    },
    // {
    //     field: 'sourceLocation',
    //     headerName: 'Origin',
    //     width: 200,
    // },
    {
      field: 'destinationLocation',
      headerName: 'Destination',
      width: 200,
    },
    {
      field: 'dateOfArrival',
      headerName: 'Date of Arrival',
      width: 200,
      renderCell: (params) => {
        return (<span>{new Date(params.value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}</span>)
      }
    },

    {
      field: 'listOfCandidates',
      headerName: 'No. of Candidates',
      width: 200,
      renderCell: (params) => {
        const showCandidates = () => {
          const url = `/jwp-task/candidates/${params?.row?.taskId}`;
          window.open(url, '_blank');
        }

        return (
          < Box style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <Box>{(params.value).length}</Box>
            {(params.value).length !== 0 && <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={showCandidates} >view candidate</span>}
          </Box >
        );
      },
    },

    {
      field: 'jobId',
      headerName: 'Job ID',
      width: 100,
    },

    {
      field: 'createdAt',
      headerName: 'Date & Time',
      width: 200,
      renderCell: (params) => {
        return (<span>{new Date(params.value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}</span>)
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        return (<span>{
          getStatus(params.row.status)}</span>)
      }
    },

    // (canUpdate || canCreate) && {
    //   field: 'action',
    //   headerName: 'Action',
    //   width: 180,
    //   renderCell: (params) => {

    //     return (
    //       < Box>
    //         <Button color="info" variant="contained" onClick={() => {
    //           setAssignTask(true);
    //           setAssignTaskData(params?.row)
    //         }}
    //         // disabled={params?.row?.jobSahayak}
    //         >Assign JS </Button>
    //       </Box>
    //     );
    //   },

    // },

  ];


  const getAllJwpDeployTask = async () => {
    try {
      let response = await getRequestWithToken(`admin/jwptask-deploy?page=${parseInt(pageNumber + 1)}&limit=${pageSize}`);
      setRowCount(response.data.total_count);
      setJwpDeployTaskData(response.data.data);
      // console.log(response.data.data);
      // let formatted_data = response?.data?.data[0]?.eligibleJobSahayaks.map((item) => ({
      //   label: [item?.first_name, item?.middle_name, item?.last_name].filter(Boolean).join(" "),
      //   id: item.id
      // }));
      // console.log("formatted data",formatted_data)
      // setJsData(formatted_data);
      // console.log("deployed Task",response.data.data);
      // console.log(JSON.stringify(response.data.total_count));
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }

  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }

  const [assignTask, setAssignTask] = useState(false);
  const [assignTaskData, setAssignTaskData] = useState([]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [jsData, setJsData] = useState([]);
  const handleAssignTask = () => setAssignTask(!assignTask);

  const intialStateForAssignTask = {
    state: "",
    city: "",
    jobshayak_name: " ",
  }
  const getAllJobSahayak = async () => {
    try {
      let response = await getRequestWithToken(`admin?usertype=jobsahayak&page=1&limit=500`);
      if (response.data.status === "Success") {
        let data = response.data.data;
        let formatted_data = data.map((item) => ({
          label: [item?.first_name, item?.middle_name, item?.last_name].filter(Boolean).join(" "),
          id: item.id
        }));
        setJsData(formatted_data);
      }
    }
    catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
    }

  }
  const handleFormSubmit = async (values, { resetForm, setErrors }) => {
    setFormSubmitted(true)
    if (values?.jobshayak_name === " ") {
      setErrors({ jobshayak_name: 'This field is required' });
      setFormSubmitted(false)
      return;
    }
    try {

      let res = await patchRequestWithToken(`/admin/jwptask-deploy/${assignTaskData?.id}/${values?.jobshayak_name?.id}`);

      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        setAssignTask(false);
        getAllJwpDeployTask();
        handleSnackBar(true, res.data.msg, "success");

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }



  }
  useEffect(() => {
    getAllJwpDeployTask();
    getAllJobSahayak();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue])


  return ((isIncludesValue) ?
    <Box className="employers-section">
      < ModalComponent
        openModal={assignTask}
        setOpenModal={setAssignTask}
        modalTitle="JobShayak Details"
        modalWidth="35%"
        handleOnCloseBtnClick={handleAssignTask}
      >
        <Formik
          enableReinitialize
          initialValues={intialStateForAssignTask}
          // validationSchema={validationForAssignTask}
          onSubmit={handleFormSubmit}
        >
          {({ errors, values, setFieldValue, resetForm }) => (
            <Form className="job-seva-assigntask-form">
              <Box className="job-seva-assigntask-form-control">
                <label className="form-label" htmlFor="state"> State<span className='must-fill-fields'>*</span></label>
                <Field id="state"
                  type="text" placeholder="Enter state"
                  name="state"
                  value="state"
                  className="form-input" disabled
                />
                {errors.state && <Box className="form-error">{errors.state}</Box>}
              </Box>

              <Box className="job-seva-assigntask-form-control">
                <label className="form-label" htmlFor="city"> City<span className='must-fill-fields'>*</span></label>
                <Field id="city"
                  type="text" placeholder="Enter city"
                  name="city"
                  value="city"
                  className="form-input" disabled
                />
                {errors.city && <Box className="form-error">{errors.city}</Box>}

              </Box>

              <Box className="job-seva-assigntask-form-control">
                <label className="form-label" htmlFor="jobshayak_name"> Jobshayak Name<span className='must-fill-fields'>*</span></label>
                {/* <Select
                            placeholder='Please Select Jobshayak'
                            name="jobshayak_name"
                            id="jobshayak_name"
                            value={values.jobshayak_name}
                            className="JobShayakName"
                           
                            onChange={(event) => {
                                const selectedName = event.target.value;
                                setFieldValue("jobshayak_name", selectedName);

                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                    maxHeight: 200, // Set the maximum height of the dropdown menu
                                    },
                                },
                                }}
                            style={{
                                padding: "0", // Remove padding
                                height: "40px", // Set the height of the menu items
                                }}
                            >
                            <MenuItem  value=" ">Select Jobshayak Name</MenuItem>
                            {jsData.map((job, index) => (
                                <MenuItem key={index} value={job.id}>
                                {`${job.first_name} ${job.middle_name ? job.middle_name : ''} ${job.last_name ? job.last_name : ''}`}
                                </MenuItem>
                            ))}
                            </Select> */}
                <Autocomplete
                  onChange={(event, newValue) => {
                    setFieldValue("jobshayak_name", newValue);
                  }}
                  id="combo-box-demo"
                  options={jsData}
                  renderInput={(params) => <TextField
                    {...params}
                    placeholder="Select JobShayak name"
                    InputProps={{
                      ...params.InputProps,
                      style: { padding: 0 }, // Apply padding directly to the input
                    }} />}
                />
                {errors.jobshayak_name && <Box className="form-error">{errors.jobshayak_name}</Box>}
              </Box>

              <Box className="form-button-section">
                <Button className="form-button"
                  variant="contained"
                  type="submit"
                  color="error"
                  onClick={() => {
                    handleAssignTask()
                  }}

                >Cancel</Button>

                <Button className="form-button"
                  variant="contained"
                  type="submit"
                  color="success"
                  disabled={formSubmitted}
                >Submit</Button>

              </Box>
            </Form>
          )}
        </Formik>
      </ModalComponent >
      <Box className="employers-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        <h1 className='formHeadings'>Jobseva / JWP Task / Deploy</h1>


      </Box>

      <Box className="employers-data">
        {showLoader ? <ReusableLoader /> : <DataGrid
          rowCount={rowCount}
          pagination
          rows={jwpDeployTaskData}
          // rows={staticRow}
          columns={columns}
          getRowId={(jwpDeployTaskData) => jwpDeployTaskData._id} //
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 50, 100]}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
          onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
          loading={loadingDataInGrid}

        />}
      </Box>

    </Box> : (<Unauthorized />)
  )
}
export default JwpDeployTask;
