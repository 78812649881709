import React, { createContext, useState } from 'react';


let GlobalContext = createContext();

const GlobalStateProvider = ({children}) => {

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarSeverity, setSnackBarSeverity] = useState("info");
    

    const handleSnackBar = (open=false,message="No message provided", severity="info")=>{
        setOpenSnackBar(open);
        setSnackBarMessage(message);
        setSnackBarSeverity(severity);
    }

    let value = {
        openSnackBar,
        snackBarMessage,
        snackBarSeverity,
        handleSnackBar,
        setOpenSnackBar
    }

  return (
    <GlobalContext.Provider value={value} >
        {children}
    </GlobalContext.Provider>
  )
}

export {GlobalContext};

export default GlobalStateProvider