

export const checkPermission = (resourceToUpdate) => {
  const storedData = localStorage.getItem("roleData");
  const userData = JSON.parse(storedData);

  const canReadPermission = userData[0]?.resources?.some(resource => {
    return (
      resource.resource === resourceToUpdate && resource.actions.includes("read:any")
    );
  });

  const canCreatePermission = userData[0]?.resources?.some(resource => {
    return (
      resource.resource === resourceToUpdate && resource.actions.includes("create:any")
    );
  });

  const canDeletePermission = userData[0]?.resources?.some(resource => {
    return (
      resource.resource === resourceToUpdate && resource.actions.includes("delete:any")
    );
  });

  const canUpdatePermission = userData[0]?.resources?.some(resource => {
    return (
      resource.resource === resourceToUpdate && resource.actions.includes("update:any")
    );
  });

  return {
    canRead: canReadPermission,
    canCreate: canCreatePermission,
    canDelete: canDeletePermission,
    canUpdate: canUpdatePermission,
  };
};
