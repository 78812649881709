import { useFormikContext } from "formik";
import { useEffect } from "react";

const FocusError = ({ handleSnackBar, values }) => {
    const { errors, isSubmitting, isValidating } = useFormikContext();
    const handleFocusOnInvalidFields = () => {
        if (isSubmitting && !isValidating) {
            let keys = Object.keys(errors);

            if (keys.length > 0) {
                const selector = `[name=${keys[0]}]`;
                const errorElement = document.querySelector(selector)
                if (errorElement) {
                    setTimeout(() => {
                        errorElement.scrollIntoView({ behavior: "smooth" });
                    }, 0)
                }
                handleSnackBar(true, "Please fill mandatory fields", "error");
            }

        }
    }

    useEffect(() => {
        handleFocusOnInvalidFields();
    }, [errors, isSubmitting, isValidating]);
    return null;
}

export default FocusError;