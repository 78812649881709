import React from 'react';
import { Box, Button, Tooltip, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect } from "react";
import { getRequestWithToken, deleteRequest } from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import MaterialTable from "../../components/materialTable/MaterialTable";


const SmsCampaign = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: []
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });


  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const handleCompleteProfilesChange = () => {
    setShowCompleteProfiles(!showCompleteProfiles);
  };

  const filteredRows = campaignData.filter(candidate => {
    const isDeletedMatch = showDeletedRows || (candidate.deleted && candidate.deleted.is_deleted === false);
    const isProfileCompleteMatch = showCompleteProfiles || (candidate.is_profile_complete && candidate.is_profile_complete.toLowerCase() === "yes");

    return isDeletedMatch && isProfileCompleteMatch;
  });

  const createNewCandidate = () => {
    navigate('/sms-campaign/create');
  }

  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No.',
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + (pageIndex * pageSize)}</span>;
      },
    },
    {
      accessorKey: 'campaignId',
      header: 'Campaign Id',
      size: 220,
    },
    {
      accessorKey: 'campaignName',
      header: 'Campaign Name',
      size: 220,
    },
    {
      accessorKey: 'templateId',
      header: 'Template Id',
      size: 250,
      Cell: ({ row }) => {
        return row.original.smsParams?.templateId || 'N/A';
      },
    },
    {
      accessorKey: 'numberOfUploads',
      header: 'Number Of Uploads',
      size: 200,
    },
    {
      accessorKey: 'campaignType',
      header: 'Campaign Type',
      size: 200,
    },
    {
      accessorKey: 'scheduledDateOrTime',
      header: 'Scheduled Date and Time',
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}
        </span>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: 'Date and Time',
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}
        </span>
      ),
    },
    {
      accessorKey: 'csvFile',
      header: 'CSV File',
      size: 150,
      Cell: ({ row }) => (
        <a
          href={row.original.csvFile}
          download
          target="_blank"
          rel="noreferrer"
        >
          Download
        </a>
      ),
    },
  ];

  const getAllCandidates = async () => {
    setLoadingDataInGrid(true);
    try {
      let response = await getRequestWithToken(`admin/campaign?modeofsend=sms&page=${parseInt(pagination.pageIndex + 1)}&limit=${pagination.pageSize}`);
      setCampaignData(response.data.data);
      setRowCount(response.data.totalCount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);

    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Marketing";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }


  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Marketing"))
  }


  useEffect(() => {
    getAllCandidates();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pagination.pageIndex, pagination.pageSize, isIncludesValue])

  return (
    (isIncludesValue) ?
      <Box className="candidates-section">
        <h3 style={{ margin: "0px" }}>SMS Campaign</h3>
        <Box className="candidates-top-section" style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}>
          {canCreate ? (<Button variant="contained" color="success" onClick={createNewCandidate}>
            Create Campaign
          </Button>) : ""}

        </Box>
        <Box className="candidates-section-data">
          {showLoader ? <ReusableLoader /> :

            <MaterialTable
              filteredRows={filteredRows}
              columns={columns}
              rowCount={rowCount}
              isLoading={loadingDataInGrid}
              pagination={pagination}
              columnPinning={columnPinning}
              setPagination={setPagination}
              setColumnPinning={setColumnPinning}
            />

            // <DataGrid
            //   // rows={campaignData}
            //   rows={filteredRows}
            //   columns={columns}
            //   getRowId={(campaignData) => campaignData._id}
            //   paginationMode="server"
            //   pagination
            //   rowsPerPageOptions={[10, 20, 30, 50, 100]}
            //   page={pageNumber}
            //   pageSize={pageSize}
            //   onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
            //   onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
            //   loading={loadingDataInGrid}
            //   rowCount={rowCount}
            // />
          }
        </Box>
      </Box> : (<Unauthorized />)
  )
};

export default SmsCampaign;
