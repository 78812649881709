import { Box, Button, FormControl, TextField, Container, Grid, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { CreateSectorValidationSchema } from '../../utils/Validations';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { useContext, useEffect, useRef, useState, } from 'react';
import "./jobSectors.scss";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
const CreateJobSectors = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([])
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [initialValues, setInitialValues] = useState({
    sectorNameEn: "",
    sectorNameHi: "",
    sectorNameHn: "",

  });
  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {

    let formData = new FormData();
    formData = {
      sectorNameEn: values.sectorNameEn,
      sectorNameHi: values.sectorNameHi,
      sectorNameHn: values.sectorNameHn,

    }
    try {
      let res = editForm ?
        await patchRequestWithToken('admin/sectors', { userid: location.state._id, ...formData }) : await postRequest('admin/sectors', formData);
      setFormSubmitted(true);

      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!editForm) {
          resetForm()
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/job-sectors');
          }, 1000);

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/job-sectors');
          }, 1000);

        }

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  }

  const fetchPincodeDetails = async (pincode, setFieldValue) => {
    try {
      let response = await getRequest(`pincode/${pincode}`);
      const data = response.data.data[0];
      setFieldValue('district', data.district);
      setFieldValue('state', data.state);
    } catch (err) {
      console.log(err)
    }
  }

  const getCandidateById = async () => {
    try {
      let response = await getRequestWithToken(`admin/sectors?${location.state?.id}`);
      console.log("responseeeeeeee : ", response.data.data)
      setDataById(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"))
  }


  useEffect(() => {

    checkPermissionFunc();
    againCheckPermissions();

    if (location.state?._id) {
      getCandidateById()
    }
    else {
      setShowLoader(false);
    }
    // console.log('location : ', location?.state?.id)
    if (location?.state?.id) {
      const { candidate = {}, deleted = {} } = dataById;
      const {
        sectorNameEn = "",
        sectorNameHi = "",
        sectorNameHn = "",

      } = candidate;

      setInitialValues({
        sectorNameEn,
        sectorNameHi,
        sectorNameHn,

      })

      setEditForm(true);
    } else {

      setInitialValues({
        sectorNameEn: "",
        sectorNameHi: "",
        sectorNameHn: "",

      });
      setEditForm(false);
    }
    ;
  }, [location.state, setInitialValues, dataById._id, isIncludesValue])

  return (

    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-candidates-section" ref={scrollTargetRef}>

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >{editForm ? "Update Sector" : "Create Sector"}</h1>

            </Box>
            <Box className="create-candidates-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CreateSectorValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-candidates-form">

                    <Box className="backgroundStyle">
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="sectorNameEn"> Sector Name English<span className='must-fill-fields'>*</span></label>
                            <Field id="sectorNameEn"
                              type="text" placeholder="Sector Name English"
                              name="sectorNameEn"
                              className="form-input" size="small"
                            />
                            {errors.sectorNameEn && <Box className="form-error">{errors.sectorNameEn}</Box>}

                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="sectorNameHn">Sector Name Hinglish<span className='must-fill-fields'>*</span></label>
                            <Field id="sectorNameHn"
                              type="text" placeholder="Sector Name Hinglish"
                              name="sectorNameHn"
                              className="form-input" />
                            {errors.sectorNameHn && <Box className="form-error">{errors.sectorNameHn}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="sectorNameHi">Sector Name Hindi <span className='must-fill-fields'>*</span></label>
                            <Field id="sectorNameHi"
                              type="text" placeholder="Sector Name Hindi "
                              name="sectorNameHi"
                              className="form-input" />
                            {errors.sectorNameHi && <Box className="form-error">{errors.sectorNameHi}</Box>}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="form-button-section">

                      <Link to='/job-sectors'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}

                      >{editForm ? "Update" : "Create"}</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>


      </Box >}
    </> : (<Unauthorized />)

  )

}
export default CreateJobSectors;