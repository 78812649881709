import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Select,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { CreateSmsCampaignValidationSchema } from "../../utils/Validations";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { format, addMinutes } from "date-fns";
import { getRequestWithToken, postRequest } from "../../utils/ApiRequests";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import FocusError from "../../components/FocusError/FocusError";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from "../../components/backButton/BackBtn";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import "./marketing.scss";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { formatTimeTo24Hour, convertToUTC } from "../../utils/functions";

const CreateSms = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([]);
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [dateTimePickers, setDateTimePickers] = useState([{}]);

  const addDateTimePicker = () => {
    setDateTimePickers([...dateTimePickers, {}]);
  };

  const removeDateTimePicker = (index) => {
    setDateTimePickers(dateTimePickers.filter((_, i) => i !== index));
  };

  const [initialValues, setInitialValues] = useState({
    campaignName: "",
    campaignType: "",
    templateId: "",
    senderHeader: "",
    csvFile: "",
    bodyTextContent: "",
    sendLater: false,
    // date: null,
    // time: null,
    scheduleTime: "",
  });
  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm, setErrors }) => {
    if (values.sendLater && values.scheduleTime === "") {
      setErrors({ scheduleTime: "Please select date and time" });
      return;
    }
    let scheduledTimeValue = values.scheduleTime
      ? values.scheduleTime
      : new Date().toISOString();

    setFormSubmitted(true);
    let formData = new FormData();
    formData.append("modeOfSend", "sms");
    formData.append("campaignName", values.campaignName);
    formData.append("campaignType", values.campaignType);
    formData.append("csvFile", values.csvFile);
    formData.append(
      "params",
      JSON.stringify({
        smsParams: {
          templateId: values.templateId,
          senderHeader: values.senderHeader,
        },
      })
    );
    formData.append("bodyTextContent", JSON.stringify(values.bodyTextContent));
    formData.append("scheduledTime", scheduledTimeValue);

    // console.log(formData);

    try {
      let res = await postRequest("admin/campaign-schedule", formData);

      if (res.data.status === "Success") {
        setFormSubmitted(false);
        if (!editForm) {
          resetForm();
          fileRef.current.value = "";
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: "smooth" });
          }
          handleSnackBar(true, res.data.msg, "success");
        } else {
          handleSnackBar(true, res.data.msg, "success");
          resetForm();

          // setTimeout(() => {
          //   navigate('/candidate');
          // }, 1000);
        }
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const getCandidateById = async () => {
    try {
      let response = await getRequestWithToken(
        `admin/userbyid/?usertype=job_seeker&userid=${location.state?._id}`
      );
      setDataById(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.msg || error?.message,
        "error"
      );
      setShowLoader(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();

    if (location.state?._id) {
      getCandidateById();
    } else {
      setShowLoader(false);
    }

    if (location.state?._id) {
      const { candidate = {}, deleted = {} } = dataById;
      const {
        campaignName = "",
        templateId = "",
        senderHeader = "",
        campaignType = "",
        bodyTextContent = "",
      } = candidate;

      setInitialValues({
        campaignName,
        campaignType,
        templateId,
        senderHeader,
        bodyTextContent,
      });
      setEditForm(true);
    } else {
      setInitialValues({
        campaignName: "",
        templateId: "",
        campaignType: "",
        senderHeader: "",
        bodyTextContent: "",
        sendLater: false,
        // date: null,
        // time: null,
        scheduleTime: "",
      });
      setEditForm(false);
    }
  }, [location.state, setInitialValues, dataById._id, isIncludesValue]);

  const fileRef = useRef(null);
  return isIncludesValue ? (
    <>
      {showLoader ? (
        <ReusableLoader />
      ) : (
        <Box className="create-candidates-section" ref={scrollTargetRef}>
          <Container>
            <Box className="create-candidates">
              <Box className="headingPlusBackBtn">
                <BackBtn />
                <h1 className="formHeadings" id="topEle">
                  {editForm ? "Update Candidate" : "Create SMS Campaign"}
                </h1>
              </Box>

              <Box className="create-candidates-form-wrapper">
                <Formik
                  initialValues={initialValues}
                  validationSchema={CreateSmsCampaignValidationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize
                >
                  {({ errors, values, setFieldValue, resetForm }) => (
                    <Form className="create-candidates-form">
                      <Box className="backgroundStyle">
                        {/* <p className='formSubSectionHeading'>Personal Details</p> */}
                        <Grid
                          container
                          spacing={2}
                          className="cstmFormGridEmployer"
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="campaignName"
                              >
                                {" "}
                                Campaign Name{" "}
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="campaignName"
                                type="text"
                                placeholder="Enter Campaign Name"
                                name="campaignName"
                                className="form-input"
                                size="small"
                              />
                              {errors.campaignName && (
                                <Box className="form-error">
                                  {errors.campaignName}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="templateId"
                              >
                                {" "}
                                Template ID{" "}
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Field
                                id="templateId"
                                type="text"
                                placeholder="Enter Template ID"
                                name="templateId"
                                className="form-input"
                                size="small"
                              />
                              {errors.templateId && (
                                <Box className="form-error">
                                  {errors.templateId}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="senderHeader"
                              >
                                {" "}
                                Sender Header{" "}
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Select
                                className="cstmSelectBox"
                                name="senderHeader"
                                id="senderHeader"
                                value={values.senderHeader || ""}
                                onChange={(event) => {
                                  setFieldValue(
                                    "senderHeader",
                                    event.target.value
                                  );
                                }}
                                displayEmpty
                                renderValue={(selected) =>
                                  selected ? selected : "Select senderHeader"
                                }
                              >
                                <MenuItem value="" disabled>
                                  Select sender Header
                                </MenuItem>
                                <MenuItem value="JBSEVA">JBSEVA</MenuItem>
                                <MenuItem value="JBSWHN">JBSWHN</MenuItem>
                                <MenuItem value="JBSYHN">JBSYHN</MenuItem>
                                <MenuItem value="678414">678414</MenuItem>
                              </Select>

                              {errors.senderHeader && (
                                <Box className="form-error">
                                  {errors.senderHeader}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={12}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="bodyTextContent"
                              >
                                Template{" "}
                                <span className="must-fill-fields">*</span>
                              </label>

                              <Field
                                as="textarea"
                                rows="4"
                                cols="10"
                                id="bodyTextContent"
                                type="text"
                                placeholder={`Paste Body Text Content here...\nex: '{{ otp }} OTP dwara aap JobsYahan - Bharat ka Job App par login kar sakte hain.\nKripya ye OTP kisi se share na karein.'`}
                                name="bodyTextContent"
                                className="form-input"
                              />
                              {errors.bodyTextContent && (
                                <Box className="form-error">
                                  {errors.bodyTextContent}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label
                                className="form-label"
                                htmlFor="campaignType"
                              >
                                {" "}
                                Campaign Type{" "}
                                <span className="must-fill-fields">*</span>
                              </label>
                              <Select
                                className="cstmSelectBox"
                                name="campaignType"
                                id="campaignType"
                                value={values.campaignType || ""}
                                onChange={(event) => {
                                  setFieldValue(
                                    "campaignType",
                                    event.target.value
                                  );
                                }}
                                displayEmpty
                                renderValue={(selected) =>
                                  selected ? selected : "Select campaignType"
                                }
                              >
                                <MenuItem value="" disabled>
                                  Select campaignType
                                </MenuItem>
                                <MenuItem value="marketing">Marketing</MenuItem>
                                <MenuItem value="utility">Utility</MenuItem>
                              </Select>

                              {errors.campaignType && (
                                <Box className="form-error">
                                  {errors.campaignType}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="csvFile">
                                Upload CSV{" "}
                                <span className="must-fill-fields">*</span>
                              </label>
                              <input
                                type="file"
                                name="csvFile"
                                className="mt-3 input-width upload_button"
                                id="csvFile2"
                                onChange={(event) => {
                                  const fileName = event.target.files[0];
                                  console.log(
                                    "selected file value: ",
                                    fileName
                                  );
                                  setFieldValue("csvFile", fileName);
                                }}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12"
                                // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                style={{
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  padding: "8px",
                                  backgroundColor: "#f7f7f7",
                                  color: "#333",
                                  fontSize: "14px",
                                }}
                                ref={fileRef}
                              />
                              {errors.csvFile && (
                                <Box className="form-error">
                                  {errors.csvFile}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          {!editForm && (
                            <Grid item xs={12} sm={12} md={12}>
                              <Box
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography>Send Later</Typography>
                                <Switch
                                  checked={values.sendLater}
                                  name="sendLater"
                                  onChange={() =>
                                    setFieldValue(
                                      "sendLater",
                                      !values.sendLater
                                    )
                                  }
                                />
                              </Box>
                              {values.sendLater && (
                                <Grid container>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <Box className="form-control">
                                      <label
                                        className="form-label"
                                        htmlFor="date"
                                      >
                                        Date and Time
                                      </label>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        {/* <DateTimePicker label="Basic date time picker" /> */}
                                        <DateTimePicker
                                          label=""
                                          value={values.scheduleTime}
                                          onChange={(newValue) => {
                                            const utcDate = new Date(
                                              newValue
                                            ).toISOString();

                                            setFieldValue(
                                              "scheduleTime",
                                              utcDate
                                            );
                                          }}
                                        />
                                      </LocalizationProvider>

                                      {errors.scheduleTime && (
                                        <Box className="form-error">
                                          {errors.scheduleTime}
                                        </Box>
                                      )}
                                    </Box>
                                    {/* <Box
                                      className="form-control"
                                      style={{ marginTop: "15px" }}
                                    >
                                      <label
                                        className="form-label"
                                        htmlFor="time"
                                      >
                                        Time
                                      </label>

                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          value={values.time}
                                          onChange={(newValue) => {
                                            setFieldValue("time", newValue);
                                          }}
                                          name="time"
                                          slotProps={{
                                            textField: {
                                              variant: "outlined",
                                              error: false,
                                              size: "small",
                                            },
                                          }}
                                        />
                                      </LocalizationProvider>

                                      {errors.time && (
                                        <Box className="form-error">
                                          {errors.time}
                                        </Box>
                                      )}
                                    </Box> */}
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Box>

                      <Box className="form-button-section">
                        <Button
                          className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                            fileRef.current.value = "";
                          }}
                          color="error"
                        >
                          Cancel
                        </Button>

                        <Button
                          className="form-button"
                          variant="contained"
                          type="submit"
                          color="success"
                          disabled={formSubmitted}
                        >
                          {editForm ? "Update" : "Submit & Schedule"}
                        </Button>
                      </Box>
                      <FocusError handleSnackBar={handleSnackBar} />
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  ) : (
    <Unauthorized />
  );
};

export default CreateSms;
