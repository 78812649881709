
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BarChartComponent from "../../components/barChart/BarChartComponent";
import LineChartComponent from "../../components/barChart/LineChartComponent ";
const Home = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        let response = await getRequestWithToken(`admin/dashboard-stats`);
        setDashboardData(response.data);
        setShowLoader(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setShowLoader(false);
      }
    };
    fetchDashboardData();
  }, [setDashboardData]);


  return (<>

    {/* <Box className="home_section" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "80vh", color: "#ff671f" }}> */}
    {showLoader ? <ReusableLoader /> : <Box className="home_section">
      <div className="widgets">
        <Grid container spacing={2} className='cstmFormGridEmployer'>
          <Grid item xs={4} sm={6} md={2}>
            <Widget type="candidate" amount={dashboardData.data.candidatesCount.count} />
          </Grid>
          <Grid item xs={4} sm={6} md={2}>
            <Widget type="employer" amount={dashboardData.data.employersCount.count} />
          </Grid>
          <Grid item xs={4} sm={6} md={2}>
            <Widget type="sahayak" amount={dashboardData.data.sahayaksCount.count} />
          </Grid>
          <Grid item xs={4} sm={6} md={2}>
            <Widget type="partner" amount={dashboardData.data.partnersCount.count} />
          </Grid>
          <Grid item xs={4} sm={6} md={2}>
            <Widget type="activeJob" amount={dashboardData.data.activeJobPostsCount.count} />
          </Grid>
        </Grid>
      </div>
      <div className="charts">
        {/* <Featured /> */}
        {/* <Chart className="chartwidth" title="Last 1 Months (Revenue)" aspect={3 / 1} /> */}
        <Grid container spacing={2} className='cstmFormGridEmployer'>
          <Grid item xs={12} sm={6} md={6}>
            <BarChartComponent data={dashboardData.data.monthWiseCandidatesCount} title="MONTH-WISE CANDIDATES COUNT" aspect={3 / 1} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <BarChartComponent data={dashboardData.data.monthWiseEmployersCount} title="MONTH-WISE EMPLOYERS COUNT" aspect={3 / 1} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <LineChartComponent data={dashboardData.data.monthWiseJobPosts} title="MONTH-WISE JOB POSTS" aspect={3 / 1} />
          </Grid>
        </Grid>
      </div>
      
      {/* <h1>Welcome to JobsYahan Dashboard</h1> */}
    </Box>}
  </>

  );
};

export default Home;
