import "./generateqr.scss";
import { Box, Button, Snackbar, Alert, MenuItem, FormControl, Select, Container } from '@mui/material';
import { useState, useContext } from "react";
import { Formik, Form, Field } from 'formik';
import { QrValidataionSchema } from '../../utils/Validations';
import { postRequest } from "../../utils/ApiRequests";
import { checkPermission } from '../../utils/userPermissions'
import { useEffect } from "react";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import FocusError from '../../components/FocusError/FocusError';



const ManageRoles = () => {
  const [template, setTemplate] = useState(" ");
  const [size, setSize] = useState(" ");
  const [batchSize, setBatchSize] = useState(" ");
  const [printQR, setPrintQR] = useState(false);
  // const no_of_qrcode = useRef(null);
  const [loading, setLoading] = useState(false);

  const [canCreate, setCanCreate] = useState(false);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);
  const { handleSnackBar } = useContext(GlobalContext);

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);

    let formData = new FormData();
    formData = {
      batch_size: values.batch_size,
      no_of_qr_code: values.no_of_qr_code,
      template: values.template_name,
      template_size: values.template_size
    }
    try {
      let res = await postRequest("qrcode", formData);
      console.log("this is res data : " + res.data.status);
      if (res.data.status === 'Success') {
        setLoading(false);
        setPrintQR(true);
        resetForm();
        handleSnackBar(true, res.data.msg, "success");
      }
      else {
      }
    } catch (err) {
      setLoading(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
      console.log("this is the eroor : " + err);
      resetForm();

    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Generate Qr";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    console.log("Can read:", permissions.canRead);
  }

  const againCheckPermissions = () => {
    console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    console.log("resourceNames : " + checkAccesInArray)
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Generate Qr"))
  }

  useEffect(() => {

    checkPermissionFunc();
    againCheckPermissions();

  }, [isIncludesValue])


  const initialValues = {
    template_name: " ",
    template_size: " ",
    no_of_qr_code: "",
    batch_size: " "
  }
  return ((isIncludesValue) ?
    <Box className="generate-qr-section">

      <Container>
        <Box className="generate-qr">
          <h1>Generate QR</h1>
          <Formik
            initialValues={initialValues}
            validationSchema={QrValidataionSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, setFieldValue, resetForm }) => (
              <Form className="generate-qr-form-wrapper">

                <Box class="generate-qr-form-row">

                  <Box className="form-control">
                    <label className="form-label" htmlFor="template_name"> Select Template *</label>
                    <FormControl fullWidth size="small">
                      <Select
                        id="template_name"
                        value={values.template_name}
                        onChange={
                          (event) => {
                            setTemplate(event.target.value)
                            setFieldValue("template_name", event.target.value)
                          }
                        }
                      >
                        <MenuItem value=" ">Select Template</MenuItem>
                        <MenuItem value="template1">Template 1</MenuItem>
                        <MenuItem value="template2">Template 2</MenuItem>
                        <MenuItem value="template3">Template 3</MenuItem>
                      </Select>
                      {errors.template_name && <Box className="form-error">{errors.template_name}</Box>}

                    </FormControl>
                  </Box>
                  <Box className="form-control">
                    <label className="form-label" htmlFor="template_size"> Size *</label>
                    <FormControl fullWidth size="small">
                      <Select
                        id="template_size"
                        value={values.template_size}
                        onChange={(event) => {
                          setSize(event.target.value);
                          setFieldValue("template_size", event.target.value)
                        }}
                      >
                        <MenuItem value=" ">Select Size</MenuItem>
                        <MenuItem value="A1">A1</MenuItem>
                        <MenuItem value="A2">A2</MenuItem>
                        <MenuItem value="A3">A3</MenuItem>
                        <MenuItem value="A4">A4</MenuItem>
                        <MenuItem value="A5">A5</MenuItem>
                        {/* <MenuItem value="Custom">Custom</MenuItem> */}
                      </Select>
                    </FormControl>
                    {errors.template_size && <Box className="form-error">{errors.template_size}</Box>}

                  </Box>

                </Box>

                <Box class="generate-qr-form-row">
                  <Box className="form-control">
                    <label className="form-label" htmlFor="no_of_qr_code">Number of QR Code *</label>
                    <Field id="no_of_qr_code"
                      type="number" placeholder="Enter number in multiples of 5"
                      name="no_of_qr_code"
                      className="form-input"
                      step="5"
                      value={values.no_of_qr_code}
                    />
                    {errors.no_of_qr_code && <Box className="form-error">{errors.no_of_qr_code}</Box>}
                  </Box>
                  <Box className="form-control">
                    <label className="form-label" htmlFor="template">Batch Size *</label>
                    <Box style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                      <FormControl size="small" style={{ width: "50%" }}>
                        <Select
                          id="template"
                          value={values.batch_size}
                          onChange={(event) => {
                            if (event.target.value !== "other") {
                              setBatchSize(event.target.value)
                              setFieldValue("batch_size", event.target.value)
                            }
                            else {
                              setBatchSize(event.target.value)
                            }
                          }}
                        >
                          <MenuItem value=" ">Select Batch Size</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="25">25</MenuItem>
                          <MenuItem value="50">50</MenuItem>
                          <MenuItem value="100">100</MenuItem>
                          <MenuItem value="1000">1000</MenuItem>
                          <MenuItem value="other">other</MenuItem>

                        </Select>
                      </FormControl>
                      {batchSize === "other" && <
                        Field id="batch"
                        style={{ width: "50%" }}
                        type="number" placeholder="Enter batch size"
                        name="batch_size"
                        className="form-input"
                        step="5"
                        value={values.batch_size}
                      />}
                    </Box>
                    {errors.batch_size && <Box className="form-error">{errors.batch_size}</Box>}

                  </Box>

                </Box>

                <Box className="form-button-section">
                  {canCreate ? (<Button className="form-button"
                    variant="contained"
                    type="submit"
                    color="success"
                    // onClick={handleQR}
                    disabled={loading}
                  > {loading ? 'Generating...' : 'Generate'}</Button>) : ""}

                  <Button
                    className="form-button"
                    variant="contained"
                    type="button"
                    color="error"
                    onClick={() => { resetForm() }}
                  >Reset</Button>
                </Box>
                <FocusError handleSnackBar={handleSnackBar} />
              </Form>
            )}
          </Formik>
        </Box>
      </Container >


    </Box > : (<Unauthorized />)
  )
}
export default ManageRoles;
