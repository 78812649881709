import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

function DragAndDropComponent({
    dndwidth = 10,
    dndHeight = 10,
    btnLabel = "Upload File",
    dndTitle = "Drag and Drop Title",
    onChange = () => { },
    fileData, setFileData,
    showBtn = false,
    name = "",
    handleChange = () => { },
    editData,
    disabled
}) {
    const [fileImg, setFileImg] = useState("")

    const onDrop = useCallback((acceptedFiles) => {
        // setFileData(acceptedFiles)
        acceptedFiles.forEach((file, index) => {

            let url = URL.createObjectURL(file);
            if (index === 0)
                setFileImg(url)
        })

        // Do something with the files
        onChange(acceptedFiles);
    }, []);
    
    let { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop ,
        disabled:disabled,
        // accept: {
        //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        //         ".xlsx",
        //     ],
        //     "application/vnd.ms-excel": [".xls"],
        // },
    });
    const [files, setFiles] = useState([]);


    useEffect(() => {
        const fileData = acceptedFiles.map((file) => (
            <Typography key={file.path}>{file.path}</Typography>
        ));
        setFiles(fileData)
    }, [acceptedFiles])

    useEffect(() => {
        if (editData) {
            console.log(" hey hi", editData.svgPngImg, " this is svgPng");
            setFileImg(editData.svgPngImg)
        }

    }, [])

    console.log("file , file ", fileImg);

    const onClear = () => {
        setFiles([]);
        setFileData([])
    };

    // let files = acceptedFiles.map((file) => (
    //   <Typography key={file.path}>{file.path}</Typography>
    //   ));

    const styles = {
        backgroundColor: "#EEF9FD",
        border: "1px dotted #1181B2",
        borderRadius: "8px",
        height: dndHeight + "%",
        width: dndwidth + "%",
        marginLeft: "10px",
        marginTop: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    };

    return (
        <div
            {...getRootProps({ className: "dropzone " })}
            style={styles}
            sx={{
                flexDirection: "column",
                display: "flex",
                paddingY: "9px",
            }}
        >  
            <input name={name} {...getInputProps()} className="py-2" sx={{ paddingY: "6px" }} />
            {files.length > 0 ?
                <img style={{ height: "100%", width: "50%", display: "block", objectFit: "cover", zIndex: 2000 }} src={fileImg} alt="selected png, svg image" /> :
                <Typography
                    variant="p"
                    className=" fw-400 fs-14"
                    sx={{
                        color: "#1181B2",
                        paddingX: "3px",
                        wordBreak: "break-all",
                        // display: "flex",
                        // justifyContent: "center",
                        // marginTop: "70px",
                        textAlign: "center",
                    }}
                >
                    {/* {files.length > 0 ? (
                    <Box display={"flex"} alignItems={"center"}>
                        {files}
                        <i
                            className="fa fa-times cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                onClear();
                            }}
                            style={{ marginLeft: 10, color: "red", fontSize: "20px" }}
                            aria-hidden="true"
                        ></i>
                    </Box>
                ) : (
                    dndTitle
                )} */}

                    {!editData && files.length === 0 && dndTitle}
                </Typography>}

            {editData && files.length === 0 && <img style={{ height: "100%", width: "50%", display: "block", objectFit: "cover", zIndex: 2000 }} src={fileImg} alt="selected png, svg image" />}
            {showBtn && <div
                className="mt-1"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Box>
                    <Button label={btnLabel} size="small" />
                </Box>
            </div>}
        </div>
    );
}

export default DragAndDropComponent;
