import {
  Box, Button, Container, createTheme, Grid
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import "./jobsahayak.scss";
import { patchRequestWithToken, postRequest, getRequest } from "../../utils/ApiRequests";
import { JobSahayakValidationSchema } from '../../utils/Validations';
import { useContext, useRef, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import FocusError from '../../components/FocusError/FocusError';
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";


const CreateJobsahayak = () => {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#757575"
      }
    }
  });

  const { handleSnackBar } = useContext(GlobalContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [jobShayakCreated, setJobShayakCreated] = useState(false);
  const [editJobSahayak, setEditJobSahayak] = useState(false);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [userId, setUserId] = useState("");
  const location = useLocation();
  let selectPlaceholderValue = "Enter your blood group";

  const [initialValues, setInitialValues] = useState(
    {
      first_name: "",
      middle_name: "",
      last_name: "",
      mobile_number: "",
      email_address: "",
      location: "",
      address: "",
      village: "",
      panchayat: "",
      district: "",
      city: "",
      state: "",
      postal_code: "",
      date_of_birth: null,
      dl_number: "",
      education_qualification: "",
      previous_experience: "",
      experience: "",
      vehicle_registration_number: "",
      blood_group: "",
      emgergency_contact_person_name: "",
      emgergency_contact_mobile: "",
      referred_by: "",
      jobsahayak_name: "",
      jobshayak_name_error: ""
    }
  )

  const fetchPincodeDetails = async (pincode, setFieldValue) => {
    try {
      console.log(pincode)
      let response = await getRequest(`pincode/${pincode}`);
      // console.log("pincodeResponse : " + JSON.stringify(response));
      // console.log("data : " + data)
      console.log("village : " + JSON.stringify(response.data.data[0]))
      const data = response.data.data[0]; // Access the first element of the array

      // Set values using setFieldValue
      setFieldValue('village', data.village);
      setFieldValue('panchayat', data.panchayat);
      setFieldValue('district', data.district);
      setFieldValue('state', data.state);
      setFieldValue('city', data.city);
    } catch (err) {
      console.log(err)
    }

  }

  const [formError, setFormError] = useState(false);
  const [message, setMessage] = useState('');

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Job Sahayak";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Job Sahayak"))
  }

  useEffect(() => {

    checkPermissionFunc();
    againCheckPermissions();

    if (location?.state?.obj?.length) {
      let singleObjArr = Object.values(location?.state?.obj)?.map(val => val);
      console.log(singleObjArr[0], " this is array");
      const { blood_group = "",
        date_of_birth = null,
        dl_number = "",
        education_qualification = "",
        email = "",
        emergency_contact,
        mobile = "",
        full_name = "",
        location: {
          address = "",
          city = "",
          postal_code = "",
          state = "",
          panchayat = "",
          village = "",
          district = "",
        }, previous_experience = "",
        experience = "",
        profile_active = "",
        user_type = "",
        vehicle_registration_number,
        last_name = "",
        first_name = "",
        middle_name = "",
        referred_by,
        jobsahayak_name = "",
        jobshayak_name_error = ""

      } = singleObjArr[0];
      setEditJobSahayak(true);
      setInitialValues({
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        mobile_number: mobile,
        email_address: email,
        address: address,
        city: city,
        state: state,
        village: village,
        district: district,
        panchayat: panchayat,
        postal_code: postal_code,
        date_of_birth: new Date(date_of_birth),
        dl_number,
        education_qualification: education_qualification,
        previous_experience: previous_experience,
        experience: experience,
        vehicle_registration_number,
        blood_group,
        emgergency_contact_person_name: emergency_contact?.person_name,
        emgergency_contact_mobile: emergency_contact?.mobile,
        referred_by,
        jobsahayak_name,
        jobshayak_name_error,

      })
      setUserId(location?.state?.userid);
    } else {
      setEditJobSahayak(false);
      setInitialValues({
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile_number: "",
        email_address: "",
        address: "",
        city: "",
        state: "",
        village: "",
        district: "",
        panchayat: "",
        postal_code: "",
        date_of_birth: null,
        dl_number: "",
        education_qualification: "",
        previous_experience: "",
        experience: "",
        vehicle_registration_number: "",
        blood_group: "",
        emgergency_contact_person_name: "",
        emgergency_contact_mobile: "",
        referred_by: "",
        jobsahayak_name: "",
        jobshayak_name_error: "",
      })
    }
  }, [location.state, isIncludesValue])

  // scroll after success
  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    console.warn("previous_experienceeeee : " + values.previous_experience)
    setFormSubmitted(true);
    let formData = new FormData();
    formData = editJobSahayak ? {
      usertype: "jobsahayak",
      first_name: values.first_name,
      last_name: values.last_name,
      middle_name: values.middle_name,
      email: values.email_address,
      emergency_contact: {
        person_name: values.emgergency_contact_person_name,
        mobile: values.emgergency_contact_mobile
      },
      // referred_by: values.referred_by,

    } : {
      usertype: "jobsahayak",
      first_name: values.first_name,
      last_name: values.last_name,
      middle_name: values.middle_name,
      mobile: values.mobile_number,
      email: values.email_address,
      emergency_contact: {
        person_name: values.emgergency_contact_person_name,
        mobile: values.emgergency_contact_mobile
      },
      referred_by: values.referred_by,
    }

    try {
      let res = editJobSahayak ? await patchRequestWithToken("admin", { userid: userId, ...formData }) : await postRequest('admin', formData);
      if (res.data.status === 'Success') {
        setJobShayakCreated(true);
        setFormSubmitted(false);
        if (!editJobSahayak) {
          resetForm();
          console.log("scrollTargetRef.current : " + scrollTargetRef.current)
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/jobsahayak');
          }, 1000);
        }
      }
      else {
        handleSnackBar(true, res.data.msg, "error");
        setFormSubmitted(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg.toUpperCase(), "error");
      setFormSubmitted(false);
    }
  }

  const CheckJobshayak = async (mobile_number, setFieldValue) => {
    // setFormSubmitted(true);
    try {
      console.log("hello")
      const response = await getRequest(`/jobsahayak/check-mobile/${mobile_number}`)
      console.log("hello 2")

      if (response.data.status === "Success") {
        console.log("hello 3")

        setFormSubmitted(false);
        if (!response?.data.data) {
          console.log("hello 4")

          setFieldValue("jobshayak_name_error", "Invalid Code")
          setFieldValue("jobsahayak_name", "");
        }
        else {
          setFieldValue("jobsahayak_name", JSON.stringify(response?.data?.data))
        }
      }
    } catch (err) {
      setFieldValue("jobshayak_name", "")
      console.log(err)
    }
  }

  return (
    (isIncludesValue) ? <Box className="create-jobsahayak-section" >

      <Container>
        <Box className="create-jobsahayak">
          <Box className="headingPlusBackBtn">
            <BackBtn />
            <h1 className='formHeadings'>{editJobSahayak ? "Update JobSahayak" : "Create JobSahayak"}</h1>
          </Box>
          <Box className="create-jobsahayak-form-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={JobSahayakValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ errors, values, setFieldValue, resetForm }) => (

                <Form className="create-jobsahayak-form">

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Personal Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="first_name"> First Name <span className='must-fill-fields'>*</span></label>
                          <Field id="first_name"
                            type="text" placeholder="Enter First Name"
                            name="first_name"
                            className="form-input"
                            value={values.first_name}
                          />
                          {errors.first_name && <Box className="form-error">{errors.first_name}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="middle_name"> Middle Name</label>
                          <Field id="middle_name"
                            type="text" placeholder="Enter Middle Name"
                            name="middle_name"
                            className="form-input"
                            value={values.middle_name}
                          />
                          {errors.middle_name && <Box className="form-error">{errors.middle_name}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="last_name">Last Name <span className='must-fill-fields'>*</span></label>
                          <Field id="last_name"
                            type="text" placeholder="Enter Last Name"
                            name="last_name"
                            className="form-input" />
                          {errors.last_name && <Box className="form-error">{errors.last_name}</Box>}
                        </Box>
                      </Grid>

                    </Grid>
                  </Box>

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Contact Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="mobile_number">Mobile Number <span className='must-fill-fields'>*</span></label>
                          <Field disabled={editJobSahayak} id="mobile_number"
                            type="text" placeholder="Enter Mobile Number"
                            name="mobile_number"
                            className="form-input"
                            maxLength="10" />
                          {errors.mobile_number && <Box className="form-error">{errors.mobile_number}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="email_address">Email Address</label>
                          <Field disabled={initialValues.email_address} id="email_address"
                            type="text" placeholder="Enter Email Address"
                            name="email_address"
                            className="form-input" />
                          {errors.email_address && <Box className="form-error">{errors.email_address}</Box>}
                        </Box>
                      </Grid>

                    </Grid>
                  </Box>

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Emergency Contact Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="emgergency_contact_person_name"> Emergency Contact Person Name <span className='must-fill-fields'>*</span></label>
                          <Field id="emgergency_contact_person_name"
                            type="text" placeholder="Enter emergency contact person name"
                            name="emgergency_contact_person_name"
                            className="form-input" />
                          {errors.emgergency_contact_person_name && <Box className="form-error">{errors.emgergency_contact_person_name}</Box>}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="emgergency_contact_mobile"> Emergency Contact Mobile Number <span className='must-fill-fields'>*</span></label>
                          <Field id="emgergency_contact_mobile"
                            type="text" placeholder="Enter emergency contact mobile "
                            name="emgergency_contact_mobile"
                            maxLength="10"
                            className="form-input" />
                          {errors.emgergency_contact_mobile && <Box className="form-error">{errors.emgergency_contact_mobile}</Box>}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {!editJobSahayak && <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Referred JobSahayak</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="referred_by"> Referral code </label>
                          <Field id="referred_by"
                            type="text" placeholder="Enter Referred mobile number"
                            name="referred_by"
                            className="form-input"

                            onChange={(event) => {
                              setFieldValue("referred_by", event.target.value)
                              if (values.referred_by.length === 9) CheckJobshayak(event.target.value, setFieldValue);
                              else {
                                setFormSubmitted(false)
                                setFieldValue("jobshayak_name_error", "")
                              }

                            }}
                            maxLength={10}
                          />
                          {errors.referred_by && <Box className="form-error">{errors.referred_by}</Box>}
                          <div className='bold' style={{ fontSize: '0.87rem', color: '#ad3030' }} >
                            {!values?.jobsahayak_name ?
                              <p className='text-danger' style={{ margin: "0" }}>{values.jobshayak_name_error}</p>
                              :
                              <p className='text-success' style={{ margin: "0" }}>{"Referred by : " + values?.jobsahayak_name}</p>
                            } </div>
                        </Box>
                      </Grid>
                    </Grid>

                  </Box>}

                  <Box className="form-button-section">

                    <Link to='/jobsahayak'>
                      <Button className="form-button"
                        variant="contained"
                        onClick={() => {
                          resetForm();
                        }}
                        color="error"
                      >Cancel</Button>
                    </Link>

                    <Button className="form-button"
                      variant="contained"
                      type="submit"
                      color="success"
                      disabled={formSubmitted}>{
                        editJobSahayak ? "Update" : "Create"
                      }
                    </Button>

                  </Box>
                  <FocusError values={values} handleSnackBar={handleSnackBar} />
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>


    </Box > : (<Unauthorized />)
  )

}
export default CreateJobsahayak;
