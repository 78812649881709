import { Box, Button, FormControl, TextField, Container, Grid, InputLabel, Select, MenuItem, Autocomplete, Checkbox } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { CreateCandidateValidationSchema, CreateOccupationValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { useContext, useEffect, useRef, useState, } from 'react';
import "./occupations.scss";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";



const CreateOccupations = () => {


  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([])
  const [sectorsData, setSectorsData] = useState([])
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [selectedSectors, setSelectedSectors] = useState([]);

  const handleAutocompleteChange = (event, value) => {
    setSelectedSectors(value);
  };

  const [initialValues, setInitialValues] = useState({
    occupationNameEn: "",
    occupationNameHi: "",
    occupationNameHn: "",
    ncoId: "",
    sectorId: [],

  });
  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {

    console.log("valuessssssssssssss", selectedSectors)
    const selectedSectorIds = selectedSectors.map(sector => sector._id);
    console.log("selectedSectorIds", selectedSectorIds)

    let formData = new FormData();
    formData = {
      occupationNameEn: values.occupationNameEn,
      occupationNameHi: values.occupationNameHi,
      occupationNameHn: values.occupationNameHn,
      ncoId: values.ncoId,
      sectorId: selectedSectorIds,

    }
    try {
      let res = editForm ?
        await patchRequestWithToken('admin/occupations', { userid: location.state._id, ...formData }) : await postRequest('admin/occupations', formData);

      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!editForm) {
          resetForm()
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/job-occupations');
          }, 1000);

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/job-occupations');
          }, 1000);

        }

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  }


  const getCandidateById = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid/?usertype=job_seeker&userid=${location.state?._id}`);
      setDataById(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"))
  }


  useEffect(() => {

    checkPermissionFunc();
    againCheckPermissions();

    if (location.state?._id) {
      getCandidateById()
    }
    else {
      setShowLoader(false);
    }

    if (location.state?._id) {
      const { candidate = {}, deleted = {} } = dataById;
      const {
        occupationNameEn = "",
        occupationNameHn = "",
        occupationNameHi = "",
        ncoId = "",
        sectorId = [],

      } = candidate;

      setInitialValues({
        occupationNameEn,
        occupationNameHn,
        occupationNameHi,
        ncoId,
        sectorId,
      })

      setEditForm(true);
    } else {

      setInitialValues({
        occupationNameEn: "",
        occupationNameHn: "",
        occupationNameHi: "",
        ncoId: "",
        sectorId: ""
      });
      setEditForm(false);
    }
    ;
  }, [location.state, setInitialValues, dataById._id, isIncludesValue])

  useEffect(() => {
    getAllSectors();
  }, [])


  const getAllSectors = async () => {
    try {
      let response = await getRequestWithToken(`admin/sectors`);
      console.log(response.data.data, "sectors data")
      const sectors = response.data.data.map(sector => ({
        sectorNameEn: sector.sectorNameEn,
        _id: sector._id
      }));

      console.log("sectors : ", sectors)
      setSectorsData(sectors);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }


  const top100Films = [
    { title: 'Sector 1', year: 1994 },
    { title: 'Sector 2', year: 1972 },
    { title: 'Sector 3', year: 1974 },
    { title: 'Sector 4', year: 2008 },
    { title: 'Sector 5', year: 1957 },
    { title: "Sector 6", year: 1993 },
    { title: 'Sector 7', year: 1994 },
  ];

  return (

    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-candidates-section" ref={scrollTargetRef}>

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >{editForm ? "Update Occupations" : "Create Occupations"}</h1>

            </Box>
            <Box className="create-candidates-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CreateOccupationValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-candidates-form">

                    <Box className="backgroundStyle">
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="occupationNameEn"> Occupation Name English<span className='must-fill-fields'>*</span></label>
                            <Field id="occupationNameEn"
                              type="text" placeholder="Occupation Name English"
                              name="occupationNameEn"
                              className="form-input" size="small"
                            />
                            {errors.occupationNameEn && <Box className="form-error">{errors.occupationNameEn}</Box>}

                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="occupationNameHn">Occupation Name Hinglish<span className='must-fill-fields'>*</span></label>
                            <Field id="occupationNameHn"
                              type="text" placeholder="Occupation Name Hinglish"
                              name="occupationNameHn"
                              className="form-input" />
                            {errors.occupationNameHn && <Box className="form-error">{errors.occupationNameHn}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="occupationNameHi">Occupation Name Hindi <span className='must-fill-fields'>*</span></label>
                            <Field id="occupationNameHi"
                              type="text" placeholder="Occupation Name Hindi "
                              name="occupationNameHi"
                              className="form-input" />
                            {errors.occupationNameHi && <Box className="form-error">{errors.occupationNameHi}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="ncoId">NCO ID </label>
                            <Field id="ncoId"
                              type="text" placeholder="Nco Id"
                              name="ncoId"
                              className="form-input" />
                            {errors.ncoId && <Box className="form-error">{errors.ncoId}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="sectorId">Sectors</label>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              options={sectorsData}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.sectorNameEn}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.sectorNameEn}
                                </li>
                              )}
                              style={{ width: 500 }}
                              renderInput={(params) => (
                                <TextField {...params} label="" placeholder="Sectors" />
                              )}
                              onChange={handleAutocompleteChange}
                            />
                            {errors.sectorId && <Box className="form-error">{errors.sectorId}</Box>}
                          </Box>

                        </Grid>


                      </Grid>
                    </Box>

                    <Box className="form-button-section">

                      <Link to='/candidate'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}

                      >{editForm ? "Update" : "Create"}</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>


      </Box >}
    </> : (<Unauthorized />)

  )

}
export default CreateOccupations;