import "./jobsection.scss";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useContext } from "react";
import {
  CircularProgress,
  Button,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import { getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import CloseIcon from "@mui/icons-material/Close";
import { formatDate } from "../../utils/functions";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import MaterialTable from "../../components/materialTable/MaterialTable";

const RejectedJobs = () => {
  const [rejectedJobData, setRejectedJobData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [isTheDataLoaded, setIsTheDataLoaded] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [activeData, setActiveData] = useState(null);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: []
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No.',
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + (pageIndex * pageSize)}</span>;
      },
    },
    {
      accessorKey: 'jobId',
      header: 'Job Id',
      size: 160,
    },
    {
      accessorKey: 'jobRoleNameEn',
      header: 'Job Title',
      size: 250,
      Cell: ({ renderedCellValue }) => (
        <div style={{ textTransform: "capitalize" }}>{renderedCellValue}</div>
      ),
    },
    {
      accessorKey: 'state',
      header: 'State',
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.state,
    },
    {
      accessorKey: 'city',
      header: 'City',
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.city,
    },
    {
      accessorKey: 'date_and_time',
      header: 'Date & Time',
      enableColumnFilter: false,
      size: 200,
      Cell: ({ row }) => {
        const createdAt = row.original.createdAt || "--";
        return (
          <span>
            {new Date(createdAt).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
            })}
          </span>
        );
      },
    },
    {
      accessorKey: 'company_name',
      header: 'Company Name',
      size: 500,
      accessorFn: (row) => row.companyName,
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 200,
      enableColumnActions: false,
      Cell: ({ row }) => (
        canRead && (
          <Button
            variant="contained"
            className="newBtns"
            color="primary"
            disabled={!row.original.comments}
            onClick={() => {
              setOpenChat(true);
              setActiveData(row.original);
            }}
          >
            View Comment
          </Button>
        )
      ),
    },
  ];

  const getRejectedJobs = async () => {
    setLoadingDataInGrid(true);
    try {
      let response = await getRequestWithToken(
        `/admin/rejected-job?isrejectedbyadmin=true&sortBy=-updatedAt&limit=${pagination.pageSize}&page=${pagination.pageIndex + 1}`
      );
      setRejectedJobData(response?.data?.data);
      setRowCount(response?.data?.totalcounts);
      setLoadingDataInGrid(false);
      setShowLoader(false);
      setIsTheDataLoaded(true);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setShowLoader(false);
      setLoadingDataInGrid(false);
      setIsTheDataLoaded(true);
    }
  };
  const closeChat = () => {
    setOpenChat(false);
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "All Jobs";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("All Jobs"))
  }

  useEffect(() => {
    // getRejectedJobs();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  useEffect(() => {
    getRejectedJobs();

  }, [pagination.pageIndex, pagination.pageSize]);




  return ((isIncludesValue) ?
    <Box className="rejected-jobs-section">
      <Typography className="section-title">Rejected Jobs</Typography>
      <Box className="rejected-jobs-data">
        {isTheDataLoaded ? (
          <MaterialTable filteredRows={rejectedJobData} columns={columns} rowCount={rowCount} isLoading={loadingDataInGrid} pagination={pagination} columnPinning={columnPinning} setPagination={setPagination} setColumnPinning={setColumnPinning} />
        ) : (
          <Box
            sx={{ width: "100%", height: "100%" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {openChat && (
        <ClickAwayListener onClickAway={closeChat}>
          <Box className="chat-box">
            <Box className="inner-wrapper">
              <Typography className="chat-title">Appeal</Typography>
              <CloseIcon className="close-icon" onClick={closeChat} />
              <Box className="admin-message">
                <Typography className="title">Admin</Typography>
                <Typography className="time">
                  {formatDate(
                    activeData?.comments?.adminComment?.[0]?.commentedAt
                  )}
                </Typography>
                <Typography className="message">
                  {activeData?.comments?.adminComment?.[0]?.comment}
                </Typography>
              </Box>
              <Box className="appeal-message">
                <Typography className="title">
                  {activeData?.aboutOrganisation?.organisationName || ""}
                </Typography>
                <Typography className="time">
                  {formatDate(
                    activeData?.comments?.employerComment?.[0]?.commentedAt
                  ) || ""}
                </Typography>
                <Typography className="message">
                  {activeData?.comments?.employerComment?.[0]?.comment ||
                    "No Comments till now"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </Box> : (<Unauthorized />)
  );
};

export default RejectedJobs;
