import "./announcement.scss";
import { Box, Button, Snackbar, Alert, Chip, FormGroup, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";


const JwpList = () => {
  const [jwpData, setJwpData] = useState([]);
  const [jwpDeleted, setJwpDeleted] = useState(false);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  // const createAnnouncement = () => {
  //   navigate('/announcement/create');
  // }

  const [showDeletedRows, setShowDeletedRows] = useState(true);

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const filteredRows = showDeletedRows
    ? jwpData
    : jwpData.filter(jwp => jwp.hasOwnProperty('deleted') && jwp.deleted.is_deleted === false);


  const getBusinessType = (bType) => {
    if (bType === 0) return "NA";
    if (bType === 1) return "Individual";
    if (bType === 2) return "Company";
  }

  const getBusinessCategory = (bType) => {
    if (bType === 0) return "NA";
    if (bType === 1) return "Retail";
    if (bType === 2) return "Service";
    if (bType === 3) return "Entertainment";
    if (bType === 4) return "NGO";
    if (bType === 5) return "Government";
  }

  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 70,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },

    {
      field: 'firstName',
      headerName: 'Name',
      width: 200,
      valueGetter: (params) => {
        const { firstName = "", middleName = "", lastName = "" } = params.row;
        const fullName = `${firstName} ${middleName} ${lastName}`;
        return fullName;
      },
    },

    {
      field: 'phone',
      headerName: 'Phone',
      width: 200,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },

    {
      field: 'businessCategory',
      headerName: 'Bussiness Category',
      width: 200,
      valueGetter: (params) => (
        params.row?.businessCategory !== undefined && params.row?.businessCategory !== null
          ? getBusinessCategory(params.row.businessCategory)
          : "--"
      ),

    },


    {
      field: 'businessName',
      headerName: 'Bussiness Name',
      width: 200,
      valueGetter: (params) => (params.row?.businessName ? params.row?.businessName : "--"),
    },

    {
      field: 'businessType',
      headerName: 'Bussiness Type',
      width: 200,
      valueGetter: (params) => (
        params.row?.businessType !== undefined && params.row?.businessType !== null
          ? getBusinessType(params.row.businessType)
          : "--"
      ),
    },

    {
      field: 'businessCity',
      headerName: 'Bussiness City',
      width: 200,
      valueGetter: (params) => (params.row?.businessCity ? params.row?.businessCity : "--"),
    },

    {
      field: 'businessState',
      headerName: 'Bussiness State',
      width: 200,
      valueGetter: (params) => (params.row?.businessState ? params.row?.businessState : "--"),
    },
    // {
    //   field: 'jobShayak',
    //   headerName: 'Job Shayak',
    //   width: 250,
    //   valueGetter: (params) => (params.row?.jobSahayak ? params.row?.jobSahayak : "--"),
    // },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) => (new Date(params.value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }))
    },
    
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        return (
         <Box style={{ display: "flex", gap: "2rem" }}>
          {canUpdate ? (<Tooltip title="Edit" >
                <EditIcon sx={{ cursor: "pointer" }} color="primary" onClick={() => navigate("/jwp/create", {
                  state: params.row,
                })} />
              </Tooltip>) : ""}
              {/* {canUpdate && <Button color="info" variant="contained" onClick={() => navigate("/jwp/assign-josahayak", {
                  state: params.row,
                })} > {params.row.jobSahayak !== "" ? "Reassign Jobsahayak" : "Assign JobSahayak"} </Button>} */}
           
          </Box >
        );
      },
    }

  ];

  const getAllJwpList = async () => {
    try {
      let response = await getRequestWithToken(`admin/job-wahan-partner?page=${parseInt(pageNumber + 1)}&limit=${pageSize}`);
      setRowCount(response?.data?.totalCount);
      setJwpData(response.data.data);
      // console.log(response.data.data)
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }

  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }

  useEffect(() => {
    getAllJwpList();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue])

  const createNewJWP = () => {
    navigate('/jwp/create');
  }
  return ((isIncludesValue) ?
    <Box className="employers-section">
      <Box className="employers-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
         {canCreate ? (<Button variant="contained" color="success" onClick={createNewJWP}>
            Create JWP
          </Button>) : ""}
      </Box>

      <Box className="employers-data">
        {showLoader ? <ReusableLoader /> : <DataGrid
          rowCount={rowCount}
          pagination
          rows={filteredRows}
          columns={columns}
          getRowId={(jwpData) => jwpData._id} //
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
          onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
          loading={loadingDataInGrid}

        />}
      </Box>
      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this user"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          // deleteJwp();
        }}
      />
    </Box> : (<Unauthorized />)
  )
}
export default JwpList;
