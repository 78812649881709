import ModalComponent from '../../components/ModalComponent/ModalComponent'
import { Box, Button, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import DragAndDropComponent from '../../components/DragAndDropComponent/DragAndDropComponent';
import { Formik } from 'formik';
import { ValidationSchemaForJobConfiguration } from '../../utils/Validations';
import { getRequestWithToken, patchRequestWithToken, postRequest } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { useState } from 'react';

const JobConfigurationCreateEditModal = ({ openModal, setOpenModal, editId, editData, getAllJobTitles, showLoaderForTheGrid, setShowLoaderForTheGrid }) => {
    const handleCloseBtnClick = () => {
        setOpenModal(false);
    }

    const [initiialValues, setInitialValues] = useState({
        jobTitleInEnglish: "",
        jobTitleInHindi: "",
        jobTitleInHinglish: "",
        svgPngImg: ""
    });

    const { handleSnackBar } = useContext(GlobalContext);

    const handleSubmit = async (values) => {
        console.log(values, " these are values");
        let formData = new FormData();
        formData.append("title_en", values.jobTitleInEnglish);
        formData.append("title_hi", values.jobTitleInHindi);
        formData.append("title_hn", values.jobTitleInHinglish);
        if (editData) {
            formData.append("id", editId)
            if (Array.isArray(values.svgPngImg)) {
                formData.append("image", values.svgPngImg[0])
            }
            //  else {
            //     formData.append("image", values.svgPngImg);
            // }
        }
        else {
            formData.append("image", values.svgPngImg[0])
        }

        try {
            const response = editData ? await patchRequestWithToken("admin/jobtitle", formData) : await postRequest("admin/jobtitle", formData);
            const { msg, status } = response.data;
            console.log(response.data);
            if (status === "Success") {
                await getAllJobTitles()
                setOpenModal(false);
                handleSnackBar(true, response.data.msg, "success");
            }
            else {
                await getAllJobTitles()
                setOpenModal(false);
                handleSnackBar(true, response.data.msg, "error");
            }
        } catch (error) {
            handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
        }
    }

    useEffect(() => {
        if (editData) {
            setInitialValues({
                ...editData
            });
        } else {
            setInitialValues(
                {
                    jobTitleInEnglish: "",
                    jobTitleInHindi: "",
                    jobTitleInHinglish: "",
                    svgPngImg: ""
                }
            );
        }
    }, [editData])

    return (
        <Box>
            <ModalComponent
                openModal={openModal}
                setOpenModal={setOpenModal}
                modalTitle={editData ? "Update Job Title" : "Create Job Title"}
                handleOnCloseBtnClick={handleCloseBtnClick}
            >
                <Box>
                    <Formik initialValues={initiialValues}
                        validationSchema={ValidationSchemaForJobConfiguration}
                        onSubmit={(values) => {
                            console.log("form submitted");
                            setShowLoaderForTheGrid(true);
                            handleSubmit(values);
                        }}
                        enableReinitialize
                    >
                        {({ errors, values, handleChange, handleBlur, handleSubmit, touched, setFieldValue }) => {

                            return <form
                                style={{ display: "block" }}
                                onSubmit={handleSubmit}
                                noValidate
                            >
                                <Box marginX={2} marginTop={2}>
                                    <Grid spacing={2} container>
                                        <Grid item xs={12} sm={6}>

                                            <TextField
                                                value={values.jobTitleInEnglish}
                                                name="jobTitleInEnglish"
                                                fullWidth size="small"
                                                label="Enter Job Title in English"
                                                onChange={handleChange}
                                                error={Boolean(errors.jobTitleInEnglish)}
                                                onBlur={handleBlur}
                                                helperText={errors.jobTitleInEnglish}
                                                type="text"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <TextField
                                                value={values.jobTitleInHindi}
                                                name="jobTitleInHindi"
                                                fullWidth size="small"
                                                label="Enter Job Title in Hindi"
                                                onChange={handleChange}
                                                error={Boolean(errors.jobTitleInHindi)}
                                                helperText={errors.jobTitleInHindi}
                                                type="text"
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} >
                                            <TextField
                                                value={values.jobTitleInHinglish}
                                                name="jobTitleInHinglish"
                                                fullWidth size="small"
                                                label="Enter Job Title in Hinglish"
                                                onChange={handleChange}
                                                error={Boolean(errors.jobTitleInHinglish)}
                                                helperText={errors.jobTitleInHinglish}
                                                type="text"
                                                onBlur={handleBlur}
                                            />
                                        </Grid>

                                        <Grid justifyContent="center" item xs={12}>
                                            <Box display="flex" className="cursor-pointer" justifyContent="center" width="100%" height={200} >
                                                <DragAndDropComponent
                                                    dndTitle="Please Drag and Drop your svg , png File" dndwidth={50} dndHeight={100}
                                                    name="svgPngImg"
                                                    onChange={(file) => {
                                                        setFieldValue("svgPngImg", file);
                                                    }}
                                                    editData={editData}
                                                />
                                            </Box>
                                            {errors.svgPngImg && <Typography fontSize="14px" color="#d32f2f" marginTop={2} textAlign="center" >{errors.svgPngImg}</Typography>}

                                        </Grid>


                                    </Grid>

                                </Box>
                                <Box>
                                    <Box
                                        marginTop={5}
                                        display="flex"
                                        justifyContent="flex-end"
                                        sx={{ width: "100%", borderTop: "1px solid #bdbdbd" }}
                                        paddingY={1.5}
                                    >
                                        <Box marginRight={1} >
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                color="error"
                                                onClick={() => {
                                                    setOpenModal(false);
                                                }}
                                                disabled={showLoaderForTheGrid}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                        <Box marginRight={1}>
                                            <Button
                                                disableElevation
                                                variant="contained"
                                                type="submit"
                                                endIcon={showLoaderForTheGrid ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : ""}
                                                disabled={showLoaderForTheGrid}
                                            >
                                                {editData ? "Update" : "Submit"}

                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </form>
                        }}

                    </Formik>
                </Box>
            </ModalComponent>
        </Box>
    )
}

export default JobConfigurationCreateEditModal