import React from 'react';
import { Navigate } from 'react-router-dom';
import Layout from "./components/layout/Layout";

const isAuthenticated = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return true;
  } else {
    return false;
  }
}

const ProtectedRoute = ({ element: Component }) => (

  isAuthenticated() ?
    <Layout>
      <Component />
    </Layout>
    : <Navigate to='/' />

)

export default ProtectedRoute