import "./announcement.scss";
import { Box, Button, Snackbar, Alert, Chip, FormGroup, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";


const Announcement = () => {
  const [announcementData, setAnnouncementData] = useState([]);
  const [announcementDeleted, setAnnouncementDeleted] = useState(false);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const createAnnouncement = () => {
    navigate('/announcement/create');
  }

  const [showDeletedRows, setShowDeletedRows] = useState(true);

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const filteredRows = showDeletedRows
    ? announcementData
    : announcementData.filter(announcement => announcement.hasOwnProperty('deleted') && announcement.deleted.is_deleted === false);


  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 70,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },

    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },

    {
      field: 'message',
      headerName: 'Message',
      width: 300,
    },

    {
      field: 'bgColor',
      headerName: 'BG Color',
      width: 200,
    },

    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) => (new Date(params.value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }))
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      renderCell: (params) => {

        return (
          params?.row?.deleted?.is_deleted ?
            < Box style={{ display: "flex", gap: "2rem" }}>
              {canUpdate ? (<EditIcon color="disabled" />) : ""}
              {canDelete ? (<DeleteIcon color="disabled" />) : ""}
            </Box > :
            < Box style={{ display: "flex", gap: "2rem" }}>
              {canUpdate ? (<EditIcon sx={{ cursor: "pointer" }} color="primary" onClick={() => navigate('/announcement/create', {
                state: params.row
              })} />) : ""}

              {canDelete ? (<DeleteIcon sx={{ cursor: "pointer" }} color="error" onClick={() => {
                setOpenDeleteModal(true);
                setCandidateId(params.row.id);
              }} />) : ""}

            </Box >
        );
      },

    },
    {
      field: 'is_deleted',
      headerName: 'Deleted',
      width: 100,
      renderCell: (params) => {

        return (params?.row?.deleted?.is_deleted && <span>
          <Chip variant="outlined" label="Deleted" color="error" />
        </span>)
      },
    },

  ];


  const deleteAnnouncement = async () => {

    let formData = new FormData();
    formData = {
      announcementData: {
        id: candidateId
      },
    }

    try {
      let response = await deleteRequest('admin/announcement', formData);

      handleSnackBar(true, response.data.msg, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllAnnouncement();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  }

  const getAllAnnouncement = async () => {
    try {
      let response = await getRequestWithToken(`admin/announcement`);
      // setRowCount(response?.data?.totalCount);
      setAnnouncementData(response.data.data);
      // console.log(response.data.data)
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }

  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }

  useEffect(() => {
    getAllAnnouncement();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue])


  return ((isIncludesValue) ?
    <Box className="employers-section">
      <Box className="employers-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        {canCreate ? (<Button variant="contained" color="success" onClick={createAnnouncement}>
          Create New Announcement
        </Button>) : ""}

      </Box>

      <Box className="employers-data">
        {showLoader ? <ReusableLoader /> : <DataGrid
          // rowCount={rowCount}
          pagination
          rows={filteredRows}
          columns={columns}
          getRowId={(announcementData) => announcementData._id} //
          // paginationMode="server"
          paginationMode="client"
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
          onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
          loading={loadingDataInGrid}

        />}
      </Box>
      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this Announcement ?"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deleteAnnouncement();
        }}
      />
    </Box> : (<Unauthorized />)
  )
}
export default Announcement;
