import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
// import "./employer.scss";
import BackBtn from '../../components/backButton/BackBtn';


const JobSahayakDetails = () => {
  const { jobSahayakId } = useParams();
  const [jobSahayakData, setJobSahayakData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);

  const getJobSahayakDetails = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid?usertype=jobsahayak&userid=${jobSahayakId}`);
      setJobSahayakData(response.data.data[0]);
      // console.log("data : ", JSON.stringify(response.data.data.first_name))
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getJobSahayakDetails();
    // console.log("jobSahayakData : ", JSON.stringify(jobSahayakData[0].first_name))
  }, [jobSahayakId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust the format as needed
  };


  useEffect(() => {
    console.log()
  }, [jobSahayakData]);

  return (
    (showLoader) ? <ReusableLoader /> :
      <Box className="create-candidates-section">

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >JobSahayak Details</h1>
            </Box>
            <Box className="create-candidates-form-wrapper">

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeadng '>Personal Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" > First Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobSahayakData.first_name}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> Middle Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.middle_name ? jobSahayakData.middle_name : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Last Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.last_name ? jobSahayakData.last_name : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Blood Group</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.blood_group ? jobSahayakData.blood_group : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Date of Birth</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.date_of_birth ? formatDate(jobSahayakData.date_of_birth) : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >DL Number</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.dl_number ? jobSahayakData.dl_number : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Vehicle Registration Number</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.vehicle_registration_number ? jobSahayakData.vehicle_registration_number : "--"}</Typography>
                    </Box>
                  </Grid>

                </Grid>
              </Box>

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Contact Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Mobile</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobSahayakData.mobile ? jobSahayakData.mobile : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Email</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{jobSahayakData.email ? jobSahayakData.email : "--"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Address</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.location?.address ? jobSahayakData.location?.address : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" > Postal Code</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.location?.postal_code ? jobSahayakData.location?.postal_code : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >City</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.location?.city ? jobSahayakData.location?.city : "--"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Village</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.location?.village ? jobSahayakData.location?.village : "--"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Panchayat</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.location?.panchayat ? jobSahayakData.location?.panchayat : "--"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >District</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.location?.district ? jobSahayakData.location?.district : "--"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >State</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.location?.state ? jobSahayakData.location?.state : "--"}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Grid container spacing={2} className='cstmFormGridEmployer'>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="backgroundStyle">
                    <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Education Qualification</Typography>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <Typography variant="subtitle1">Qualification</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.education_qualification ? jobSahayakData.education_qualification : "--"}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Box className="backgroundStyle">
                    <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Work & Expereince</Typography>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <Typography variant="subtitle1">Past Work Experience</Typography>
                          <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>
                          {jobSahayakData.experience && `${jobSahayakData.experience} Years`}
                          </Typography>
                        </Box>
                      </Grid>

                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Emergency Contact Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">Emergency Contact Person Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.emergency_contact?.person_name ? jobSahayakData.emergency_contact?.person_name : "--"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">Emergency Contact Mobile Number</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.emergency_contact?.mobile ? jobSahayakData.emergency_contact?.mobile : "--"}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Referred JobSahayak Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.referred_jobsahayak?.first_name ? `${jobSahayakData.referred_jobsahayak?.first_name} ${jobSahayakData.referred_jobsahayak?.middle_name} ${jobSahayakData.referred_jobsahayak?.last_name}` : "--"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">Mobile Number</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{jobSahayakData.referred_jobsahayak?.mobile ? jobSahayakData.referred_jobsahayak?.mobile : "--"}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

            </Box>
          </Box>
        </Container>

      </Box >

  );
};

export default JobSahayakDetails;
