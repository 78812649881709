import React from 'react'
import "./unauthorized.scss"

export default function Unauthorized() {
  return (
    <div className='mainContainer'>
      <img src="https://jobyahanp.s3.ap-south-1.amazonaws.com/images/logo/access_denied.svg" alt="" />
      <h1>Access Denied</h1>
      <p>You do not have permission to access this page.</p>
    </div>

  )
}
