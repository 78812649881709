import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect } from "react";
import { getRequestWithToken, deleteRequest } from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import MaterialTable from "../../components/materialTable/MaterialTable";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Notifications = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [campaignId, setCampaignId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);
  const [tabvalue, setTabValue] = useState(1);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const currentTabSelect = (event, newValue) => {
    setTabValue(newValue);
  };

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const handleCompleteProfilesChange = () => {
    setShowCompleteProfiles(!showCompleteProfiles);
  };

  const filteredRows = campaignData.filter((candidate) => {
    const isDeletedMatch =
      showDeletedRows ||
      (candidate.deleted && candidate.deleted.is_deleted === false);
    const isProfileCompleteMatch =
      showCompleteProfiles ||
      (candidate.is_profile_complete &&
        candidate.is_profile_complete.toLowerCase() === "yes");

    return isDeletedMatch && isProfileCompleteMatch;
  });

  const createNewNotification = () => {
    navigate("/notifications/create");
  };

  const BannerColumns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },

    // {
    //   accessorKey: "campaignId",
    //   header: "Campaign Id",
    //   size: 220,
    // },

    {
      accessorKey: "tag",
      header: "Tag Name",
      size: 100,
    },
    {
      accessorKey: "title_en",
      header: "Title (En)",
      size: 200,
      Cell: ({ cell }) => <span>{cell?.row?.original?.title?.en}</span>,
    },

    {
      accessorKey: "title_hn",
      header: "Title (Hn)",
      size: 200,
      Cell: ({ cell }) => <span>{cell?.row?.original?.title?.hn}</span>,
    },

    {
      accessorKey: "title_hi",
      header: "Title (Hi)",
      size: 200,
      Cell: ({ cell }) => <span>{cell?.row?.original?.title?.hi}</span>,
    },

    {
      accessorKey: "ctaButton",
      header: "CTA Button Text",
      size: 200,
    },

    {
      accessorKey: "expiry",
      header: "Expiry Date and Time",
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Date and Time",
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
  ];

  const NotificationColumns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },

    {
      accessorKey: "campaignId",
      header: "Campaign Id",
      size: 220,
    },

    {
      accessorKey: "campaignName",
      header: "Campaign Name",
      size: 220,
    },
    {
      accessorKey: "numberOfUploads",
      header: "Number Of Uploads",
      size: 200,
    },

    {
      accessorKey: "scheduledDateOrTime",
      header: "Scheduled Date and Time",
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "Date and Time",
      size: 200,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 200,
      Cell: ({ row }) => {
        const currentTime = new Date();
        const scheduledDateOrTime = new Date(
          row?.original?.scheduledDateOrTime
        );

        if (scheduledDateOrTime > currentTime && canDelete) {
          return (
            <Tooltip title="Delete Campaign">
              <DeleteIcon
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  setOpenDeleteModal(true);
                  setCampaignId(row.original.campaignId);
                }}
              />
            </Tooltip>
          );
        }

        return "";
      },
    },
  ];

  const deleteCampaign = async () => {
    try {
      let response = await deleteRequest(
        `/admin/campaign?campaignId=${campaignId}`
      );
      response = response.data;
      if (response?.status === "Success") {
        handleSnackBar(true, response?.msg, "success");
        setOpenDeleteModal(false);
        getAllNotificationAndBanner();
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  };

  const getAllNotificationAndBanner = async () => {
    setLoadingDataInGrid(true);
    try {
      let response;
      if (tabvalue === 0) {
        response = await getRequestWithToken("/admin/banner");
      } else if (tabvalue === 1) {
        response = await getRequestWithToken(
          `admin/campaign?modeofsend=mobile notification&sort=-1&page=${parseInt(
            pagination.pageIndex + 1
          )}&limit=${pagination.pageSize}`
        );
      }
      setCampaignData(response.data.data);
      setRowCount(response.data.totalCount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setShowLoader(false);
      setLoadingDataInGrid(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Marketing";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Marketing"));
  };

  useEffect(() => {
    getAllNotificationAndBanner();
  }, [pagination.pageIndex, pagination.pageSize, tabvalue]);

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  return isIncludesValue ? (
    <Box className="candidates-section">
      <h3 style={{ margin: "0px" }}>Notifications</h3>

      <Box
        className="candidates-top-section"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {canCreate ? (
          <Button
            variant="contained"
            color="success"
            onClick={createNewNotification}
          >
            Create Notification
          </Button>
        ) : (
          ""
        )}
      </Box>
      <Box className="candidates-section-data">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabvalue}
            onChange={currentTabSelect}
            aria-label="basic tabs example"
          >
            {/* <Tab
              label="Banner"
              style={{ fontWeight: "700" }}
              {...a11yProps(0)}
            /> */}
            <Tab
              label="Notification"
              style={{ fontWeight: "700" }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        {showLoader ? (
          <ReusableLoader />
        ) : (
          <MaterialTable
            filteredRows={filteredRows}
            columns={tabvalue === 0 ? BannerColumns : NotificationColumns}
            rowCount={rowCount}
            isLoading={loadingDataInGrid}
            pagination={pagination}
            columnPinning={columnPinning}
            setPagination={setPagination}
            setColumnPinning={setColumnPinning}
          />
        )}
        <SureYouWantToDeletModal
          modalTitle=""
          dialogBody="Are you sure you want to delete this notification ?"
          buttonText="Yes"
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          onYesClick={() => {
            deleteCampaign(campaignId);
          }}
        />
      </Box>
    </Box>
  ) : (
    <Unauthorized />
  );
};

export default Notifications;
