import React from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';
import { Box, Chip, Typography } from '@mui/material';

const MaterialTable = ({
  columns,
  rowCount,
  filteredRows,
  isLoading,
  pagination,
  setPagination,
  columnPinning,
  setColumnPinning,
  hideFooter = true
}) => {

  const table = useMaterialReactTable({
    columns,
    data: filteredRows,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    muiSkeletonProps: {
      animation: 'wave',
    },
    muiLinearProgressProps: {
      color: 'secondary',
    },
    muiCircularProgressProps: {
      color: 'secondary',
    },
    manualPagination: true,
    rowCount,
    onPaginationChange: setPagination,
    state: {
      isLoading,
      pagination,
      columnPinning,
    },
    onColumnPinningChange: setColumnPinning,
    enableRowActions: false,
    initialState: {
      columnPinning: { left: [], right: [] },
    },
    layoutMode: 'grid',
    muiTableContainerProps: { sx: { maxHeight: '500px' } },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {!Number.isNaN(totalPages) && (
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              Page {pageIndex + 1} of {totalPages}
            </Typography>
          )}
        </Box>
      );
    },

    enableBottomToolbar: hideFooter,
  });

  return <MaterialReactTable table={table} />;
};

export default MaterialTable;