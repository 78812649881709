import {
  Box,
  Button,
  Container,
  Grid,
  Select,
  MenuItem,
  Typography,
  FormControl,
  Switch,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import {
  NotificationCampaignFirstFormSchema,
  NotificationCampaignSecondFormSchema,
  thirdFormNotificationValidationSchema,
  thirdFormBannerValidationSchema,
} from "../../utils/Validations";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  getRequestWithToken,
  patchRequestWithToken,
  postRequest,
  deleteRequest,
} from "../../utils/ApiRequests";
import { useContext, useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import FocusError from "../../components/FocusError/FocusError";
import BackBtn from "../../components/backButton/BackBtn";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import "./marketing.scss";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LinearProgress from "@mui/material/LinearProgress";
import CreatableSelect from "react-select/creatable";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";

const CreateNotification = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [dataById, setDataById] = useState([]);
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);
  const [targetCandidates, setTargetCandidates] = useState([]);
  const [targetCandidatesCount, setTargetCandidatesCount] = useState(null);

  const [initialValues2, setInitialValues2] = useState({
    pincode: "",
    schedule: "",
    tagText: "",
    titleEn: "",
    titleHn: "",
    titleHi: "",
    redirectTo: "",
    bodyEn: "",
    bodyHn: "",
    bodyHi: "",
    ctaButtonText: "",
    expiryDateAndTime: "",
    sendLater: "",
    scheduleTime: "",
  });

  const [secondFormValues, setSecondFormValues] = useState({
    condition: "",
    pincode: "",
    jobId: "",
    radius: "",
    eligibleCount: 0,
  });

  const [thirdFormNotificationValues, setThirdFormNotificationValues] =
    useState({
      title: "",
      description: "",
    });

  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const [formNumber, setFormNumber] = useState(1);

  const [firstFormValues, setFirstFormValues] = useState({
    notificationName: "",
    tag: null,
    notificationType: "",
    scheduledTime: "",
    modeOfSend: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleCreate = (inputValue, setFieldValue) => {
    setValue({ value: inputValue, label: inputValue });
    setFieldValue("tag", { value: inputValue, label: inputValue });
  };

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const redirectToData = [
    { id: 1, name: "KYC Screen", value: "KYC_SCREEN" },
    { id: 2, name: "Profile Section", value: "PROFILE_SECTION" },
    { id: 3, name: "Saved Jobs", value: "SAVED_JOBS" },
    { id: 4, name: "Applied Jobs", value: "APPLIED_JOB" },
    { id: 5, name: "Employer", value: "EMPLOYER" },
    { id: 6, name: "Jobs", value: "JOBS" },
    { id: 7, name: "All Occupation", value: "ALL_OCCUPATION" },
    { id: 8, name: "All Sectors", value: "ALL_SECTORS" },
  ];

  const handleSubmitFirstForm = async (values) => {
    setFormSubmitted(true);
    const {
      notificationName,
      tag,
      notificationType,
      scheduledTime,
      modeOfSend,
    } = values;

    let newFirstFormValues = new FormData();

    if (modeOfSend === "banner") {
      newFirstFormValues = {
        bannerType: 2,
        tag: tag.value,
        name: notificationName,
        type: notificationType,
        sendLater: scheduledTime
          ? scheduledTime
          : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      };
    }

    if (modeOfSend === "mobile notification") {
      newFirstFormValues = {
        campaignName: notificationName,
        tag: tag.value,
        campaignType: notificationType,
        scheduledTime: scheduledTime ? scheduledTime : new Date().toISOString(),
        modeOfSend: modeOfSend,
      };
    }
    // console.log(newFirstFormValues, "first Form Values");
    setFirstFormValues(values);
    // setFormNumber(2);
    // setCampaignId("CAMP12034");

    try {
      let res = await postRequest(`admin/campaign/create`, newFirstFormValues);

      if (res.data.status === "Success") {
        setFormSubmitted(false);
        setFormNumber(2);
        setCampaignId(res?.data?.data?.campaignId);
      } else {
        setFormSubmitted(false);
        setIsLoading(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      setIsLoading(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const conditionData = [
    // { id: 1, label: "Job Id", value: "Job Id" },
    { id: 2, label: "Pincode", value: "Pincode" },
    { id: 3, label: "Not Applied on Job", value: "Not Applied on Job" },
    { id: 4, label: "KYC Not completed", value: "Kyc not completed" },
    { id: 5, label: "Last Activity 1 week ago", value: "Last Activity 1 Week" },
    {
      id: 6,
      label: "Last Activity 1 month ago",
      value: "Last Activity 1 Month",
    },
    { id: 7, label: "All Users", value: "All Users" },
  ];

  const handleSubmitSecondForm = async (values, { setFieldValue }) => {
    const { condition, jobId, pincode, radius } = values;
    const { modeOfSend } = firstFormValues;
    // const modeOfSend = "mobile notification";
    setLoading(true);
    let formData = new FormData();
    formData = {
      campaignId: campaignId,
      condition: condition,
      modeOfSend: modeOfSend,
      pincode: pincode,
      range: radius,
      jobId: jobId,
    };
    console.log(formData, "formData");
    try {
      let res = await patchRequestWithToken(
        `admin/campaign/check-count`,
        formData
      );

      if (res.data.status === "Success") {
        setFormSubmitted(false);
        setFormNumber(2);
        setFieldValue("eligibleCount", res.data.totalCount);
        setSecondFormValues((prevStat) => {
          return {
            ...prevStat,
            eligibleCount: res.data.totalCount,
          };
        });
        setLoading(false);
      } else {
        setFormSubmitted(false);
        setLoading(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      setLoading(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const handleSubmitSecondBannerForm = async (values, { setFieldValue }) => {
    const { condition, jobId, pincode, radius } = values;
    let ConditionValue = conditionData?.find(
      (item) => item.value === condition
    );
    setLoading(true);
    let formData = new FormData();
    formData = {
      condition: {
        value_type: ConditionValue.id,
        value: jobId || pincode,
        radius: radius,
      },
    };
    try {
      let res = await patchRequestWithToken(`admin/banner/count`, formData);

      if (res.data.status === "Success") {
        setFormSubmitted(false);
        setFormNumber(2);
        setFieldValue("eligibleCount", res.data.totalCount);
        setSecondFormValues((prevStat) => {
          return {
            ...prevStat,
            eligibleCount: res.data.totalCount,
          };
        });
        setLoading(false);
      } else {
        setFormSubmitted(false);
        setLoading(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      setLoading(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const handleSubmit2 = async (values, { resetForm }) => {
    const {
      tagText,
      titleEn,
      titleHi,
      titleHn,
      redirectTo,
      bodyEn,
      bodyHi,
      bodyHn,
      ctaButtonText,
      expiryDateAndTime,
      sendLater,
      scheduleTime,
    } = values;

    const { notificationType, condition, pincode, jobId, radius } =
      initialValues2;
    let formData = new FormData();
    formData = {
      tag: tagText,
      title: {
        en: titleEn,
        hi: titleHi,
        hn: titleHn,
      },
      body: { en: bodyEn, hi: bodyHi, hn: bodyHn },
      ctaButton: ctaButtonText,
      type: redirectTo,
      reference: redirectTo,
      expiry: expiryDateAndTime,
      sendLater: sendLater ? scheduleTime : "",
      bannerType: notificationType,
      condition: {
        value_type: 1,
        value: jobId || pincode || "",
        radius: radius,
        // value_type: condition,
      },
    };
    setFormSubmitted(true);

    try {
      let res = await postRequest(`admin/banner`, formData);
      if (res.data.status === "Success") {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "success");
        resetForm(); // Reset the form after successful submission
        setTimeout(() => {
          navigate("/notifications");
        }, 500);
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const handleSubmitThirdNoficationForm = async (values, { resetForm }) => {
    setFormSubmitted(true);
    const { title, description } = values;
    const { modeOfSend } = firstFormValues;
    let formData = new FormData();
    formData = {
      campaignId: campaignId,
      modeOfSend: modeOfSend,
      title: title,
      body: description,
    };

    try {
      let res = await postRequest(`admin/campaign/notification`, formData);
      if (res.data.status === "Success") {
        let response2 = await patchRequestWithToken("admin/campaign/activate", {
          campaignId: campaignId,
        });
        if (response2.data.status === "Success") {
          handleSnackBar(true, res.data.msg, "success");
          setFormSubmitted(false);
          setTimeout(() => {
            navigate("/notifications");
          }, 500);
        }
      } else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error");
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  };

  const deleteCampaign = async () => {
    try {
      let response = await deleteRequest(
        `/admin/campaign?campaignId=${campaignId}`
      );
      response = response.data;
      if (response?.status === "Success") {
        handleSnackBar(true, "Campaign canceled", "success");
        setOpenDeleteModal(false);
        navigate("/notifications");
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"));
  };

  const getTags = async () => {
    try {
      let response = await getRequestWithToken("admin/tags");
      response = response?.data;
      if (response.status === "Success") {
        let tags = response.data
          .filter((tag) => tag && tag !== "undefined")
          .map((tag) => ({
            label: tag.replace("_", " "),
            value: tag.replace("_", " "),
          }));

        setOptions(tags);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();

    if (location.state?._id) {
    } else {
      setShowLoader(false);
    }

    if (location.state?._id) {
      // const { candidate = {}, deleted = {} } = dataById;
      // const {
      //   schedule = "",
      //   title = "",
      //   body = "",
      //   scheduledTime = "",
      // } = candidate;
      // setFirstFormValues({
      //   schedule,
      //   title,
      //   body,
      //   scheduledTime,
      // });
      setEditForm(true);
    } else {
      setFirstFormValues({
        notificationName: "",
        tag: null,
        notificationType: "",
        scheduledTime: "",
        modeOfSend: "",
      });
      setEditForm(false);
    }
  }, [location.state, setFirstFormValues, dataById._id, isIncludesValue]);

  return isIncludesValue ? (
    <>
      <Box
        className="create-notification-campaign-section"
        ref={scrollTargetRef}
      >
        <Container>
          <Box className="create-notification-campaign">
            <Box
              className="headingPlusBackBtn"
              style={{ marginBottom: "20px" }}
            >
              <BackBtn />
              <h1 className="formHeadings" id="topEle">
                {editForm ? "Update Notification" : "Create Notification"}
              </h1>
            </Box>

            {formSubmitted && (
              <LinearProgress
                style={{
                  width: "100%",
                  position: "absolute",
                  left: "0",
                  top: "66px",
                  zIndex: "999",
                }}
              />
            )}

            {formNumber === 1 && (
              <Box className="create-notification-campaign-form-wrapper">
                <Formik
                  initialValues={firstFormValues}
                  validationSchema={NotificationCampaignFirstFormSchema}
                  onSubmit={handleSubmitFirstForm}
                  enableReinitialize
                >
                  {({ errors, values, setFieldValue, resetForm }) => (
                    <Form className="create-notification-campaign-form">
                      <Box className="backgroundStyle">
                        <Grid
                          container
                          spacing={2}
                          className="cstmForGridNotification spaceBetween"
                        >
                          <Grid item xs={12} sm={6} md={6}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label
                                    className="form-label"
                                    htmlFor="notificationName"
                                  >
                                    {" "}
                                    Notification Name{" "}
                                    <span className="must-fill-fields">*</span>
                                  </label>
                                  <Field
                                    id="notificationName"
                                    type="text"
                                    placeholder="Enter Notification Name"
                                    name="notificationName"
                                    className="form-input"
                                    size="small"
                                  />
                                  {errors.notificationName && (
                                    <Box className="form-error">
                                      {errors.notificationName}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label
                                    className="form-label"
                                    htmlFor="notificationType"
                                  >
                                    Notification Sending Mode
                                    <span className="must-fill-fields">*</span>
                                  </label>
                                  <FormControl
                                    name="notificationType"
                                    fullWidth
                                    size="small"
                                  >
                                    {values.modeOfSend === "" && (
                                      <InputLabel shrink={false}>
                                        Select Notification Type
                                      </InputLabel>
                                    )}
                                    <Select
                                      className="cstmSelectBox"
                                      name="modeOfSend"
                                      id="modeOfSend"
                                      value={values.modeOfSend}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "modeOfSend",
                                          event.target.value
                                        );
                                      }}
                                    >
                                      {[
                                        {
                                          id: 1,
                                          name: "Notification",
                                          value: "mobile notification",
                                        },
                                        // {
                                        //   id: 2,
                                        //   name: "Banner",
                                        //   value: "banner",
                                        // },
                                      ].map((item, index) => {
                                        return (
                                          <MenuItem
                                            value={item?.value}
                                            key={index}
                                          >
                                            {item?.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                  {errors.modeOfSend && (
                                    <Box className="form-error">
                                      {errors.modeOfSend}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label className="form-label" htmlFor="tag">
                                    Notification Tag
                                    <span className="must-fill-fields">*</span>
                                  </label>
                                  <CreatableSelect
                                    isClearable
                                    isDisabled={isLoading}
                                    isLoading={isLoading}
                                    onChange={(newValue) => {
                                      setValue(newValue);
                                      setFieldValue("tag", newValue);
                                    }}
                                    onCreateOption={(inputValue) =>
                                      handleCreate(inputValue, setFieldValue)
                                    }
                                    options={options || []}
                                    value={value}
                                    placeholder="Select or create a  tag"
                                  />
                                  {errors.tag && (
                                    <Box className="form-error">
                                      {errors.tag}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label
                                    className="form-label"
                                    htmlFor="notificationType"
                                  >
                                    {" "}
                                    Notification Type{" "}
                                    <span className="must-fill-fields">*</span>
                                  </label>
                                  <Select
                                    className="cstmSelectBox"
                                    name="notificationType"
                                    id="notificationType"
                                    value={values.notificationType}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "notificationType",
                                        event.target.value
                                      );
                                    }}
                                    displayEmpty
                                    renderValue={(selected) =>
                                      selected
                                        ? selected
                                        : "Select notificationType"
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      Select Notification Type
                                    </MenuItem>
                                    <MenuItem value="marketing">
                                      Marketing
                                    </MenuItem>
                                    <MenuItem value="utility">Utility</MenuItem>
                                  </Select>

                                  {errors.notificationType && (
                                    <Box className="form-error">
                                      {errors.notificationType}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={5} md={5}>
                            <Box
                              style={{
                                display: "flex",
                                // gap: "5px",
                                marginTop: "-7px",
                                alignItems: "center",
                              }}
                            >
                              <Typography className="sendLater">
                                Send Later
                              </Typography>

                              <Switch
                                className="saveLaterSwitch"
                                checked={values.sendLater}
                                name="sendLater"
                                // onChange={() =>
                                //   setFieldValue(
                                //     "sendLater",
                                //     !values.sendLater
                                //   )
                                // }
                                onChange={async () => {
                                  const newSendLaterValue = !values.sendLater;
                                  await setFieldValue(
                                    "sendLater",
                                    newSendLaterValue
                                  );
                                  if (values.sendLater) {
                                    setFieldValue("scheduledTime", null);
                                  }
                                }}
                              />
                            </Box>
                            {values.sendLater && (
                              <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control flexRow">
                                    <label
                                      className="form-label"
                                      htmlFor="date"
                                    >
                                      Date and Time
                                    </label>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DateTimePicker
                                        label=""
                                        value={values.scheduledTime}
                                        className="cstmDateTimePicker"
                                        onChange={(newValue) => {
                                          const utcDate = new Date(
                                            newValue
                                          ).toISOString();

                                          setFieldValue(
                                            "scheduledTime",
                                            utcDate
                                          );
                                        }}
                                      />
                                    </LocalizationProvider>
                                  </Box>
                                  {errors.scheduledTime && (
                                    <Box className="form-error">
                                      {errors.scheduledTime}
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Box>

                      <Box className="form-button-section">
                        <Button
                          className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                            setValue([]);
                          }}
                          color="error"
                        >
                          Cancel
                        </Button>

                        <Button
                          className="form-button"
                          variant="contained"
                          type="submit"
                          color="success"
                          disabled={formSubmitted}
                        >
                          Next
                        </Button>
                      </Box>
                      <FocusError handleSnackBar={handleSnackBar} />
                    </Form>
                  )}
                </Formik>
              </Box>
            )}

            {formNumber === 2 && (
              <Box className="create-notification-campaign-form-wrapper">
                <Box className="backgroundStyle">
                  <Formik
                    initialValues={secondFormValues}
                    validationSchema={NotificationCampaignSecondFormSchema}
                    onSubmit={
                      firstFormValues?.modeOfSend === "banner"
                        ? handleSubmitSecondBannerForm
                        : handleSubmitSecondForm
                    }
                    // enableReinitialize
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="create-notification-campaign-form">
                        <Grid
                          container
                          spacing={2}
                          className="cstmForGridNotification "
                          style={{ position: "relative" }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Box className="form-control">
                              <label className="form-label" htmlFor="condition">
                                Condition
                                <span className="must-fill-fields">*</span>
                              </label>
                              <FormControl
                                name="condition"
                                fullWidth
                                size="small"
                              >
                                {values.condition === "" && (
                                  <InputLabel shrink={false}>
                                    Select Condition
                                  </InputLabel>
                                )}
                                <Select
                                  className="cstmSelectBox"
                                  name="condition"
                                  id="condition"
                                  value={values.condition}
                                  onChange={async (event) => {
                                    await setFieldValue(
                                      "condition",
                                      event.target.value
                                    );
                                    await setFieldValue("radius", "");
                                    await setFieldValue("eligibleCount", 0);
                                    setSecondFormValues((prevStat) => {
                                      return {
                                        ...prevStat,
                                        eligibleCount: 0,
                                      };
                                    });
                                  }}
                                >
                                  {conditionData?.map((item) => {
                                    return (
                                      <MenuItem
                                        value={item?.value}
                                        key={item?.id}
                                      >
                                        {item?.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              {errors.condition && (
                                <Box className="form-error">
                                  {errors.condition}
                                </Box>
                              )}
                            </Box>
                          </Grid>

                          {values.condition === "Pincode" && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box className="form-control">
                                <label className="form-label" htmlFor="radius">
                                  Enter Radius (Km)
                                  <span className="must-fill-fields">*</span>
                                </label>
                                <Field
                                  id="radius"
                                  type="number"
                                  placeholder="Enter Radius"
                                  name="radius"
                                  className="form-input"
                                  size="small"
                                  min="0"
                                />
                                {errors.radius && (
                                  <Box className="form-error">
                                    {errors.radius}
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          )}

                          {values.condition === "Job Id" && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box className="form-control">
                                <label className="form-label" htmlFor="jobId">
                                  Job ID
                                  <span className="must-fill-fields">*</span>
                                </label>
                                <Field
                                  id="jobId"
                                  type="text"
                                  placeholder="Enter Job Id"
                                  name="jobId"
                                  className="form-input"
                                  size="small"
                                />
                                {errors.jobId && (
                                  <Box className="form-error">
                                    {errors.jobId}
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          )}

                          {values.condition === "Pincode" && (
                            <Grid item xs={12} sm={6} md={4}>
                              <Box className="form-control">
                                <label className="form-label" htmlFor="pincode">
                                  Pincode
                                  <span className="must-fill-fields">*</span>
                                </label>
                                <Field name="pincode">
                                  {({ field, form }) => (
                                    <input
                                      {...field}
                                      type="text"
                                      inputMode="numeric"
                                      pattern="[0-9]*"
                                      maxLength="6"
                                      placeholder="Enter Pincode"
                                      className="form-input"
                                      onChange={(e) => {
                                        const value = e.target.value
                                          .replace(/[^0-9]/g, "")
                                          .slice(0, 6);
                                        form.setFieldValue(field.name, value);
                                      }}
                                    />
                                  )}
                                </Field>
                                {errors.pincode && (
                                  <Box className="form-error">
                                    {errors.pincode}
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={12} md={12}>
                            <Grid container>
                              <Grid item xs={12} sm={4} md={8}></Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <Box
                                  style={{
                                    background: "white",
                                    padding: "10px",
                                    border: "1px solid lightgray",
                                    borderRadius: "10px",
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography>Number of target user</Typography>
                                  {loading ? (
                                    <CircularProgress />
                                  ) : (
                                    <Typography
                                      style={{
                                        fontSize: "40px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {values.eligibleCount}
                                    </Typography>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Box className="form-button-section">
                          {/* <Button
                            className="form-button"
                            variant="contained"
                            // onClick={() => {
                            //   setOpenDeleteModal(true);
                            // }}
                            color="error"
                          >
                            Cancel
                          </Button> */}

                          <Button
                            className="form-button"
                            variant="contained"
                            color="success"
                            type="submit"
                            disabled={formSubmitted || loading}
                          >
                            Check count
                          </Button>
                        </Box>
                        <FocusError handleSnackBar={handleSnackBar} />
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            )}

            {formNumber === 2 && (
              <Box
                className="create-notification-campaign-form-wrapper"
                style={{ marginTop: "10px" }}
              >
                <h2
                  style={{ marginBottom: "10px", textTransform: "capitalize" }}
                >{`${firstFormValues?.modeOfSend} Details`}</h2>
                {firstFormValues?.modeOfSend === "banner" ? (
                  <>
                    <Formik
                      initialValues={initialValues2}
                      validationSchema={thirdFormBannerValidationSchema}
                      onSubmit={handleSubmit2}
                      enableReinitialize
                    >
                      {({ errors, values, setFieldValue, resetForm }) => (
                        <Form className="create-notification-campaign-form">
                          <Box>
                            <Box className="backgroundStyle">
                              <Grid
                                container
                                spacing={2}
                                className="cstmFormGridEmployer"
                                style={{ position: "relative" }}
                              >
                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="tagText"
                                    >
                                      Chig tag text
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="tagText"
                                      type="text"
                                      placeholder="Max 11 Characters"
                                      name="tagText"
                                      className="form-input"
                                      size="small"
                                      maxLength="11"
                                    />
                                    {errors.tagText && (
                                      <Box className="form-error">
                                        {errors.tagText}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="titleEn"
                                    >
                                      Notification Title (English)
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="titleEn"
                                      type="text"
                                      placeholder="Max 30 Characters"
                                      name="titleEn"
                                      className="form-input"
                                      size="small"
                                      maxLength="30"
                                    />
                                    {errors.titleEn && (
                                      <Box className="form-error">
                                        {errors.titleEn}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="titleHn"
                                    >
                                      Notification Title (Hinglish)
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="titleHn"
                                      type="text"
                                      placeholder="Max 30 Characters"
                                      name="titleHn"
                                      className="form-input"
                                      size="small"
                                      maxLength="30"
                                    />
                                    {errors.titleHn && (
                                      <Box className="form-error">
                                        {errors.titleHn}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="titleHi"
                                    >
                                      Notification Title (Hindi)
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="titleHi"
                                      type="text"
                                      placeholder="Max 30 Characters"
                                      name="titleHi"
                                      className="form-input"
                                      size="small"
                                      maxLength="30"
                                    />
                                    {errors.titleHi && (
                                      <Box className="form-error">
                                        {errors.titleHi}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="bodyEn"
                                    >
                                      Notification Body (English)
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="bodyEn"
                                      as="textarea"
                                      placeholder="Enter Notification body"
                                      name="bodyEn"
                                      className="form-input"
                                      rows={4}
                                    />
                                    {errors.bodyEn && (
                                      <Box className="form-error">
                                        {errors.bodyEn}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="bodyHn"
                                    >
                                      Notification Body (Hinglish)
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="bodyHn"
                                      as="textarea"
                                      placeholder="Enter Notification body"
                                      name="bodyHn"
                                      className="form-input"
                                      rows={4}
                                    />
                                    {errors.bodyHn && (
                                      <Box className="form-error">
                                        {errors.bodyHn}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="bodyHi"
                                    >
                                      Notification Body (Hindi)
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="bodyHi"
                                      as="textarea"
                                      placeholder="Enter Notification body"
                                      name="bodyHi"
                                      className="form-input"
                                      rows={4}
                                    />
                                    {errors.bodyHi && (
                                      <Box className="form-error">
                                        {errors.bodyHi}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="ctaButtonText"
                                    >
                                      Cta Button Text
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <Field
                                      id="ctaButtonText"
                                      type="text"
                                      placeholder="Max 15 Characters"
                                      name="ctaButtonText"
                                      className="form-input"
                                      size="small"
                                      maxLength="15"
                                    />
                                    {errors.ctaButtonText && (
                                      <Box className="form-error">
                                        {errors.ctaButtonText}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="redirectTo"
                                    >
                                      Redirect to:
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <FormControl
                                      name="redirectTo"
                                      fullWidth
                                      size="small"
                                    >
                                      {values.redirectTo === "" && (
                                        <InputLabel shrink={false}>
                                          Select Redirect To
                                        </InputLabel>
                                      )}
                                      <Select
                                        className="cstmSelectBox"
                                        name="redirectTo"
                                        id="redirectTo"
                                        value={values.redirectTo}
                                        onChange={(event) => {
                                          setFieldValue(
                                            "redirectTo",
                                            event.target.value
                                          );
                                        }}
                                      >
                                        {redirectToData?.map((item, index) => {
                                          return (
                                            <MenuItem
                                              value={item?.value}
                                              key={index}
                                            >
                                              {item?.name}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    {errors.redirectTo && (
                                      <Box className="form-error">
                                        {errors.redirectTo}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                  <Box className="form-control">
                                    <label
                                      className="form-label"
                                      htmlFor="expiryDateAndTime"
                                    >
                                      Expiry Date & Time
                                      <span className="must-fill-fields">
                                        *
                                      </span>
                                    </label>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DateTimePicker
                                        className="datePickerGetInput"
                                        label=""
                                        value={values.expiryDateAndTime}
                                        onChange={(newValue) => {
                                          setFieldValue(
                                            "expiryDateAndTime",
                                            newValue
                                          );
                                        }}
                                      />
                                    </LocalizationProvider>
                                    {errors.expiryDateAndTime && (
                                      <Box className="form-error">
                                        {errors.expiryDateAndTime}
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>

                            <Box
                              className="form-button-section"
                              style={{ marginTop: "10px" }}
                            >
                              <Button
                                className="form-button"
                                variant="contained"
                                onClick={() => {
                                  resetForm();
                                }}
                                color="error"
                              >
                                Cancel
                              </Button>

                              <Button
                                className="form-button"
                                variant="contained"
                                type="submit"
                                color="success"
                                disabled={formSubmitted}
                              >
                                {editForm ? "Update" : "Submit & Schedule"}
                              </Button>
                            </Box>
                            <FocusError handleSnackBar={handleSnackBar} />
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : (
                  <Formik
                    initialValues={thirdFormNotificationValues}
                    validationSchema={thirdFormNotificationValidationSchema}
                    onSubmit={handleSubmitThirdNoficationForm}
                    enableReinitialize
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="create-notification-campaign-form">
                        <Box>
                          <Box className="backgroundStyle">
                            <Grid
                              container
                              spacing={2}
                              className="cstmFormGridEmployer"
                              style={{ position: "relative" }}
                            >
                              <Grid item xs={12} sm={6} md={4}>
                                <Box className="form-control">
                                  <label className="form-label" htmlFor="title">
                                    Notification Title
                                    <span className="must-fill-fields">*</span>
                                  </label>
                                  <Field
                                    id="title"
                                    type="title"
                                    placeholder="Enter Notification Title"
                                    name="title"
                                    className="form-input"
                                    size="small"
                                    // maxLength="11"
                                  />
                                  {errors.title && (
                                    <Box className="form-error">
                                      {errors.title}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12}>
                                <Box className="form-control">
                                  <label
                                    className="form-label"
                                    htmlFor="description"
                                  >
                                    Notification Body
                                    <span className="must-fill-fields">*</span>
                                  </label>
                                  <Field
                                    id="description"
                                    as="textarea"
                                    placeholder="Enter Notification body"
                                    name="description"
                                    className="form-input"
                                    rows={4}
                                  />
                                  {errors.description && (
                                    <Box className="form-error">
                                      {errors.description}
                                    </Box>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box
                            className="form-button-section"
                            style={{ marginTop: "10px" }}
                          >
                            <Button
                              className="form-button"
                              variant="contained"
                              onClick={() => {
                                setOpenDeleteModal(true);
                              }}
                              color="error"
                              disabled={
                                !Boolean(secondFormValues?.eligibleCount)
                              }
                            >
                              Cancel
                            </Button>

                            <Button
                              className="form-button"
                              variant="contained"
                              type="submit"
                              color="success"
                              disabled={
                                formSubmitted ||
                                !Boolean(secondFormValues?.eligibleCount)
                              }
                            >
                              {editForm ? "Update" : "Submit & Schedule"}
                            </Button>
                          </Box>
                          <FocusError handleSnackBar={handleSnackBar} />
                        </Box>
                      </Form>
                    )}
                  </Formik>
                )}
              </Box>
            )}

            <SureYouWantToDeletModal
              modalTitle=""
              dialogBody="Are you sure you want to cancel this notification ?"
              buttonText="Yes"
              openDeleteModal={openDeleteModal}
              setOpenDeleteModal={setOpenDeleteModal}
              onYesClick={() => {
                deleteCampaign(campaignId);
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  ) : (
    <Unauthorized />
  );
};
export default CreateNotification;
