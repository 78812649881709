import "./employer.scss";
import * as React from 'react';
import { Box, Button, Chip, FormGroup, FormControlLabel, Switch, Tooltip, FormControl, Select, MenuItem, Typography, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useMemo } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequestWithToken, patchRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { PanoramaSharp } from "@material-ui/icons";
import SearchBar from "../../components/searchbar/SearchBar";
import MaterialTable from "../../components/materialTable/MaterialTable";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const Employer = () => {
  const [employerData, setEmployerData] = useState([]);
  const [employerDeleted, setEmployerDeleted] = useState(false);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openStateChangeModal, setOpenStateChangeModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);
  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [tabvalue, setTabValue] = React.useState(0);

  const [profileStatus, setProfileStatus] = useState(null);
  const [employerID, setEmployerID] = useState(null);
  const [searchBarValue, setSearchBarValue] = useState("");

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: []
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const createNewEmployer = () => {
    navigate('/employer/create');
  }

  const [showDeletedRows, setShowDeletedRows] = useState(true);

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const filteredRows = showDeletedRows
    ? employerData
    : employerData.filter(employer => employer.hasOwnProperty('deleted') && employer.deleted.is_deleted === false);

  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No.',
      size: 120,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        const rowIndex = row.index;
        return <span>{rowIndex + 1 + (pageIndex * pageSize)}</span>;
      },
    },
    {
      accessorFn: (row) => {
        const { first_name, middle_name, last_name } = row.employer || {};
        return [first_name, middle_name, last_name].filter(Boolean).join(' ').trim() || '';
      },
      header: 'Full Name',
      size: 250,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row.employer?.company_name ?? '--',
      header: 'Organisation Name',
      size: 300,
      id: "companyname"
    },
    {
      accessorFn: (row) => row.employer?.mobile ?? '--',
      header: 'Phone',
      size: 300,
      id: "phone"
    },
    {
      accessorFn: (row) => row.employer?.email ?? '--',
      header: 'Email',
      size: 300,
      id: "email"
    },

    {
      accessorFn: (row) => row.employerId ?? '--',
      header: 'EUID',
      size: 200,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row.employer?.total_applicant,
      id: 'total_applicant',
      header: 'Applicant Count',
      size: 200,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <span>
          {new Date(cell.getValue()).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}
        </span>
      ),
    },
    {
      accessorKey: 'last_login',
      header: 'Last Login',
      size: 200,
      enableColumnFilter: false,
      Cell: ({ cell }) => (
        <span>
          {cell.getValue()
            ? new Date(cell.getValue()).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })
            : "--"}
        </span>
      ),
    },
    {
      accessorKey: 'jobs',
      header: 'Jobs',
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <div>
          <table>
            <thead>
              <tr>
                <th style={{ fontWeight: "400", paddingRight: "10px" }}>Active</th>
                <th style={{ fontWeight: "400", paddingRight: "10px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{row.original.total_active_jobs || "0"}</td>
                <td>{row.original.total_jobs || "0"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
    },
    {
      accessorFn: (row) => row.employer?.city ?? '--',
      header: 'City',
      size: 150,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row.employer?.state ?? '--',
      header: 'State',
      size: 150,
      enableColumnFilter: false,
    },
    {
      accessorFn: (row) => row.employer?.pincode ?? '--',
      header: 'pincode',
      size: 150,
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 180,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        row.original.deleted?.is_deleted ? (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canUpdate && <EditIcon color="disabled" />}
            {canDelete && <DeleteIcon color="disabled" />}
          </Box>
        ) : (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canRead && (
              <Tooltip title="View">
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() => navigate(`/employer/detail/${row.original._id}`, { state: "Employer" })}
                />
              </Tooltip>
            )}
            {canUpdate && (
              <EditIcon
                sx={{ cursor: "pointer" }}
                color="primary"
                onClick={() => navigate('/employer/create', { state: row.original })}
              />
            )}
            {canDelete && (
              <DeleteIcon
                sx={{ cursor: "pointer" }}
                color="error"
                onClick={() => {
                  setOpenDeleteModal(true);
                  setCandidateId(row.original._id);
                }}
              />
            )}
          </Box>
        )
      ),
    },
    {
      accessorKey: 'is_deleted',
      header: 'Deleted',
      enableColumnFilter: false,
      size: 150,
      Cell: ({ row }) => {
        if (row.original.deleted?.is_deleted) {
          return (
            <span>
              <Chip variant="outlined" label="Deleted" color="error" />
            </span>
          );
        }
        return null;
      },
    }
  ];


  const deleteEmployer = async () => {
    try {
      console.log(candidateId)
      let response = await deleteRequest(`admin?usertype=employer&userid=${candidateId}`);

      handleSnackBar(true, response?.data?.msg, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllEmployers();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  }

  const getAllEmployers = async () => {
    setLoadingDataInGrid(true);
    try {
      if (tabvalue === 0) {
        let response = await getRequestWithToken(`admin?usertype=employer&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&utype=false&search=${searchBarValue}`);
        setRowCount(response?.data?.totalCount);
        setEmployerData(response.data.data);
        setLoadingDataInGrid(false);
        setShowLoader(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      if (!employerData.length) {
        setLoadingDataInGrid(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const filterParams = {
          // global: globalFilter,
          ...Object.fromEntries(
            columnFilters.map(filter => [filter.id, filter.value])
          )
        };

        const filterQueryString = new URLSearchParams(filterParams).toString();

        const response = await getRequestWithToken(
          `admin?usertype=employer&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}&utype=false&${filterQueryString}`
        );

        setEmployerData(response?.data?.data);
        setRowCount(response?.data?.totalCount);
        setIsError(false);
        setLoadingDataInGrid(false);
        setShowLoader(false);
      } catch (error) {
        setIsError(true);
        handleSnackBar(
          true,
          error?.response?.data?.message || error?.message,
          "error"
        );
        setLoadingDataInGrid(false);
      }
      setLoadingDataInGrid(false);
      setIsRefetching(false);
      setShowLoader(false);

    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const handleStateChange = async (event, jobsahayakID) => {
    let actionValue = event.target.value;
    setProfileStatus(actionValue);
    setEmployerID(jobsahayakID);
    if (actionValue === "active") {
      setOpenTask(true);
    } else {
      executeStateChange(actionValue, jobsahayakID);
    }
  };

  const executeStateChange = async (profileStatus, employerID) => {
    try {
      let response = await patchRequestWithToken(`employer/profile-active?id=${employerID}&status=${profileStatus}`);
      if (response.data.status === "Success") {
        handleSnackBar(true, response.data.msg, "success");
        setOpenTask(false);
        // setShowLoader(true);
        getAllEmployers();
      }
      // setLoadingDataInGrid(false);
    }
    catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
    }
  }
  const checkPermissionFunc = () => {
    const resourceToUpdate = "Employer";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Employer"))
  }

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue, profileStatus, tabvalue, searchBarValue])

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const currentTabSelect = (event, newValue) => {
    setTabValue(newValue);
  };

  const [openTask, setOpenTask] = useState(false);

  const handleCloseTask = () => setOpenTask(false)

  const table = useMaterialReactTable({
    columns,
    data: filteredRows,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    muiSkeletonProps: {
      animation: 'wave',
    },
    muiLinearProgressProps: {
      style: {
        backgroundColor: '#ff671f', // Custom orange color
      },
    },
    muiCircularProgressProps: {
      color: 'secondary',
    },
    muiToolbarAlertBannerProps: isError
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return ((isIncludesValue) ?
    <Box className="employers-section">
      < ModalComponent
        openModal={openTask}
        setOpenModal={setOpenTask}
        modalTitle="Create Employer"
        modalWidth="35%"
        handleOnCloseBtnClick={handleCloseTask}
      >
        <Box style={{ padding: "15px" }}>
          <p>Do you want to create Employer?</p>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" color="error" style={{ marginRight: "10px" }} onClick={handleCloseTask}>No</Button>
            <Button variant="contained" color="success" onClick={() => executeStateChange(profileStatus, employerID)}>Yes</Button>
          </Box>

        </Box>
      </ModalComponent >

      <Box className="employers-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        {canCreate ? (<Button variant="contained" color="success" onClick={createNewEmployer}>
          Create New Employer
        </Button>) : ""}

        {/* <FormGroup>
          <FormControlLabel control={<Switch checked={showDeletedRows} onChange={handleToggleChange} />} label="Include Deleted Users" labelPlacement="start" />
        </FormGroup> */}

      </Box>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabvalue} onChange={currentTabSelect} aria-label="basic tabs example">
            <Tab label="All Employers" style={{ fontWeight: "700" }}  {...a11yProps(0)} />

          </Tabs>
        </Box>

        {showLoader ? <ReusableLoader /> :
          <MaterialReactTable table={table} />
        }
      </Box>

      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this user"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deleteEmployer();
        }}
      />

    </Box> : (<Unauthorized />)
  )
}
export default Employer;
