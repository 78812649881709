
import * as React from 'react';
import "./announcement.scss";
import { Box, Button, TextField, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useContext, useEffect, useRef } from "react";
import { getRequestWithToken, postRequest, patchRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { Formik, Form, Field, } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { validationForCreateTask } from "../../utils/Validations";
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';

const CreateNewTask = () => {

  const [AllCandidateData, setAllCandidateData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);
  const [pageNumber, setPageNumber] = useState(0);
  const [shortlistPageNumber, setShortlistPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);

  const [canCreate, setCanCreate] = useState(false);
  // const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const navigate = useNavigate()

  const handleShortListCandidate = async (candidateInfo, action) => {
    try {
      let res = await patchRequestWithToken(`/admin/candidate/shortlisted-approved?_id=${candidateInfo?.candidateId}&action=${action}&jobId=${_id}`);
      if (res.data.status === 'Success') {
        handleSnackBar(true, res.data.msg, "success");
        setAllCandidateData((prev) => {
          return prev.map(prevCandidate => {
            if (prevCandidate && prevCandidate?.candidateId === candidateInfo?.candidateId) {
              let resp = {
                ...prevCandidate,
                shortlisted: action

              }
              return resp;
            } else {
              return prevCandidate;
            }
          });

        })
        if (activeTab === "shortlist_candidates") {
          let data = AllCandidateData.filter((candidate) =>
            candidateInfo.candidateId !== candidate.candidateId)
          setAllCandidateData(data);
        }

        setLoadingDataInGrid(false);
      }
      else {
        handleSnackBar(true, res.data.msg, "error");
        setLoadingDataInGrid(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);

    }
  }
  const candidatesColumn = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {

        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row.candidateId);
        return (<span> {rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => {
        return (<span>{`${params.row?.candidate?.first_name} ${params.row?.candidate?.middle_name} ${params.row?.candidate?.last_name}`}</span>)
      }

    },

    {
      field: 'mobile',
      headerName: 'Phone Number',
      width: 150,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.mobile}</span>)
      }
    },

    {
      field: 'age',
      headerName: 'Age',
      width: 100,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.age}</span>)
      }
    },


    {
      field: 'address',
      headerName: 'Address',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.city + ', ' + params.row?.candidate?.state}</span>)
      }

    },

    {
      field: 'source',
      headerName: 'Source',
      width: 150,
      renderCell: (params) => {
        return (<span>{params.row?.source}</span>)
      }

    },
    {
      field: 'kyc_status',
      headerName: 'KYC status',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.kyc_status ? "Verfied" : "Not verified"}</span>)
      }
    },

    {
      field: 'jobshayak',
      headerName: 'Job Shayak',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.job_sahayak_uuid ? "Assigned" : "Not Assigned"}</span>)
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 300,
      renderCell: (params) => {
        return (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canRead && <Tooltip title="View" onClick={() => navigate(`/candidate/detail/${params?.row?.candidateId}`)} >
              <VisibilityIcon sx={{ cursor: "pointer" }} color="primary"
                disabled />
            </Tooltip>}
            {canUpdate && <Button color="info" variant="contained"
              disabled={params?.row?.shortlisted}
              onClick={() => handleShortListCandidate(params.row, true)} >Mark Shortlist </Button>}
          </Box>
        );
      },

    }

  ];

  const sendForKYC = async (data) => {
    let assigned_jobshayak = data?.candidate?.job_sahayak_uuid;
    if (!assigned_jobshayak) {
      handleSnackBar(true, "Please Assign Jobshayak ", "error");
      return;
    }
    let candidateID = data?.id;
    try {
      // console.log("candidateUUID : " + dataById.id);
      let res = await patchRequestWithToken(`candidate/assign-jobsahayak-send-jobseva?candidateUUID=${candidateID}&jobSahayakUUID=${assigned_jobshayak}&kycRequired=${true}`);

      if (res.data.status === 'Success') {
        handleSnackBar(true, res.data.msg, "success");

      }
      else {
        handleSnackBar(true, res.data.msg, "success");
        setTimeout(() => {
          navigate(-1);
        }, 1000);

      }

    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  }

  const candidate = useRef(null);
  const deleteTaskConfirmationMessage = async (data) => {
    setConfirmationDialog(true);
    candidate.current = data;
  }

  const deleteTask = (status) => async () => {
    setConfirmationDialog(false);
    if (status) {
      let res = await patchRequestWithToken(`/admin/remove-candidate?id=${candidate?.current?.candidateId}`);
      if (res.data.status === 'Success') {
        handleSnackBar(true, res.data.msg, "success");
        candidate.current = null;
        getAllCandidates();
      }
    }
  }

  const shortlistCandidatesColmns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {

        let addNumber = shortlistPageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row.candidate._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'task_id',
      headerName: 'Task ID',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.taskId}</span>)
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => {
        return (<span>{`${params.row?.candidate?.first_name} ${params.row?.candidate?.middle_name} ${params.row?.candidate?.last_name}`}</span>)
      }

    },

    {
      field: 'mobile',
      headerName: 'Phone Number',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.mobile}</span>)
      }
    },

    {
      field: 'state',
      headerName: 'Age',
      width: 150,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.age}</span>)
      }
    },


    {
      field: 'address',
      headerName: 'Address',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.city + ', ' + params.row?.candidate?.state}</span>)
      }

    },
    {
      field: 'jobshayak',
      headerName: 'Job Shayak',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.job_sahayak_uuid ? "Assigned" : "Not Assigned"}</span>)
      }
    },
    {
      field: 'kycstatus',
      headerName: 'KYC status',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.kyc_status ? "Verfied" : "Not verified"}</span>)
      }
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 750,
      renderCell: (params) => {
        return (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canCreate && <>

              <Button color="info" variant="contained" disabled={!params.row?.candidate?.taskId} onClick={() =>
                deleteTaskConfirmationMessage(params.row)
              }>Delete Task</Button>

              <Button size="small" variant="contained"
                disabled={params.row?.candidate?.kyc_status}
                onClick={
                  (e) => {
                    e.preventDefault();
                    sendForKYC(params.row)
                  }}>
                Send for KYC
              </Button>

              <Button size="small" variant="contained"
                onClick={() => navigate("/candidate/assign-js", {
                  state: {
                    ...params.row, // Include existing row data
                    buttonId: "assign_jobshayak" // Add identification of the button
                  },
                })}
              >
                {params.row.candidate?.job_sahayak_uuid ? "Reassign Jobsahayak" : "Assign Jobsahayak"}
              </Button>
            </>}
            {canUpdate && <Button color="info" variant="contained" disabled={!!params.row?.candidate?.taskId} onClick={() =>
              handleShortListCandidate(params.row, false)
            }>Remove Candidate </Button>}
          </Box>
        );
      },

    }
  ]

  const deployedColumn = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row.candidate._id);
        return (<span> {rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      renderCell: (params) => {
        return (<span>{`${params.row?.candidate?.candidate?.first_name} ${params.row?.candidate?.candidate?.middle_name} ${params.row?.candidate?.candidate?.last_name}`}</span>)
      }

    },

    {
      field: 'mobile',
      headerName: 'Phone Number',
      width: 150,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.candidate?.mobile}</span>)
      }
    },

    {
      field: 'address',
      headerName: 'Address',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.candidate?.city + ', ' + params.row?.candidate?.candidate?.state}</span>)
      }

    },

    {
      field: 'source',
      headerName: 'Source',
      width: 150,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.source}</span>)
      }

    }

  ];
  const getAllCandidates = async () => {
    try {
      setLoadingDataInGrid(true);
      activeTab !== "shortlist_candidates" && setShowLoader(true);
      let pageNum = activeTab === "candidate_pool" ? pageNumber : shortlistPageNumber;
      let api_url = `jobs/jobseva-job-candidate?page=${parseInt(pageNum + 1)}&limit=${pageSize}&job_id=${job_id}`;

      if (activeTab === "shortlist_candidates") {
        api_url = api_url + `&shortlisted=true`;
      }
      else if (activeTab === "deployed_candidates") {
        api_url = `admin/deployed-candidate?page=${parseInt(pageNum + 1)}&limit=${pageSize}&jobId=${job_id}`;
      }

      let response = await getRequestWithToken(api_url);
      setAllCandidateData(response?.data?.data);
      setRowCount(response?.data?.totalCount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);

    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
    setCanCreate(permissions.canCreate)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }
  /*Tabs functionality*/
  const [activeTab, setActiveTab] = useState("candidate_pool");
  /*Changing the active Tab*/
  const changeActiveTab = (tabName) => {
    setActiveTab(tabName)
    activeTab === "candidate_pool" ? setPageNumber(0) : setShortlistPageNumber(0)
  }
  /*Custom Tab*/
  function CustomTabPanel(props) {
    const { value, label } = props;

    return (
      <div role="tabpanel">
        <Button variant={activeTab === value ? "contained" : "outlined"}
          onClick={() => {
            changeActiveTab(value)
          }}>{label}</Button>

      </div>
    );
  }

  /*Task functionality*/
  const [openTask, setOpenTask] = useState(false);

  const handleCloseTask = () => setOpenTask(false)
  useEffect(() => {
    getAllCandidates();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue, pageSize, pageNumber, shortlistPageNumber, activeTab])

  /*Create Task Initial State*/
  const intialStateForCreateTask = {
    source_location: " ",
    destination_location: "",
    date_of_arrival: "",
    title: ""
  }

  const [formSubmitted, setFormSubmitted] = useState(false);
  const location = useLocation();
  const { job_id, job_role, id, _id } = location?.state;

  const handleFormSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true)
    let candidateList = selectedRows.filter((can) => can?.candidate?.job_sahayak_uuid && !(can?.candidate?.taskId))
      .map((can) => can.id);
    let dateOfArrival = new Date(values.date_of_arrival);
    let dateOfArrivalFormat = dateOfArrival.toLocaleDateString('en-US', { timeZone: 'Asia/Kolkata' });

    let formData = new FormData();
    formData = {
      location: {
        "type": "Point",
        "coordinates": [
          parseFloat(values.destination_lat),
          parseFloat(values.destination_lng)
        ]
      },
      title: values.title,
      source_location: values.source_location,
      destination_location: values.destination_location,
      dateOfArrival: dateOfArrivalFormat,
      listOfCandidates: candidateList,
      job_uuid: id
    }
    try {
      let res = await postRequest('/admin/jwptask', formData);
      setSelectedRows([]);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        setOpenTask(false);
        handleSnackBar(true, res.data.msg, "success");
        getAllCandidates();

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }
  }

  /*Row Selection Model*/
  // const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = (newSelectionModel) => {
    const selectedRows = newSelectionModel.map((rowId) =>
      AllCandidateData.find((row) => row.candidate._id === rowId)
    );
    setSelectedRows(selectedRows);
  };

  const handleOpenTask = () => {
    if (selectedRows.length > 0)
      setOpenTask(true)
    else
      handleSnackBar(true, "Please select atleast one candidate", "error")
  }

  return (
    <Box className="candidate-pool-section">
      < ModalComponent
        openModal={confirmationDialog}
        setOpenModal={setConfirmationDialog}
        modalTitle="Delete Candidate Task"
        modalWidth="35%"
        handleOnCloseBtnClick={deleteTask(false)}
      >
        <Box style={{ padding: "15px" }}>
          <p>Do you want to delete this candidate task?</p>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <Button variant="contained" color="error" style={{ marginRight: "10px" }} onClick={deleteTask(false)}>No</Button>
            <Button variant="contained" color="success" onClick={deleteTask(true)}>Yes</Button>
          </Box>

        </Box>
      </ModalComponent >

      < ModalComponent
        openModal={openTask}
        setOpenModal={setOpenTask}
        modalTitle="Create New Task"
        modalWidth="35%"
        handleOnCloseBtnClick={handleCloseTask}
      >
        <Formik
          enableReinitialize
          initialValues={intialStateForCreateTask}
          validationSchema={validationForCreateTask}
          onSubmit={handleFormSubmit}
        >
          {({ errors, values, setFieldValue, resetForm }) => (

            <Form className="job-seva-task-form">
              <Box className="job-seva-task-form-control">
                <label className="form-label" htmlFor="title"> Title <span className='must-fill-fields'>*</span></label>
                <Field id="title"
                  type="text" placeholder="Enter Title"
                  name="title"
                  className="form-input"

                />
                {errors.title && <Box className="form-error">{errors.title}</Box>}

              </Box>

              <Box className="job-seva-task-form-control">
                <label className="form-label" htmlFor="destination_location"> Destination Location <span className='must-fill-fields'>*</span></label>
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  value={values.destination_location}
                  onChange={(event) =>
                    setFieldValue("destination_location", event.target.value)
                  }
                  onPlaceSelected={(place) => {
                    let address = place?.formatted_address || place?.name;
                    let lat = place.geometry.location.lat().toString();
                    let lng = place.geometry.location.lng().toString();
                    setFieldValue("destination_location", address);
                    setFieldValue("destination_lat", lat);
                    setFieldValue("destination_lng", lng);
                  }}
                  options={{
                    types: [],
                    componentRestrictions: { country: "in" },
                  }}
                  placeholder="Enter Destination Location"
                  name="destination_location"
                  id="destination_location" className='form-input'

                />
                {errors.destination_location && <Box className="form-error">{errors.destination_location}</Box>}

              </Box>

              <Box className="job-seva-task-form-control">
                <label className="form-label" htmlFor="date_of_arrival">Date of Arrival <span className='must-fill-fields'>*</span></label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={values.date_of_arrival}
                    onChange={(newValue) => {
                      setFieldValue("date_of_arrival", newValue);
                    }}
                    slotProps={{ textField: { variant: "outlined", error: false, size: "small" } }}
                  // renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {errors.date_of_arrival && <Box className="form-error">{errors.date_of_arrival}</Box>}
              </Box>


              <Box className="form-button-section">

                <Button className="form-button"
                  variant="contained"
                  type="submit"
                  color="error"
                  onClick={() => {
                    handleCloseTask()
                    setFormSubmitted(false);
                    setSelectedRows([]);
                  }}

                >Cancel</Button>

                <Button className="form-button"
                  variant="contained"
                  type="submit"
                  color="success"
                  disabled={formSubmitted}
                >Submit</Button>

              </Box>
            </Form>
          )}
        </Formik>
      </ModalComponent >
      <Box className="candidate-pool-top-section ">
        <h3>JobSeva {"JobID"}</h3>
        <Box className="job-info-section">
          <Box className="form-input">
            <label htmlFor="job_id">Job Id</label>
            <TextField
              id="job_id"
              size="small"
              value={job_id}
              disabled
            />
          </Box>

          <Box className="form-input">
            <label htmlFor="job_title">Job Title</label>
            <TextField
              id="job_title"
              size="small"
              value={job_role}
              disabled
            />
          </Box>
        </Box>
      </Box>
      <Box className="job-tab-section">
        <CustomTabPanel value="candidate_pool" label="Candidate Pool" />
        <CustomTabPanel value="shortlist_candidates" label="Shortlist Candidates" />
        <CustomTabPanel value="deployed_candidates" label="Deployed Candidates" />
      </Box>
      {
        (isIncludesValue) ?
          <Box className="candidates-section-data">
            {showLoader ? <ReusableLoader /> :
              <>
                <DataGrid
                  rows={AllCandidateData}
                  columns={(activeTab === "candidate_pool" && candidatesColumn) ||
                    (activeTab === "shortlist_candidates" && shortlistCandidatesColmns) ||
                    (activeTab === "deployed_candidates" && deployedColumn)}
                  getRowId={(AllCandidateData) => AllCandidateData.candidateId}
                  paginationMode="server"
                  pagination
                  rowsPerPageOptions={activeTab === "candidate_pool" ? [10, 20, 30, 50, 100] : [10, 100]}
                  page={activeTab === "candidate_pool" ? pageNumber : shortlistPageNumber}
                  pageSize={pageSize}
                  onPageChange={(newPage) => { setLoadingDataInGrid(true); activeTab === "candidate_pool" ? setPageNumber(newPage) : setShortlistPageNumber(newPage) }}
                  onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
                  loading={loadingDataInGrid}
                  rowCount={rowCount}
                  isRowSelectable={(params) => !params.row?.candidate?.taskId && activeTab === "shortlist_candidates"}
                  checkboxSelection={activeTab === "shortlist_candidates"}
                  onSelectionModelChange={handleSelectionChange}
                  selectionModel={selectedRows?.map((row) => row.candidate._id)}
                  keepNonExistentRowsSelected
                />
                {activeTab === "shortlist_candidates" &&
                  <div style={{ marginTop: "10px", display: "flex", gap: "15px", justifyContent: "end" }}>
                    <Button variant="contained" color="error" onClick={() => setOpenTask(false)}
                      disabled={AllCandidateData.filter((can) => (can?.candidate?.taskId)).length > 0}
                    >
                      Cancel
                    </Button>
                    {canCreate && <Button variant="contained" color="success"
                      onClick={handleOpenTask}
                      disabled={AllCandidateData.filter((can) => !can?.candidate?.taskId).length <= 0}
                    >
                      Create JWP Task
                    </Button>}
                  </div>}
              </>
            }
          </Box> : (<Unauthorized />)
      }

    </Box >
  )
}

export default CreateNewTask;