import "./announcement.scss";
import { Box, Button, Snackbar, Alert, Chip, FormGroup, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequestWithToken, patchRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import IconButton from '@mui/material/IconButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



const JwpTask = () => {
  const [jwpTaskData, setJwpTaskData] = useState([]);
  const [jwpTaskDeleted, setJwpTaskDeleted] = useState(false);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [isCompleted, setIsCompleted] = useState(false);

  const createJwpTask = () => {
    navigate('/jwp-task/create');
  }

  const [showDeletedRows, setShowDeletedRows] = useState(true);

  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const filteredRows = showDeletedRows
    ? jwpTaskData
    : jwpTaskData.filter(jwpTask => jwpTask.hasOwnProperty('deleted') && jwpTask.deleted.is_deleted === false);


  const getStatus = (status) => {
    if (status === 1) return "New";
    if (status === 2) return "In Progress";
    if (status === 3) return <CheckCircleOutlineIcon style={{ color: "#0ccc0c" }} />;
    if (status === 4) return "Closed";
    if (status === 5) return "Rejected";
    if (status === 6) return "Accepted"
  }
  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 70,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },

    {
      field: 'taskId',
      headerName: 'Task Id',
      width: 200,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },

    {
      field: 'taskType',
      headerName: 'Type',
      width: 150,
      renderCell: (params) => {
        let typeText = '--';

        if (params.value === 1) {
          typeText = 'Send';
        } else if (params.value === 2) {
          typeText = 'Deploy';
        }

        return <span>{typeText}</span>;
      }
    },

    {
      field: 'assignee',
      headerName: 'Assignee',
      width: 200,
    },

    // {
    //   field: 'sourceLocation',
    //   headerName: 'Origin',
    //   width: 200,
    // },
    {
      field: 'destinationLocation',
      headerName: 'Destination',
      width: 200,
    },
    {
      field: 'dateOfArrival',
      headerName: 'Date of Arrival',
      width: 150,
    },

    {
      field: 'listOfCandidates',
      headerName: 'No. of Candidates',
      width: 200,
      renderCell: (params) => {
        const showCandidates = () => {
          const url = `/jwp-task/candidates/${params?.row?.taskId}`;
          window.open(url, '_blank');
        }

        return (
          < Box style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
            <Box>{(params.value).length}</Box>
            <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={showCandidates} >view candidate</span>
          </Box >
        );
      },
    },

    {
      field: 'jobId',
      headerName: 'Job ID',
      width: 200,
    },

    {
      field: 'createdAt',
      headerName: 'Date & Time',
      width: 200,
      renderCell: (params) => {
        return (<span>{new Date(params.value).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}</span>)
      }
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => {
        return (<span>{
          getStatus(params.row.status)}</span>)
      }
    },

  ];

  const deleteJwpTask = async () => {
    try {
      console.log(candidateId)
      let response = await deleteRequest(`admin./jwp-task?announcement_uuid=${candidateId}`);

      handleSnackBar(true, response.data.data, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllJwpTask();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  }

  const getAllJwpTask = async () => {
    try {
      let response = await getRequestWithToken(`admin/jwptask?page=${parseInt(pageNumber + 1)}&limit=${pageSize}`);
      setRowCount(response.data.total_count);
      setJwpTaskData(response.data.data);
      // console.log(JSON.stringify(response.data.total_count));
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }

  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }

  const markComplete = async (job_id) => {
    setLoadingDataInGrid(true);
    try {
      let res = await patchRequestWithToken(`admin/jwptask/${job_id}`);
      if (res.data.status === 'Success') {
        handleSnackBar(true, res.data.msg, "success");
        setJwpTaskData((prev) => {
          console.log(job_id)
          let res = prev?.map(prevjob => {
            if (prevjob?.status === job_id) {
              return ({
                ...prevjob,
                is_managed: !prevjob.is_managed
              })
            } else return prevjob;
          })
          return res;
        })
        setLoadingDataInGrid(false);
      }
      else {
        handleSnackBar(true, res.data.msg, "error");
        setLoadingDataInGrid(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLoadingDataInGrid(false);
    }
  }

  useEffect(() => {
    getAllJwpTask();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue])


  return ((isIncludesValue) ?
    <Box className="employers-section">
      <Box className="employers-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        <h1 className='formHeadings'>Jobseva / JWP Task</h1>
        {/* <FormGroup>
          <FormControlLabel control={<Switch checked={showDeletedRows} onChange={handleToggleChange} />} label="Include Deleted Users" labelPlacement="start" />
        </FormGroup> */}

      </Box>

      <Box className="employers-data">
        {showLoader ? <ReusableLoader /> : <DataGrid
          rowCount={rowCount}
          pagination
          rows={filteredRows}
          // rows={staticRow}
          columns={columns}
          getRowId={(jwpTaskData) => jwpTaskData._id} //
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 50, 100]}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
          onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
          loading={loadingDataInGrid}

        />}
      </Box>
      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this user"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deleteJwpTask();
        }}
      />
    </Box> : (<Unauthorized />)
  )
}
export default JwpTask;
