import "./announcement.scss";
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { json, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { useParams, useLocation } from "react-router-dom";
import BackBtn from '../../components/backButton/BackBtn';



const JSInfo = () => {
  const [JSData, setJSData] = useState([]);
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);
  const { page_name } = useParams();

  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();


  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 70,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row.id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },

    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 200,
    },

    {
      field: 'mobile',
      headerName: 'Mobile',
      width: 200,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 200,
    },
    {
      field: 'person_name',
      headerName: 'Emergency Contact Person',
      width: 200,
    },
    {
      field: 'person_contact',
      headerName: 'Emergency Contact No.',
      width: 200,
    },
  ];

  const getAllJSData = async () => {
    let endpoint;
    if (page_name === "broadcasted_list") {
      endpoint = `admin/jwptask-deploy-broadcasted-js?page=${parseInt(pageNumber + 1)}&limit=${pageSize}&taskId=${location?.state}`;
    } else if (page_name === "rejected_list") {
      endpoint = `admin/jwptask-deploy-rejected-js?page=${parseInt(pageNumber + 1)}&limit=${pageSize}&taskId=${location?.state}`;
    }

    try {
      let response = await getRequestWithToken(endpoint);
      let jsDataIs = response.data.data.map(item => item.jobSahayak)
      let formattedData = jsDataIs.map(item => ({
        id: item._id,
        name: `${item.first_name} ${item.middle_name} ${item.last_name}`,
        email: item.email,
        mobile: item.mobile,
        address: `${item.location.address}, ${item.location.city},${item.location.village},${item.location.state},${item.location.postal_code}`,
        person_name: item.emergency_contact.person_name,
        person_contact: item.emergency_contact.mobile
      }));

      setRowCount(response.data.total_count);
      setJSData(formattedData);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }
  }


  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    console.log("resourceNames : " + checkAccesInArray)
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }


  useEffect(() => {
    getAllJSData();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue])


  return ((isIncludesValue) ?
    <Box className="employers-section">
      <Box className="employers-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        <Box className="headingPlusBackBtn" style={{ marginLeft: "30px" }}>
          <BackBtn />
          <h1 className='formHeadings'>JobSeva / Deploy Task / JS /
            <span style={{ textTransform: "capitalize" }}> {page_name?.replace("_", " ")}</span>
          </h1>
        </Box>

      </Box>

      <Box className="employers-data">
        {showLoader ? <ReusableLoader /> : <DataGrid
          rowCount={rowCount}
          pagination
          rows={JSData}
          columns={columns}
          getRowId={(JSData) => JSData.id} //
          paginationMode="server"
          rowsPerPageOptions={[10, 20, 50, 100]}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
          onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
          loading={loadingDataInGrid}

        />}
      </Box>

    </Box> : (<Unauthorized />)
  )
}
export default JSInfo;
