import "./manageroles.scss";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useContext, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import {
  postRequest,
  getRequest,
  patchRequestWithToken,
} from "../../utils/ApiRequests";

import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { CofigRolesValidationSchema } from "../../utils/Validations";

const ConfigRoles = () => {
  const [createRole, setCreateRole] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [modulesName, setModulesName] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [operationMode, setOperationMode] = useState("read");
  /*Accordion Expansion*/
  const [expandedAccordion, setExpandedAccordion] = useState(0);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [roleNamesArray, setRoleNamesArray] = useState([]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  //generating the intial values
  const generateInitialValues = (moduleNames, rolename, existingData) => {
    const initialValues = {
      role_name: rolename ? rolename : "", // You may set this to an initial role name
      permissions: {},
    };
    console.log(initialValues, "intialValuse");
    // Populate permissions based on module names and existing data
    moduleNames.forEach((moduleName) => {
      initialValues.permissions[moduleName] = {
        read: false,
        create: false,
        update: false,
        delete: false,
      };
    });
    console.log(initialValues, "initalValues");
    // If you have existing data, you can merge it into initialValues
    if (existingData) {
      existingData.forEach((dataItem) => {
        const { role, resource, action } = dataItem;
        if (initialValues.permissions[resource]) {
          // Assuming the action is in the format "action:any"
          const permissionAction = action.split(":")[0];
          initialValues.permissions[resource][permissionAction] = true;
        }
      });
    }

    return initialValues;
  };

  const initialValues = generateInitialValues(modulesName);

  const createNewRole = () => {
    setCreateRole(true);
  };
  const handleClose = () => setCreateRole(false);

  const { handleSnackBar } = useContext(GlobalContext);

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray)
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Config Roles"));
  };

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitting(true);
    const outputRoles = [];

    for (const [resource, actions] of Object.entries(values.permissions)) {
      for (const [action, value] of Object.entries(actions)) {
        if (value) {
          console.log("value of role is this : " + values.role_name);
          const roleObject = {
            role: values.role_name,
            resource: resource,
            action: `${action}:any`,
            attributes: ["*"],
          };
          outputRoles.push(roleObject);
        }
      }
    }

    // Check if the array is empty during edit mode and add a default role if needed
    if (operationMode === "edit" && outputRoles.length === 0) {
      const defaultRoleObject = {
        role: values.role_name,
        // resource: "no resources",
        // action: "no actions",
      };
      // let cstmMsg = operationMode === "edit" ? "Please select at least one permission" : "Please select at least one permission"
      // handleSnackBar(true, cstmMsg, "error");
      outputRoles.push(defaultRoleObject);
    }

    const formData = {
      roles: outputRoles,
    };

    try {
      // console.log("formData  : " + JSON.stringify(formData));
      let response =
        operationMode === "edit"
          ? await patchRequestWithToken("admin/access-role", formData)
          : await postRequest("admin/access-role", formData);
      if (response.status === 201 || response.status === 200) {
        let msg =
          operationMode === "edit"
            ? "You have successfully updated the role"
            : "You have successfully created the role";
        setFormSubmitting(false);
        handleSnackBar(true, msg, "success");
        resetForm();
        setCreateRole(false);
        operationMode === "edit" && setOperationMode("read");
      }
    } catch (err) {
      setFormSubmitting(false);
      let errorMsg = JSON.stringify(err.response.data.msg);
      if (errorMsg === `"roles[0].resource is required"`) {
        handleSnackBar(true, "Please select at least one permission", "error");
      } else {
        handleSnackBar(true, errorMsg, "error");
      }
    }
  };

  const cancelFormSubmitting = (resetForm) => {
    resetForm();
    setCreateRole(false);
  };
  const TableModule = ({ values, setFieldValue, operationMode }) => {
    return (
      <TableContainer className="table" style={{ margin: "0px" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell" width="20%">
                Module
              </TableCell>
              <TableCell className="tableCell" width="80%">
                Permission
              </TableCell>
            </TableRow>
          </TableHead>

          <TableRow>
            <TableCell className="tableContent role" width="20%"></TableCell>
            <TableCell className="permission" width="80%">
              <TableCell width="200px">Read</TableCell>
              <TableCell width="200px">Create</TableCell>
              <TableCell width="200px">Update</TableCell>
              <TableCell width="200px">Delete</TableCell>
            </TableCell>
          </TableRow>
          <TableBody>
            {modulesName &&
              modulesName.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="tableContent module_name" width="20%">
                      {item.replace("_", " ")}
                    </TableCell>
                    <TableCell className="permission" width="80%">
                      <TableCell width="200px">
                        <input
                          type="checkbox"
                          id="read"
                          checked={values?.permissions[item]?.read}
                          disabled={operationMode === "read"}
                          onChange={(e) =>
                            setFieldValue(
                              `permissions.${item}.read`,
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                      <TableCell width="200px">
                        <input
                          type="checkbox"
                          id="create"
                          disabled={operationMode === "read"}
                          checked={values?.permissions[item]?.create}
                          onChange={(e) =>
                            setFieldValue(
                              `permissions.${item}.create`,
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                      <TableCell width="200px">
                        <input
                          type="checkbox"
                          id="update"
                          checked={values?.permissions[item]?.update}
                          disabled={operationMode === "read"}
                          onChange={(e) =>
                            setFieldValue(
                              `permissions.${item}.update`,
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                      <TableCell width="200px">
                        <input
                          type="checkbox"
                          id="delete"
                          checked={values?.permissions[item]?.delete}
                          disabled={operationMode === "read"}
                          onChange={(e) =>
                            setFieldValue(
                              `permissions.${item}.delete`,
                              e.target.checked
                            )
                          }
                        />
                      </TableCell>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  useEffect(() => {
    let isMounted = true;
    const getRoleData = async () => {
      try {
        let response = await getRequest("admin/access-role");
        if (response.status === 201) {
          // Group the data by the "role" property
          const groupedData = response?.data?.data.reduce((result, item) => {
            const key = item.role;
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(item);
            return result;
          }, {});

          // Filter based on your condition (similar roles)
          const filteredData = Object.values(groupedData).filter(
            (group) => group.length >= 1
          );
          let rolesArray = filteredData.map((group) => group[0].role);
          setRoleNamesArray(rolesArray);
          console.log("newly filtered Roles 2: ", roleNamesArray);

          const dataWithRole = filteredData.map((group) => {
            const role = group[0].role; // Assuming that each group has the same role
            return [role, ...group];
          });
          // console.log("filtered Roled : " + JSON.stringify(dataWithRole))
          if (isMounted) {
            setRoleData(dataWithRole);
            setShowLoader(false);
          }
        }
      } catch (err) {
        setRoleData([]);
      }
    };

    const getModulesName = async () => {
      try {
        let response = await getRequest("admin/active-model");
        if (response.status === 200) {
          // Group the data by the "role" property
          setModulesName(response?.data?.data);
          // console.log(response?.data?.data)
        }
      } catch (err) {
        setModulesName([]);
      }
    };

    getRoleData();
    getModulesName();
    againCheckPermissions();

    return () => {
      // Set isMounted to false when the component is unmounted
      isMounted = false;
    };
  }, [createRole, operationMode, isIncludesValue, !roleNamesArray[0]]);

  /*Pagination */
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page index
  // Calculate the start and end indexes based on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRoleData =
    roleData.length > 0 ? roleData?.slice(startIndex, endIndex) : [];

  // Function to handle page changes
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return isIncludesValue ? (
    <Box className="config-roles-section">
      <Box className="config-roles-top-section">
        <h4>Roles</h4>
        <Button variant="contained" color="success" onClick={createNewRole}>
          Create Roles
        </Button>
      </Box>

      <Container className="config-roles-data-section">
        {showLoader ? (
          <ReusableLoader />
        ) : (
          paginatedRoleData.length > 0 &&
          paginatedRoleData.map((roleItem, index) => {
            return (
              <Accordion
                key={index}
                expanded={expandedAccordion === index}
                onChange={handleAccordionChange(index)}
              >
                <AccordionSummary
                  style={{ textTransform: "capitalize" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {roleItem[0].replace("_", " ")}
                </AccordionSummary>

                <AccordionDetails>
                  <Formik
                    initialValues={generateInitialValues(
                      modulesName,
                      roleItem[0],
                      roleItem
                    )}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, values, setFieldValue, resetForm }) => (
                      <Form className="config-role-form">
                        <TableModule
                          values={values}
                          setFieldValue={setFieldValue}
                          operationMode={operationMode}
                        />
                        {operationMode === "read" ? (
                          <Button
                            type="button"
                            onClick={() => {
                              setOperationMode("edit");
                            }}
                          >
                            Edit
                          </Button>
                        ) : (
                          <div>
                            <Button type="submit" disabled={formSubmitting}>
                              Update
                            </Button>
                            <Button
                              type="button"
                              onClick={() => {
                                setOperationMode("read");
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
        <div
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Pagination
            count={Math.ceil(roleData.length / itemsPerPage)} // Calculate the total number of pages
            page={currentPage}
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      </Container>
      <Modal
        className="form-modal-wrapper"
        open={createRole}
        onClose={handleClose}
      >
        <Box className="form-modal">
          <Box className="form-header">
            <CloseIcon onClick={handleClose} />
          </Box>
          <Box className="form-body">
            <Formik
              initialValues={initialValues}
              validationSchema={CofigRolesValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, values, setFieldValue, resetForm }) => (
                <Form className="create-role-form">
                  <Box className="form-control">
                    <label className="form-label" htmlFor="role_name">
                      {" "}
                      Role Name
                    </label>
                    <Box>
                      <Field
                        id="role_name"
                        type="text"
                        placeholder="Enter Role Name"
                        name="role_name"
                        className="form-input"
                      />
                      {errors.role_name && (
                        <Box className="form-error">{errors.role_name}</Box>
                      )}
                    </Box>
                  </Box>
                  <TableModule
                    values={values}
                    setFieldValue={setFieldValue}
                    operationMode="create"
                  />

                  <Box className="form-button-section">
                    <Button
                      className="form-button"
                      variant="contained"
                      type="button"
                      color="error"
                      onClick={() => cancelFormSubmitting(resetForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="form-button"
                      variant="contained"
                      type="submit"
                      color="success"
                      disabled={formSubmitting}
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  ) : (
    <Unauthorized />
  );
};
export default ConfigRoles;
