import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequestWithToken } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import JobConfigurationCreateEditModal from './JobConfigurationCreateEditModal';
import SureYouWantToDeletModal from '../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal';
import { checkPermission } from '../../utils/userPermissions';
import Unauthorized from '../../components/unauthorized/Unauthorized';

const JobConfiguration = () => {

  const { handleSnackBar } = useContext(GlobalContext);
  const [jobTitleRows, setJobTitleRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteModalTitle, setDeleteModalTitle] = useState("");
  const [showLoaderForTheGrid, setShowLoaderForTheGrid] = useState(false);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const columns = [
    {
      field: 'id', headerName: 'S.No.', width: 100, renderCell: (params) => {

        const { _id } = params.row;
        return <Typography>{params.api.getRowIndex(_id) + 1}</Typography>
      },
    },
    {
      field: "jobTitleInEnglish",
      headerName: "Job Title in English",
      width: 250,
      renderCell: (params) => {
        const { title_en } = params.row;
        return <Typography sx={{ textTransform: "capitalize" }}>{title_en}</Typography>
      },
    },
    {
      field: "jobTitleInHindi",
      headerName: "Job Title in Hindi",
      width: 250,
      renderCell: (params) => {
        const { title_hi } = params.row;
        return <Typography>{title_hi}</Typography>
      },
    },
    {
      field: "jobTitleInHinglish",
      headerName: "Job Title in Hinglishs",
      width: 250,
      renderCell: (params) => {
        const { title_hn } = params.row;
        return <Typography sx={{ textTransform: "capitalize" }}>{title_hn}</Typography>
      },
    },
    {
      field: "jobTitleImage",
      headerName: "Image",
      width: 200,
      renderCell: (params) => {
        const { title_image_url } = params.row;
        return <img style={{
          objectFit: "contain",
          width: "100%",
          height: "100%",
          display: "block"
        }}
          src={title_image_url}
          alt="Job Title Image"
        />
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        const { title_en, title_hi, title_hn, title_image_url, _id } = params.row;
        return <Box sx={{ width: "100%" }} display="flex" justifyContent="center" alignItems="center" >
          <Box>
            {canUpdate ? (<EditIcon onClick={() => {
              setEditId(_id);
              setEditData({
                jobTitleInEnglish: title_en,
                jobTitleInHindi: title_hi,
                jobTitleInHinglish: title_hn,
                svgPngImg: title_image_url
              })
              setOpenModal(true);
            }} className="cursor-pointer" color="primary" />) : ""}

          </Box>
          <Box marginLeft={4}>
            {canDelete ? (<DeleteIcon onClick={() => {
              setDeleteId(_id);
              setOpenDeleteModal(true);
              setDeleteModalTitle(title_en);
            }} className="cursor-pointer" color="error" />) : ""}

          </Box>
        </Box>
      },
    },
  ]

  let rows = [{
    id: "1",
    jobTitleInEnglish: "English",
    jobTitleInHindi: "Hindi",
    jobTitleInHinglish: "Hinglish"
  }];

  console.log(jobTitleRows, " these are job title rows");

  const getAllJobTitles = async () => {
    setShowLoaderForTheGrid(true);
    try {
      const response = await getRequestWithToken("admin/jobtitle");
      const { status = "", data = [] } = response?.data;
      if (status === "Success") {
        setJobTitleRows([...data]);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
    }
    setShowLoaderForTheGrid(false);
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Job Title";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
    console.log("resourceToUpdate:", resourceToUpdate);
    console.log("Can read:", permissions.canRead);
    console.log("Can create:", permissions.canCreate);
    console.log("Can delete:", permissions.canDelete);
    console.log("Can update:", permissions.canUpdate);
  }

  const againCheckPermissions = () => {
    console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    console.log("resourceNames : " + checkAccesInArray)
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Job Title"))
  }

  useEffect(() => {
    getAllJobTitles();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue])

  const handleDeleteClick = async () => {
    setShowLoaderForTheGrid(true);
    try {
      const response = await deleteRequest(`admin/jobtitle/?id=${deleteId}`);
      const { msg, status } = response?.data;
      console.log(response, " this is response");
      if (status === "Success") {
        handleSnackBar(true, msg, "success")
        getAllJobTitles();
        setOpenDeleteModal(false);
      }
      else
        handleSnackBar(true, msg, "error");
    } catch (error) {
      setOpenDeleteModal(false);
      setShowLoaderForTheGrid(false);
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
    }
  }

  return ((isIncludesValue) ?
    <Box margin={2} >
      <Box marginY={2} >
        {canCreate ? (<Button variant="contained" onClick={() => {
          setEditData(null);
          setOpenModal(true);
        }}>Create a Job Title</Button>) : ""}
      </Box>

      <Box sx={{ height: "80vh" }}>
        <DataGrid loading={showLoaderForTheGrid} rowHeight={100} columns={columns} rows={jobTitleRows} getRowId={(row) => row._id} />
      </Box>
      {openModal && <JobConfigurationCreateEditModal
        editId={editId}
        openModal={openModal}
        setOpenModal={setOpenModal}
        editData={editData}
        getAllJobTitles={getAllJobTitles}
        showLoaderForTheGrid={showLoaderForTheGrid}
        setShowLoaderForTheGrid={setShowLoaderForTheGrid}
      />}

      <SureYouWantToDeletModal
        modalTitle="Delete Job Title"
        dialogBody={`Are you sure you want to delete this ${deleteModalTitle} Job Title`}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={handleDeleteClick}
        disabled={showLoaderForTheGrid}
        endIcon={showLoaderForTheGrid ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : ""}
      />
    </Box> : (<Unauthorized />)
  )
}

export default JobConfiguration