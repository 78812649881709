import { Box, Button, Snackbar, Alert, MenuItem, FormControl, Select, Container, Grid, Typography, InputLabel, Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import "./jobsection.scss";
import { useContext, useEffect, useState, useRef } from 'react';
import { CreateEmployerValidationSchema, CreateRecruiterClientValidationSchema, UpdateEmployerValidationSchema, UpdateJobValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { useDropzone } from "react-dropzone";
import NorthIcon from '@mui/icons-material/North';
import CircularProgress from '@mui/material/CircularProgress';
import { uploadImage } from '../../utils/ApiRequests';


const ViewJob = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const [dataById, setDataById] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);

  const [fileImg, setFileImg] = useState("");
  const [imageUploader, setImageUploader] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [sector, setSector] = useState([]);

  const [imageUpload, setImageUpload] = useState([]);
  const [pincodeLoader, setPincodeLoader] = useState(false);
  const [sectorValueIs, setSectorValueIs] = useState('');
  const [occupationValueIs, setOccupationValueIs] = useState('');
  const [roleValueIs, setRoleValueIs] = useState('');

  const [jobRoles, setJobRoles] = useState([]);
  const [showLoaderForDepartement, setShowLoaderForDepartement] = useState(false);
  const [occupations, setOccupations] = useState([]);
  const [showLoaderForJobRoles, setShowLoaderForJobRoles] = useState(false);
  const [showLoaderForJobSector, setShowLoaderForJobSector] = useState(false);
  const [roleNameEn, setRoleNameEn] = useState('');


  const [initialValues, setInitialValues] = useState({
    jobSector: "",
    jobOccupation: "",
    jobRole: "",
    jobRoleNameEn: "",

  })
  const [edit, setEdit] = useState(false);

  const inputRefs = {};

  const scrollToErrorField = (fieldName) => {
    if (inputRefs[fieldName]) {
      inputRefs[fieldName].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true);
    let formData = new FormData();
    formData = {
      jobSector: values.jobSector,
      jobOccupation: values.jobOccupation,
      jobRole: values.jobRole,
      jobRoleNameEn: roleNameEn,
    }

    try {
      let res = await patchRequestWithToken(`admin/job/${location?.state}`, formData);
      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        resetForm();
        if (scrollTargetRef.current) {
          scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        handleSnackBar(true, res.data.message, "success");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.message, "error")
      }
    } catch (err) {
      console.log(err);
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.message, "error")
    }
  }

  const getEmployerById = async () => {
    try {
      let response = await getRequestWithToken(`admin/job/${location?.state}`);
      setDataById(response.data.data)
      // setFieldValue("jobSector", selectedSectorValue);
      // setSectorValueIs(response?.data?.data?.employerId?.sectorType?._id);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "All Jobs";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("All Jobs"))
  }

  const getSectors = async () => {
    setShowLoaderForJobSector(true)
    try {
      let data = await getRequestWithToken("admin/sectors");
      if (data.data.status === "Success") {
        setSector([...data.data.data]);
        setShowLoaderForJobSector(false)
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setShowLoaderForJobSector(false)

    }
  };

  const getDepartment = async (jobsector) => {
    setJobRoles([])
    setShowLoaderForDepartement(true);
    try {
      let data = await getRequestWithToken(
        `admin/occupations?sectorId=${jobsector}`
      );
      if (data.data.status === "Success") {
        setOccupations([...data.data.data]);
        setShowLoaderForDepartement(false);
      } else {
        handleSnackBar(true, data.data.status, "error");
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setOccupations([]);
      setJobRoles([])
    }
    setShowLoaderForDepartement(false);
  };

  useEffect(() => {
    getDepartment(sectorValueIs);
  }, [sectorValueIs])

  useEffect(() => {
    getJobRoles(occupationValueIs);
  }, [occupationValueIs])


  const getJobRoles = async (jobOccupation) => {
    setShowLoaderForJobRoles(true);
    try {
      let data = await getRequestWithToken(
        `admin/job-roles?occupation=${jobOccupation}`
      );
      if (data.data.status === "Success") {
        setJobRoles([...data.data.data]);
        setShowLoaderForJobRoles(false);
      } else {
        handleSnackBar(true, data.data.status, "error");
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setJobRoles([])
    }
    setShowLoaderForJobRoles(false);
  };


  useEffect(() => {
    getSectors();
    checkPermissionFunc();
    againCheckPermissions();

    if (location.state) {
      getEmployerById()
    }
    else {
      setShowLoader(false);
    }

  }, [location.state, dataById?._id, isIncludesValue])

  const onDrop = async (acceptedFiles) => {
    const empData = localStorage.getItem('EmployerLoginData');
    setFileData(acceptedFiles)
    setImageUpload(acceptedFiles[0])
    acceptedFiles.forEach((file, index) => {

      let url = URL.createObjectURL(file);
      if (index === 0)
        setFileImg(url)
    })
    // let formData = new FormData();
    // formData.append("image", acceptedFiles[0]);
    // setImageUploader(true)
    // try {
    //   let response = await uploadImage(`upload`, formData);
    //   if (response.status === "Success") {
    //     handleSnackBar(true, response.data, "success")
    //     setImageUploader(false)
    //   }

    // } catch (err) {
    //   handleSnackBar(true, err?.response?.data?.msg, "error")
    //   console.log(err)
    //   setImageUploader(false)

    // }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-employer-section" ref={scrollTargetRef}>

        <Container>
          <Box className="create-employer">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings'>View Job</h1>
            </Box>


            <Box className="create-employer-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={UpdateJobValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-employer-form ">

                    <Box className="backgroundStyle">

                      <Typography style={{ marginBottom: "10px" }}>Existing : </Typography>
                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={6} sm={6} md={6}>
                          <Box>
                            <Typography className="form-heading">Employer ID : {dataById?.jobId}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                          <Box>
                            <Typography className="form-heading">Job ID : {dataById?.employerId?.employerId}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label">Employer Job Sector </label>
                            <Typography className="borderBx form-heading">{dataById?.employerId?.sectorType?.sectorNameEn}</Typography>
                          </Box>
                        </Grid>

                        {dataById?.clientId !== null && <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label">Client Job Sector </label>
                            <Typography className="borderBx form-heading">{dataById?.clientId?.organizationSector?.sectorNameEn}</Typography>
                          </Box>
                        </Grid>}


                      </Grid>
                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label">Job Sector </label>
                            <Typography className="borderBx form-heading">{dataById?.sector?.sectorNameEn}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label"> Job Occupation </label>
                            <Typography className=" borderBx form-heading">{dataById?.occupationType?.occupationNameEn}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" > Job Role  </label>
                            <Typography className="borderBx form-heading">{dataById?.jobRole?.jobRoleNameEn}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="backgroundStyle">
                      <Typography style={{ marginBottom: "10px" }}>Updated : </Typography>
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jobSector">Employer/Client Job Sector  <span className='must-fill-fields'>*</span>{showLoaderForJobSector && <CircularProgress style={{ width: '15px', height: '15px' }} />}  </label>
                            <FormControl name="jobSector" fullWidth size="small">

                              <Autocomplete
                                id="jobSector"

                                options={sector || []}
                                getOptionLabel={(option) => option.sectorNameEn}
                                value={sector.find((s) => s._id === values.jobSector) || null}
                                onChange={(event, newValue) => {
                                  const selectedSectorValue = newValue?._id || '';
                                  console.log("selectedSectorValue", selectedSectorValue);

                                  setFieldValue("jobSector", selectedSectorValue);
                                  setSectorValueIs(selectedSectorValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} placeholder="Select Job Sector" />
                                )}
                              />
                            </FormControl>
                            {errors.jobSector && <Box className="form-error">{errors.jobSector}</Box>}
                          </Box>
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label">Job Sector </label>
                            <Typography className="borderBx form-heading">{dataById?.employerId?.sectorType?.sectorNameEn}</Typography>
                          </Box>
                          {errors.jobSector && <Box className="form-error">{errors.jobSector}</Box>}
                        </Grid> */}

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jobOccupation"> Job Occupation <span className='must-fill-fields'>*</span>{showLoaderForDepartement && <CircularProgress style={{ width: '15px', height: '15px' }} />}  </label>
                            <FormControl name="jobOccupation" fullWidth size="small">
                              {/* {values.jobOccupation === "" && <InputLabel shrink={false}>Select Job Occupation</InputLabel>} */}
                              <Autocomplete
                                id="jobOccupation"
                                options={occupations || []}
                                getOptionLabel={(option) => option.occupationNameEn}
                                value={occupations.find((o) => o._id === values.jobOccupation) || null}
                                onChange={(event, newValue) => {
                                  const selectedOccupationValue = newValue?._id || '';
                                  setFieldValue("jobOccupation", selectedOccupationValue);
                                  setOccupationValueIs(selectedOccupationValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} placeholder="Select Job Occupation" />
                                )}
                              />
                            </FormControl>
                            {errors.jobOccupation && <Box className="form-error">{errors.jobOccupation}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jobRole"> Job Role  <span className='must-fill-fields'>*</span>{showLoaderForJobRoles && <CircularProgress style={{ width: '15px', height: '15px' }} />} </label>
                            <FormControl name="jobRole" fullWidth size="small">
                              {/* {values.jobRole === "" && <InputLabel shrink={false}>Select Job Role</InputLabel>} */}
                              <Autocomplete
                                id="jobRole"
                                options={jobRoles || []}
                                getOptionLabel={(option) => option.jobRoleNameEn}
                                value={jobRoles.find((r) => r._id === values.jobRole) || null}
                                onChange={(event, newValue) => {
                                  const selectedRoleValue = newValue?._id || '';
                                  setFieldValue("jobRole", selectedRoleValue);
                                  setRoleValueIs(selectedRoleValue);
                                  setRoleNameEn(newValue?.jobRoleNameEn || '');
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} placeholder="Select Job Role" />
                                )}
                              />
                            </FormControl>
                            {errors.jobRole && <Box className="form-error">{errors.jobRole}</Box>}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box className="form-button-section">

                      <Button className="form-button"
                        variant="contained"
                        onClick={() => {
                          resetForm();
                        }}
                        color="error"
                      >Cancel</Button>


                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}
                      >Approve & Submit</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container >

      </Box >}
    </> : (<Unauthorized />)

  )

}
export default ViewJob;