import "./Announcement";
import { Box, Button, Stack, Alert, Tooltip, Chip, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { json, useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect } from "react";
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';


const CandidateView = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);

  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params?.row?.candidate._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'full_name',
      headerName: 'Name',
      width: 250,
      valueGetter: (params) => {
        const { first_name = "", middle_name = "", last_name = "" } = params?.row?.candidate?.candidate;
        const fullName = `${first_name} ${middle_name} ${last_name}`;
        return fullName;
      },
    },
    {
      field: 'mobile',
      headerName: 'Phone Number',
      width: 150,
      valueGetter: (params) => params?.row?.candidate?.candidate?.mobile,
    },
    {
      field: 'age',
      headerName: 'Age',
      width: 100,
      valueGetter: (params) => params?.row?.candidate?.candidate?.age,
    },

    {
      field: 'address',
      headerName: 'Address',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.candidate?.city + ', ' + params.row?.candidate?.candidate?.state}</span>)
      }

    },
    {
      field: 'kyc_status',
      headerName: 'KYC Status',
      width: 180,
      valueGetter: (params) => params?.row?.candidate?.candidate?.kyc_status ? "Verified" : "Not Verified",
    },
    {
      field: 'jobshayak',
      headerName: 'Job Shayak',
      width: 200,
      renderCell: (params) => {
        return (<span>{params.row?.candidate?.candidate?.job_sahayak_uuid ? "Assigned" : "Not Assigned"}</span>)
      }
    },
    {
      field: 'createdAt',
      headerName: 'Date and Time',
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            <table>

              <tbody>
                <tr>
                  <td style={{ minWidth: "200px" }}>{new Date(params?.row?.candidate?.createdAt).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      },
    },

  ];

  const { taskId } = useParams();

  const getAllCandidates = async () => {
    try {
      let response = await getRequestWithToken(`admin/jwptask-candidate?page=${pageNumber + 1}&limit=${pageSize}&taskId=${taskId}`);
      setCandidateData(response.data.data);
      console.log(response.data.data)
      setRowCount(response?.data?.total_count);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      setCandidateData([]);
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);

    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }


  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }


  useEffect(() => {
    getAllCandidates();
    checkPermissionFunc();
    againCheckPermissions();

  }, [pageSize, pageNumber, isIncludesValue])
  return (
    (isIncludesValue) ?
      <Box className="candidates-section">

        <Box className="candidates-section-data">
          {showLoader ? <ReusableLoader /> :
            <DataGrid
              rows={candidateData}
              columns={columns}
              getRowId={(candidateData) => candidateData?.candidate._id}
              paginationMode="server"
              pagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              page={pageNumber}
              pageSize={pageSize}
              onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
              onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
              loading={loadingDataInGrid}
              rowCount={rowCount}
            />
          }
        </Box>


      </Box> : (<Unauthorized />)
  )
}
export default CandidateView;
