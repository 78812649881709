import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import * as React from 'react';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ClearIcon from '@mui/icons-material/Clear';
import './Layout.scss'


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const Layout = ({ children }) => {
  // const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <Drawer className="cstmDrawer" variant="permanent" open={open}>
        <Link to="/dashboard" className="sidebarLogo">
          <img className="jYLogoHorizontal" style={{ textDecoration: "none", display: open ? "block" : "none" }} src="https://jobyahanp.s3.ap-south-1.amazonaws.com/images/logo/jobsyahan-logo-new.svg" alt="logo" />
          <img className="jYLogoVertical" style={{ textDecoration: "none", display: !open ? "block" : "none" }} src="https://jobyahanp.s3.ap-south-1.amazonaws.com/images/logo/JY_vertical_logo_admin.svg" alt="logo" />
        </Link>

        <IconButton className="close-drawer-button" onClick={handleDrawerClose} sx={{
          ...(!open && { display: 'none !important' }),
        }}>
          {/* <ChevronLeftIcon /> */}
          <ClearIcon />

        </IconButton>

        <Sidebar />
      </Drawer>

      <Box component="main" className="website-layout-outer" style={{ width: "calc(100vw - 240px)" }} sx={{ flexGrow: 1, p: 3 }}>
        <Box className="website-layout">
          <Box className="cstmNavbar">

            <Box className={`burgerMenuPlusHeading ${open ? 'burgerMenuPlusHeadingDisplayNone' : ''}`}>
              <IconButton
                className="burgerMenuBtn"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              {/* <Link to="/" style={{ textDecoration: "none" }}>
                <Box className={`logo ${open ? 'logoHidden' : ''}`} >JobsYahan Admin</Box>
              </Link> */}
            </Box>

            <Navbar />
          </Box>

          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;