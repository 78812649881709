import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Login from '../../pages/login/Login';

export default function Logout() {
  const navigate = useNavigate();


  const deleteTokenFromLocalStorage = () => {
    localStorage.removeItem("token");
    navigate('/')
  };

  useEffect(() => {
    deleteTokenFromLocalStorage()
  }, []);

  return (
    <Login />
  )
}
