import "./jobsection.scss";
import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Modal,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useContext, useEffect } from "react";
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { useNavigate } from "react-router-dom";
import { patchRequestWithToken } from "../../utils/ApiRequests";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { UpdateJobRejectValidationSchema } from "../../utils/Validations";
import MaterialTable from "../../components/materialTable/MaterialTable";

const AllJobs = () => {
  const [jobApproved, setJobApproved] = useState(false);
  const [jobRejectionForm, setJobRejectionForm] = useState(false);
  const [jobRejected, setJobRejected] = useState(false);
  const [allJobsData, setAllJobsData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [isManaged, setIsManaged] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const navigate = useNavigate();

  const [employerData, setEmployerData] = useState([]);

  const [tabvalue, setTabValue] = React.useState(0);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [isTheDataLoaded, setIsTheDataLoaded] = useState(false);
  const [rejectingJob, setRejectingJob] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // const isManaged = false;
  // const isFeatured = false;
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const currentTabSelect = (event, newValue) => {
    setTabValue(newValue);
  };

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      accessorKey: "jobId",
      header: "Job Id",
      size: 120,
    },
    {
      accessorKey: "jobRoleNameEn",
      header: "Job Title",
      size: 250,
      Cell: ({ renderedCellValue }) => (
        <div style={{ textTransform: "capitalize" }}>{renderedCellValue}</div>
      ),
    },
    {
      accessorKey: "job description",
      header: "Job Description",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const openDescription = () => {
          navigate("/job-description", {
            state: row.original,
          });
        };
        return (
          <span
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={openDescription}
          >
            view
          </span>
        );
      },
    },
    {
      accessorKey: "state",
      header: "State",
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.state,
    },
    {
      accessorKey: "city",
      header: "City",
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.city,
    },
    {
      accessorKey: "is_managed",
      header: "Managed",
      size: 200,
      accessorFn: (row) => (row.is_managed ? "Yes" : "No"),
    },
    {
      accessorKey: "date_and_time",
      header: "Date & Time",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const createdAt = row.original.createdAt || "--";
        return (
          <span>
            {new Date(createdAt).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
            })}
          </span>
        );
      },
    },
    {
      accessorKey: "company_name",
      header: "Company Name",
      size: 500,
      accessorFn: (row) => row.companyName,
    },
  ];

  const requestedColumns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      accessorKey: "jobId",
      header: "Job Id",
      size: 120,
    },
    {
      accessorKey: "jobRoleNameEn",
      header: "Job Title",
      size: 250,
      Cell: ({ renderedCellValue }) => (
        <div style={{ textTransform: "capitalize" }}>{renderedCellValue}</div>
      ),
    },
    {
      accessorKey: "job description",
      header: "Job Description",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const openDescription = () => {
          navigate("/job-description", {
            state: row.original,
          });
        };
        return (
          <span
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
            onClick={openDescription}
          >
            view
          </span>
        );
      },
    },
    {
      accessorKey: "state",
      header: "State",
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.state,
    },
    {
      accessorKey: "city",
      header: "City",
      size: 200,
      accessorFn: (row) => row.jobDescription?.jobLocation?.city,
    },
    {
      accessorKey: "is_managed",
      header: "Managed",
      size: 200,
      accessorFn: (row) => (row.is_managed ? "Yes" : "No"),
    },
    {
      accessorKey: "date_and_time",
      header: "Date & Time",
      size: 200,
      Cell: ({ row }) => {
        const createdAt = row.original.createdAt || "--";
        return (
          <span>
            {new Date(createdAt).toLocaleString("en-US", {
              timeZone: "Asia/Kolkata",
            })}
          </span>
        );
      },
    },
    {
      accessorKey: "company_name",
      header: "Company Name",
      size: 500,
      accessorFn: (row) => row.companyName,
    },
    {
      accessorKey: "action 1",
      header: "Action",
      size: 250,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canUpdate && (
              <Button
                variant="contained"
                className="newBtns"
                color="primary"
                onClick={() => {
                  navigate("/all-jobs/view-job", { state: row.original._id });
                }}
              >
                View
              </Button>
            )}
            {canUpdate && (
              <Button
                variant="contained"
                className="newBtns"
                color="error"
                onClick={() => {
                  setJobRejectionForm(true);
                  setRejectingJob(row.original._id);
                }}
              >
                Reject
              </Button>
            )}
          </Box>
        );
      },
    },
  ];

  const getAllJobs = async () => {
    setLoadingDataInGrid(true);
    try {
      if (tabvalue === 0) {
        let response = await getRequestWithToken(
          `admin/job?page=${parseInt(pagination.pageIndex + 1)}&limit=${pagination.pageSize
          }&status=active`
        );
        setAllJobsData(response?.data?.data);
        setRowCount(response?.data.totalcount);
        setLoadingDataInGrid(false);
        setShowLoader(false);
        setIsTheDataLoaded(true);
      } else if (tabvalue === 1) {
        let response = await getRequestWithToken(
          `admin/job?page=${parseInt(pagination.pageIndex + 1)}&limit=${pagination.pageSize
          }&status=pending`
        );
        setAllJobsData(response?.data?.data);
        setRowCount(response?.data?.totalcount);
        setLoadingDataInGrid(false);
        setShowLoader(false);
        setIsTheDataLoaded(true);
      }
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.message || error?.message,
        "error"
      );
      setShowLoader(false);
      setLoadingDataInGrid(false);
      setIsTheDataLoaded(true);
    }
  };

  const sendToJobSeva = async (objId) => {
    setLoadingDataInGrid(true);
    try {
      let res = await patchRequestWithToken(`jobs/send-jobseva/${objId}`);
      // console.log(res.data, " this is res data");

      if (res.data.status === "Success") {
        handleSnackBar(true, res.data.msg, "success");
        setLoadingDataInGrid(false);
      } else {
        handleSnackBar(true, res.data.msg, "error");
        setLoadingDataInGrid(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLoadingDataInGrid(false);
    }
  };

  const markManaged = async (job_id, btnName) => {
    setLoadingDataInGrid(true);
    try {
      let res = await patchRequestWithToken(
        `jobs/toggle-ismanaged?job_id=${job_id}&action=${btnName === "managed" ? "true" : "false"
        }`
      );
      if (res.data.status === "Success") {
        handleSnackBar(true, res.data.msg, "success");
        setAllJobsData((prev) => {
          // console.log(job_id)
          let res = prev?.map((prevjob) => {
            if (prevjob?.job_id === job_id) {
              return {
                ...prevjob,
                is_managed: !prevjob.is_managed,
              };
            } else return prevjob;
          });
          return res;
        });
        setLoadingDataInGrid(false);
      } else {
        handleSnackBar(true, res.data.msg, "error");
        setLoadingDataInGrid(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLoadingDataInGrid(false);
    }
  };

  const checkPermissionFunc = () => {
    const resourceToUpdate = "All Jobs";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("All Jobs"));
  };

  useEffect(() => {
    // getAllJobs();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);

  useEffect(() => {
    getAllJobs();
  }, [pagination.pageIndex, pagination.pageSize, tabvalue]);

  const handleSubmit = async (values, { resetForm }) => {
    if (values.button_action === "submit_data") {
      let formData = new FormData();
      formData = {
        reject: true,
        comments: values.comments,
      };
      try {
        let res = await patchRequestWithToken(
          `admin/job/${rejectingJob}`,
          formData
        );
        if (res.data.status === "Success") {
          setFormSubmitted(false);
          resetForm();
          handleSnackBar(true, res.data.msg, "success");
          setJobRejectionForm(false);
          getAllJobs();
        } else {
          setFormSubmitted(false);
          handleSnackBar(true, res.data.msg, "error");
        }
      } catch (err) {
        console.log(err);
        setFormSubmitted(false);
        handleSnackBar(true, err?.response?.data?.msg, "error");
      }
    }
    if (values.button_action === "cancel_submission") {
      setJobRejectionForm(false);
    }
  };

  return isIncludesValue ? (
    <Box className="jobs-section">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={jobApproved}
        autoHideDuration={1000}
        onClose={() => setJobApproved(false)}
      >
        <Alert severity="success">
          You have successfully approved the job.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={jobRejected}
        autoHideDuration={1000}
        onClose={() => setJobRejected(false)}
      >
        <Alert severity="success">
          You have successfully rejected the job.
        </Alert>
      </Snackbar>

      <Modal open={jobRejectionForm} className="job-rejection-modal">
        <Box className="job-rejection-form-outerwrapper">
          <Box className="job-rejection-form-wrapper">
            <Box className="job-rejection-form-topsection">
              <CloseIcon onClick={() => setJobRejectionForm(false)} />
            </Box>
            <Formik
              initialValues={{ comments: "" }}
              validationSchema={UpdateJobRejectValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, setFieldValue }) => (
                <Form className="job-rejection-form">
                  <Box className="form-control">
                    <label className="form-label" htmlFor="comments">
                      {" "}
                      Comments
                    </label>
                    <Field
                      id="comments"
                      as="textarea"
                      placeholder="Enter comments"
                      name="comments"
                      className="form-input"
                      rows="3"
                    />
                    {errors.comments && (
                      <Box className="form-error">{errors.comments}</Box>
                    )}
                  </Box>

                  <Box className="form-button-section">
                    <Button
                      className="form-button"
                      variant="contained"
                      type="submit"
                      color="success"
                      disabled={formSubmitted}
                      onClick={() =>
                        setFieldValue("button_action", "submit_data")
                      }
                    >
                      Reject
                    </Button>
                    <Button
                      className="form-button"
                      variant="contained"
                      type="submit"
                      color="error"
                      onClick={() =>
                        setFieldValue("button_action", "cancel_submission")
                      }
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabvalue}
            onChange={currentTabSelect}
            aria-label="basic tabs example"
          >
            <Tab
              label="Active Jobs"
              style={{ fontWeight: "700" }}
              {...a11yProps(0)}
            />
            <Tab
              label="Pending Jobs"
              style={{ fontWeight: "700" }}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabvalue} index={0}>
          <Box className="jobs-section-data">
            {isTheDataLoaded ? (
              allJobsData.length > 0 ? (
                <MaterialTable
                  filteredRows={allJobsData}
                  columns={columns}
                  rowCount={rowCount}
                  isLoading={loadingDataInGrid}
                  pagination={pagination}
                  columnPinning={columnPinning}
                  setPagination={setPagination}
                  setColumnPinning={setColumnPinning}
                />
              ) : (
                <Box className="no-data-found">No Active Jobs</Box>
              )
            ) : (
              <Box
                sx={{ width: "100%", height: "100%" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {" "}
                <CircularProgress />{" "}
              </Box>
            )}
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={tabvalue} index={1}>
          <Box className="jobs-section-data">
            {isTheDataLoaded ? (
              allJobsData.length > 0 ? (
                <MaterialTable
                  filteredRows={allJobsData}
                  columns={requestedColumns}
                  rowCount={rowCount}
                  isLoading={loadingDataInGrid}
                  pagination={pagination}
                  columnPinning={columnPinning}
                  setPagination={setPagination}
                  setColumnPinning={setColumnPinning}
                />
              ) : (
                <Box className="no-data-found">No Pending Jobs</Box>
              )
            ) : (
              <Box
                sx={{ width: "100%", height: "100%" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {" "}
                <CircularProgress />{" "}
              </Box>
            )}
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  ) : (
    <Unauthorized />
  );
};

export default AllJobs;
