import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import "./employer.scss";
import BackBtn from '../../components/backButton/BackBtn';


const EmployerDetails = () => {
  const { employerId } = useParams();
  const [employerData, setEmployerData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();

  const getEmployerDetails = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid?usertype=employer&userid=${employerId}`);
      setEmployerData(response.data.data);
      console.log("response : " + JSON.stringify(response.data.data))
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
    }
  };

  useEffect(() => {

    getEmployerDetails();
  }, [employerId]);

  useEffect(() => {
    console.log("data response: ", JSON.stringify(employerData));
  }, [employerData]);

  return (
    (showLoader) ? <ReusableLoader /> :
      <Box className="create-candidates-section">

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >{location.state} Details</h1>
            </Box>
            <Box className="create-candidates-form-wrapper">

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeadng '>Personal Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" > First Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.firstName}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> Middle Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.middleName}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Last Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.lastName}</Typography>
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >EUID</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.EUID}</Typography>
                    </Box>
                  </Grid> */}
                </Grid>
              </Box>

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Contact Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">Mobile Number</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.phone}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Email Address</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.email}</Typography>
                    </Box>
                  </Grid>

                </Grid>
              </Box>


              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Company Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Brand Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.business_name}</Typography>
                    </Box>
                  </Grid> */}

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Company Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{employerData?.companyName}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" > Address</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.fullAddress}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" > Pincode</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.pincode}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >City</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.city}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >State</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.state}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className='cstmFormGridEmployer'>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >BusinessLogo</Typography>
                      {/* <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{employerData?.companyAddress?.state}</Typography> */}
                      <img src={employerData?.businessLogo} width={250} style={{ borderRadius: "10px" }} alt="" />
                    </Box>
                  </Grid>

                </Grid>
              </Box>

            </Box>
          </Box>
        </Container>

      </Box >

  );
};

export default EmployerDetails;
