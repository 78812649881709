import React from 'react'
import './SearchBar.scss'
import SearchIcon from '@mui/icons-material/Search';
function SearchBar({placeholder,searchBarValue,setSearchBarValue}) {
  const handleSearchBar = (searchItemName)=>{
    let searchValue = searchItemName;
    if(searchItemName.length>0 && searchItemName.includes('@') && searchItemName.includes('+'))
      searchValue = searchItemName.replace('+','%2B')
    setSearchBarValue(searchValue)
  }
  return (
   <div className='searchbar-section'>
     <div className='searchbar-wrapper'>
     <SearchIcon/>
        <input type="text" placeholder={placeholder}
         onKeyUp={(e)=>handleSearchBar(e.target.value)}/>
     </div>
        
   </div>
  )
}

export default SearchBar