import { Box, Button, MenuItem, FormControl, Select, Container, Grid, InputLabel } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import "./manageroles.scss";
import { useContext, useEffect, useState } from 'react';
import { subadminValidationSchema, editSubadminValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequest } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import FocusError from '../../components/FocusError/FocusError';

const CreateSubadmin = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const { handleSnackBar } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    access_role: " ",
    email_id: "",
    password: "",

  })
  const [edit, setEdit] = useState(false);
  const inputRefs = {};

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true);
    let formData = new FormData();
    formData = {
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      access_role: values.access_role,
      role: "admin"
    }
    try {
      let res = edit ?
        await patchRequestWithToken('admin/admin', { ...formData, admin_id: location?.state?._id }) : await postRequest('admin/admin', {
          ...formData, password: values.password,
          email: values.email_id,

        });
      if (res.data.status === 'Success') {
        if (!edit) resetForm();
        handleSnackBar(true, res.data.msg, "success");
        setFormSubmitted(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      console.log(err);
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error")
    }

  }


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        let response = await getRequest("admin/access-role/list");
        if (response.status === 201) {
          setRolesData(response?.data?.data)
        }
      } catch (err) {
        setRolesData([]);
      }
    }
    if (location?.state?._id) {
      const subadmin = location.state;
      console.log(subadmin)
      setInitialValues({
        first_name: subadmin?.first_name,
        middle_name: subadmin?.middle_name,
        last_name: subadmin?.last_name,
        access_role: subadmin?.access_role,
        email_id: subadmin?.email,
        password: subadmin?.password,
      });
      setEdit(true);
    } else {
      setInitialValues({
        first_name: "",
        middle_name: "",
        last_name: "",
        access_role: "",
        email_id: "",
        password: "",
      });
      setEdit(false);
    }
    getRoles();
  }, [location.state])
  return (
    <Box className="create-subadmin-section">

      <Container>
        <Box className="create-subadmin">
          <h1 className='formHeadings'>{edit ? "Update Sub-Admin" : "Create Sub-Admin"}</h1>
          <Box className="create-subadmin-form-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={edit ? editSubadminValidationSchema : subadminValidationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ errors, values, setFieldValue }) => (

                <Form className="create-subadmin-form ">

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Personal Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="first_name"> First Name <span className='must-fill-fields'>*</span></label>
                          <Field innerRef={(el) => { inputRefs.first_name = el }} id="first_name"
                            type="text" placeholder="Enter First Name"
                            name="first_name"
                            className="form-input" />
                          {errors.first_name && <Box className="form-error">{errors.first_name}</Box>}
                        </Box>

                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="middle_name"> Middle Name </label>
                          <Field
                            id="middle_name"
                            type="text" placeholder="Enter Middle Name"
                            name="middle_name"
                            className="form-input" />
                          {errors.middle_name && <Box className="form-error">{errors.middle_name}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="last_name">Last Name <span className='must-fill-fields'>*</span></label>
                          <Field
                            innerRef={(el) => { inputRefs.last_name = el }}

                            id="last_name"

                            type="text" placeholder="Enter Last Name"
                            name="last_name"
                            className="form-input" />
                          {errors.last_name && <Box className="form-error">{errors.last_name}</Box>}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="backgroundStyle">
                    <p className='formSubSectionHeading'>Other Details</p>
                    <Grid container spacing={2} className='cstmFormGridEmployer'>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="access_role"> Access Role <span className='must-fill-fields'>*</span></label>
                          <FormControl name="access_role" fullWidth size="small">
                            {values.access_role === "" && <InputLabel shrink={false}>Select Role</InputLabel>}
                            <Select
                              name="access_role"
                              id="access_role"
                              value={values.access_role}
                              onChange={(event) => { setFieldValue("access_role", event.target.value) }}>
                              {/* <MenuItem value="" disabled>Please enter your access role</MenuItem> */}
                              {rolesData && rolesData?.roles?.map((item, index) => {
                                return (<MenuItem value={item} key={index}>{capitalizeFirstLetter(item)}</MenuItem>)
                              })}


                            </Select>
                          </FormControl>
                          {errors.access_role && <Box className="form-error">{errors.access_role}</Box>}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="email_id"> Email Id <span className='must-fill-fields'>*</span></label>
                          <Field disabled={edit} id="email_id"
                            type="text" placeholder="Enter  email id"
                            name="email_id"
                            className="form-input" />
                          {errors.email_id && <Box className="form-error">{errors.email_id}</Box>}
                        </Box>
                      </Grid>

                      {!edit && <Grid item xs={12} sm={6} md={4}>
                        <Box className="form-control">
                          <label className="form-label" htmlFor="password"> Password <span className='must-fill-fields'>*</span></label>
                          <Field id="password"
                            type="password" placeholder="Enter password"
                            name="password"
                            className="form-input" />
                          {errors.password && <Box className="form-error">{errors.password}</Box>}
                        </Box>

                      </Grid>}
                    </Grid>
                  </Box>

                  <Box className="form-button-section">
                    <Button className="form-button"
                      variant="contained"
                      type="submit"
                      color="success"
                      disabled={formSubmitted}
                    >{edit ? "Update" : "Create"}</Button>
                    {edit && <Button className="form-button"
                      variant="contained"
                      type="button"
                      color="error"
                      onClick={() => navigate('/manage-roles')}

                    >Cancel</Button>}


                  </Box>
                  <FocusError handleSnackBar={handleSnackBar} />
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Container>

    </Box >
  )

}
export default CreateSubadmin;