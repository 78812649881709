import React from 'react';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Container } from '@mui/material';
import BackBtn from '../../components/backButton/BackBtn';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';


const JobsevaJobDescription = () => {
    const location = useLocation();
    const jobData = location?.state;

    // if (!jobData || !jobData.description || jobData.description.length === 0) {
    //     return (
    //         <Box className="create-candidates-section">
    //             <Container>
    //                 <Box className="create-candidates">
    //                     <Box className="headingPlusBackBtn">
    //                         <BackBtn />
    //                         <h1 className='formHeadings' >Job Details</h1>
    //                     </Box>
    //                     <Box className="create-candidates-form-wrapper">
    //                         <p style={{ fontSize: "20px", textAlign: "center", marginTop: "20%" }}>No job description available</p>
    //                     </Box>
    //                 </Box>
    //             </Container>
    //         </Box >
    //     );
    // }

    return (
        <Box className="create-candidates-section">
            {console.log(jobData)}
            <Container>
                <Box className="create-candidates">
                    <Box className="headingPlusBackBtn">
                        <BackBtn />
                        <h1 className='formHeadings' >Job Details</h1>
                    </Box>
                    <Box className="create-candidates-form-wrapper">
                        <Box className="backgroundStyle">
                            {parse(jobData.description)}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box >
    );
};

export default JobsevaJobDescription;