import "./occupations.scss";
import { Box, Button, Stack, Alert, Tooltip, Chip, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { json, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect } from "react";
import { getRequestWithToken, deleteRequest, patchRequestWithToken } from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import SearchBar from "../../components/searchbar/SearchBar";

const Occupations = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);
  const navigate = useNavigate();

  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");
  const handleToggleChange = () => {
    setShowDeletedRows(!showDeletedRows);
  };

  const handleCompleteProfilesChange = () => {
    setShowCompleteProfiles(!showCompleteProfiles);
  };

  const filteredRows = candidateData.filter(candidate => {
    const isDeletedMatch = showDeletedRows || (candidate.deleted && candidate.deleted.is_deleted === false);
    const isProfileCompleteMatch = showCompleteProfiles || (candidate.is_profile_complete && candidate.is_profile_complete.toLowerCase() === "yes");

    return isDeletedMatch && isProfileCompleteMatch;
  });

  const createNewCandidate = () => {
    navigate('/job-occupations/create');
  }

  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },
    // Full Name
    {
      field: 'occupationNameEn',
      headerName: 'Occupation Name En',
      width: 250,
      // valueGetter: (params) => {
      //   const { first_name = "", middle_name = "", last_name = "" } = params.row.candidate;
      //   const fullName = `${first_name} ${middle_name} ${last_name}`;
      //   return fullName;
      // },
    },
    {
      field: 'occupationNameHn',
      headerName: 'Occupation Name Hn',
      width: 250,

    },
    {
      field: 'occupationNameHi',
      headerName: 'Occupation Name Hi',
      width: 250,

    },


    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {

        return (
          params?.row?.deleted?.is_deleted ?
            < Box style={{ display: "flex", gap: "2rem" }}>
              {canUpdate ? (<EditIcon color="disabled" />) : ""}
              {canDelete ? (<DeleteIcon color="disabled" />) : ""}
            </Box > :
            < Box style={{ display: "flex", gap: "2rem" }}>
              {canRead ? (<Tooltip title="View" >
                <VisibilityIcon sx={{ cursor: "pointer" }} color="primary"
                  onClick={() => {
                    navigate(`/candidate/detail/${params.row._id}`)
                  }}
                />
              </Tooltip>) : ""}

              {canUpdate ? (<Tooltip title="Edit" >
                <EditIcon sx={{ cursor: "pointer" }} color="primary" onClick={() => navigate("/candidate/create", {
                  state: params.row,
                })} />
              </Tooltip>) : ""}

              {canDelete ? (<Tooltip title="Delete" >
                <DeleteIcon sx={{ cursor: "pointer" }} color="error" onClick={() => {
                  setCandidateId(params.row._id)
                  setOpenDeleteModal(true);
                }} />
              </Tooltip>) : ""}

            </Box >
        );
      },

    },

    {
      field: 'isDeleted',
      headerName: 'Deleted',
      width: 100,
      renderCell: (params) => {
        const isDeleted =
          params?.row?.deleted?.is_deleted !== undefined ? params.row.deleted.is_deleted : false;

        return isDeleted && <Chip variant="outlined" label="Deleted" color="error" />;
      },
    },

  ];

  const deleteCandidate = async () => {
    try {
      let response = await deleteRequest(`admin?usertype=job_seeker&&userid=${candidateId}`);

      handleSnackBar(true, response.data.data, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllCandidates();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.message, "error");
      setOpenDeleteModal(false);
    }
  }

  const getAllCandidates = async () => {
    setLoadingDataInGrid(true);
    try {
      let response = await getRequestWithToken(`admin/occupations?${parseInt(pageNumber + 1)}&limit=${pageSize}&search=${searchBarValue}`);
      setCandidateData(response.data.data);
      setRowCount(response.data.totalCount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);

    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }


  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"))
  }


  useEffect(() => {
    getAllCandidates();
    checkPermissionFunc();
    againCheckPermissions();
  }, [pageSize, pageNumber, isIncludesValue, searchBarValue])

  return (
    (isIncludesValue) ?
      <Box className="candidates-section">
        <SearchBar placeholder="Search by name,email, phone number, pincode, jsk, platform source and kyc status"
          setSearchBarValue={setSearchBarValue}
          searchBarValue={searchBarValue}
        />
        <Box className="candidates-top-section" style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}>
          {canCreate ? (<Button variant="contained" color="success" onClick={createNewCandidate}>
            Create Occupations
          </Button>) : ""}

          <Box style={{ display: "flex", gap: "20px" }}>

            <FormGroup>
              <FormControlLabel control={<Switch checked={showDeletedRows} onChange={handleToggleChange} />} label="Include Deleted Users" labelPlacement="start" />
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={<Switch checked={showCompleteProfiles} onChange={handleCompleteProfilesChange} />}
                label="Include InComplete Profiles"
                labelPlacement="start"
              />
            </FormGroup>
          </Box>

        </Box>
        <Box className="candidates-section-data">
          {showLoader ? <ReusableLoader /> : <DataGrid
            // rows={candidateData}
            rows={filteredRows}
            columns={columns}
            getRowId={(candidateData) => candidateData._id}
            paginationMode="server"
            pagination
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            page={pageNumber}
            pageSize={pageSize}
            onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
            onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
            loading={loadingDataInGrid}
            rowCount={rowCount}
          />}
        </Box>

        <SureYouWantToDeletModal
          modalTitle=""
          dialogBody="Are you sure you want to delete this user"
          buttonText="Yes"
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          onYesClick={() => {
            deleteCandidate();
          }}
        />
      </Box> : (<Unauthorized />)
  )
}
export default Occupations;
