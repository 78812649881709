import React, { useContext, useState, useEffect } from "react";
import { Box, Button, FormGroup, FormControlLabel, Switch, Tooltip, Chip } from '@mui/material';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getRequestWithToken, deleteRequest } from "../../utils/ApiRequests";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from '../../utils/userPermissions';
import Unauthorized from "../../components/unauthorized/Unauthorized";
import MaterialTable from "../../components/materialTable/MaterialTable";

const ViewClient = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [showCompleteProfiles, setShowCompleteProfiles] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: []
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [empId, setEmpId] = useState("");

  const handleToggleChange = () => setShowDeletedRows(!showDeletedRows);
  const handleCompleteProfilesChange = () => setShowCompleteProfiles(!showCompleteProfiles);

  const filteredRows = candidateData.filter(candidate => {
    const isDeletedMatch = showDeletedRows || (candidate.deleted && candidate.deleted.is_deleted === false);
    const isProfileCompleteMatch = showCompleteProfiles || (candidate.is_profile_complete && candidate.is_profile_complete.toLowerCase() === "yes");
    return isDeletedMatch && isProfileCompleteMatch;
  });

  const createNewCandidate = () => navigate('/recruiter/client/create');

  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No.',
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + (pageIndex * pageSize)}</span>;
      },
    },
    {
      accessorKey: 'organizationName',
      header: 'Client/Organization name',
      size: 250,
    },

    {
      accessorKey: 'tradeOrBrandName',
      header: 'Trade/Brand Name',
      size: 250,
    },

    {
      accessorKey: 'organizationSectorName',
      header: 'Job sector',
      size: 200,
    },
    {
      accessorKey: 'state',
      header: 'State',
      size: 180,
    },
    {
      accessorKey: 'city',
      header: 'City',
      size: 180,
    },
    {
      accessorKey: 'jobs',
      header: 'Jobs',
      size: 200,
      enableColumnActions: false,
      Cell: ({ row }) => (
        <div>
          <table>
            <thead>
              <tr>
                <th style={{ fontWeight: "400", paddingRight: "10px" }}>Active</th>
                <th style={{ fontWeight: "400", paddingRight: "10px" }}>Total</th>
                <th style={{ fontWeight: "400", paddingRight: "10px" }}>Applicants</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{row.original.totalActiveJobs || "0"}</td>
                <td>{row.original.jobCount || "0"}</td>
                <td>{row.original.candidateCount || "0"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 150,
      enableColumnActions: false,
      Cell: ({ row }) => (
        row.original.deleted?.is_deleted ? (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canUpdate && <EditIcon color="disabled" />}
            {canDelete && <DeleteIcon color="disabled" />}
          </Box>
        ) : (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canRead && (
              <Tooltip title="View">
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() => navigate(`/recruiter/detail/${row.original._id}`)}
                />
              </Tooltip>
            )}
            {canUpdate && (
              <Tooltip title="Edit">
                <EditIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() => navigate("/recruiter/client/create", { state: row.original })}
                />
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Delete">
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  color="error"
                  onClick={() => {
                    setCandidateId(row.original._id);
                    setOpenDeleteModal(true);
                  }}
                />
              </Tooltip>
            )}
          </Box>
        )
      ),
    },
    {
      accessorKey: 'isDeleted',
      header: 'Deleted',
      size: 150,
      enableColumnActions: false,
      Cell: ({ row }) => {
        const isDeleted = row.original.deleted?.is_deleted !== undefined ? row.original.deleted.is_deleted : false;
        return isDeleted && <Chip variant="outlined" label="Deleted" color="error" />;
      },
    },
  ];


  const deleteCandidate = async () => {
    try {
      const response = await deleteRequest(`admin?usertype=client&userid=${candidateId}`);
      handleSnackBar(true, response?.data?.msg, "success");
      setOpenDeleteModal(false);
      getAllClients(empId);
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  }

  const getAllClients = async (empId) => {
    setIsLoading(true);
    try {
      const response = await getRequestWithToken(`admin/?usertype=client&employerid=${empId}&page=${parseInt(pagination.pageIndex + 1)}&limit=${pagination.pageSize}&search=${searchBarValue}`);
      setCandidateData(response.data.data);
      setRowCount(response.data.totalCount);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
    } finally {
      setIsLoading(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Employer";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  }

  useEffect(() => {
    const emplData = JSON.parse(localStorage.getItem("EmpData"));
    setEmpId(emplData?._id);
    if (emplData?._id) {
      getAllClients(emplData?._id);
    }
    checkPermissionFunc();
  }, [pagination.pageIndex, pagination.pageSize, searchBarValue]);

  if (!localStorage.getItem("resourceNames")?.includes("Employer")) {
    return <Unauthorized />;
  }

  return (
    <Box className="candidates-section">
      <Box className="candidates-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        {canCreate && (
          <Button variant="contained" color="success" onClick={createNewCandidate}>
            Add Client
          </Button>
        )}

        <Box style={{ display: "flex", gap: "20px" }}>
          {/* <FormGroup>
            <FormControlLabel
              control={<Switch checked={showDeletedRows} onChange={handleToggleChange} />}
              label="Include Deleted Users"
              labelPlacement="start"
            />
          </FormGroup> */}
          {/* <FormGroup>
            <FormControlLabel
              control={<Switch checked={showCompleteProfiles} onChange={handleCompleteProfilesChange} />}
              label="Include InComplete Profiles"
              labelPlacement="start"
            />
          </FormGroup> */}
        </Box>
      </Box>

      <Box className="candidates-section-data">
        <MaterialTable
          filteredRows={filteredRows}
          columns={columns}
          rowCount={rowCount}
          isLoading={isLoading}
          pagination={pagination}
          columnPinning={columnPinning}
          setPagination={setPagination}
          setColumnPinning={setColumnPinning}
        />
      </Box>

      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this user"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={deleteCandidate}
      />
    </Box>
  );
}

export default ViewClient;