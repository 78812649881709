import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// let data = [
//   {
//     "count": 105,
//     "month": 1,
//     "year": 2023
//   },
//   {
//     "count": 105,
//     "month": 2,
//     "year": 2023
//   },
//   {
//     "count": 105,
//     "month": 3,
//     "year": 2023
//   },
//   {
//     "count": 58,
//     "month": 8,
//     "year": 2023
//   },
//   {
//     "count": 58,
//     "month": 11,
//     "year": 2023
//   },
//   {
//     "count": 164,
//     "month": 9,
//     "year": 2023
//   },
//   {
//     "count": 3,
//     "month": 7,
//     "year": 2023
//   },
//   {
//     "count": 105,
//     "month": 10,
//     "year": 2023
//   },

// ]

const BarChartComponent = ({ data, title, aspect }) => {
  return (
    <div className="bar-chart" style={{ backgroundColor: "#fff", boxShadow: "2px 4px 10px 1px rgba(201, 201, 201, 0.47)", borderRadius: "10px" }}>
      <div className="title" style={{ padding: "16px 25px", fontWeight: "bold", fontSize: "14px", color: "rgb(160, 160, 160)" }}>{title}</div>
      <ResponsiveContainer aspect={aspect}>
        <BarChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis dataKey="month" stroke="gray" /> */}
          <XAxis dataKey={(entry) => `${entry.month}/${entry.year}`} stroke="gray" />
          <XAxis dataKey="month" stroke="gray" />
          <YAxis stroke="gray" />
          <Tooltip />
          <Bar dataKey="count" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
