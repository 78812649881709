import { Box, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRequest, getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import Unauthorized from '../../components/unauthorized/Unauthorized';
import MaterialTable from "../../components/materialTable/MaterialTable";


const ManageRoles = () => {
  const [manageRolesData, setManageRolesData] = useState([]);
  const navigate = useNavigate();
  const { handleSnackBar } = useContext(GlobalContext);
  const [showLoader, setShowLoader] = useState(true);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userid, setUserId] = useState("");
  const pageNumber = 0;
  const pageSize = 10;
  // const [pageNumber, setPageNumber] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: []
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const CreateSubAdmin = () => {
    navigate('/manage-roles/create');
  }

  const columns = [
    {
      accessorKey: 'id',
      header: 'S.No.',
      size: 100,
      enableColumnActions: false,
      Cell: ({ row, table }) => {
        const meta = table.options.meta;
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;

        // Calculate the serial number
        const serialNumber = pageIndex * pageSize + row.index + 1;

        return <span>{serialNumber}</span>;
      },
    },
    {
      accessorKey: 'first_name',
      header: 'Name',
      size: 200,
      Cell: ({ row }) => {
        const { first_name = "", middle_name = "", last_name = "" } = row.original;
        return <span>{`${first_name} ${middle_name} ${last_name}`.trim()}</span>;
      },
    },
    {
      accessorKey: 'email',
      header: 'Email Address',
      size: 300,
    },
    {
      accessorKey: 'access_role',
      header: 'Access Role',
      size: 300,
      Cell: ({ row }) => (
        <div style={{ textTransform: 'capitalize' }}>
          {row.original.access_role}
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 150,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const isDeleted = row.original.deleted?.deleted_at;

        return (
          isDeleted ? (
            <Box style={{ display: "flex", gap: "2rem" }}>
              <EditIcon color="disabled" />
              <DeleteIcon color="disabled" />
            </Box>
          ) : (
            <Box style={{ display: "flex", gap: "2rem" }}>
              <EditIcon
                sx={{ cursor: "pointer" }}
                color="primary"
                onClick={() => navigate('/manage-roles/create', {
                  state: row.original
                })}
              />
              <DeleteIcon
                sx={{ cursor: "pointer" }}
                color="error"
                onClick={() => {
                  setOpenDeleteModal(true);
                  setUserId(row.original._id);
                }}
              />
            </Box>
          )
        );
      },
    },
  ];

  const deleteSubadmin = async () => {
    try {
      let response = await deleteRequest(`admin/admin?admin_id=${userid}`);

      handleSnackBar(true, response?.data?.msg, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllSubadmins();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  }

  const getAllSubadmins = async () => {
    setLoadingDataInGrid(true);
    try {
      let response = await getRequestWithToken(`admin/admin`);
      setRowCount(response.data.data.length);
      setManageRolesData(response.data.data);
      // console.log(response?.data?.data)
      // console.log("ting : " + JSON.stringify(response))
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setLoadingDataInGrid(false);
      setShowLoader(false);
    }

  }
  useEffect(() => {
    getAllSubadmins();
    againCheckPermissions();
  }, [isIncludesValue])

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Manage Roles"))
  }

  return ((isIncludesValue) ?
    <Box className="manage-roles-section">
      <Box className="manage-roles-top-section" style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
        <Button variant="contained" color="success" onClick={CreateSubAdmin}>
          Create Sub Admin
        </Button>
      </Box>

      <Box className="manage-roles-data">
        {showLoader ? <ReusableLoader /> :

          <MaterialTable
            filteredRows={manageRolesData}
            columns={columns}
            rowCount={rowCount}
            isLoading={loadingDataInGrid}
            pagination={pagination}
            columnPinning={columnPinning}
            setPagination={setPagination}
            setColumnPinning={setColumnPinning}
            hideFooter={false}
          />

        }
      </Box>
      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this user"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deleteSubadmin();
        }}
      />
    </Box> : (<Unauthorized />))

}

export default ManageRoles;