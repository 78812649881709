import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import "./partner.scss";
import BackBtn from '../../components/backButton/BackBtn';


const PartnerDetails = () => {
  const { partnerId } = useParams();
  const [partnerData, setpartnerData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);

  const getCandidateDetails = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid?usertype=partner&userid=${partnerId}`);
      setpartnerData(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getCandidateDetails();
  }, [partnerId]);

  useEffect(() => {
    console.log("data response: ", JSON.stringify(partnerData));
  }, [partnerData]);

  return (
    (showLoader) ? <ReusableLoader /> :
      <Box className="create-candidates-section">

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >Partner Details</h1>
            </Box>
            <Box className="create-candidates-form-wrapper">

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeadng '>Institute Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" > Name of Institue</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{partnerData.name_of_institute}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Address</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.address}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Pincode</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.pincode}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">City</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.city}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">State</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.state}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Website of Institute</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.website_of_institute}</Typography>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Institute Type</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.institute_type}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Training Trade</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.courses}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box className="backgroundStyle">
                <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Contact Person Details</Typography>
                <Grid container spacing={2} className='cstmFormGridEmployer'>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">First Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_person_first_name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">Middle Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_person_middle_name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography variant="subtitle1">Last Name</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_person_last_name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Phone Number</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_number}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1">Alternate Contact Number</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }} >{partnerData.alternate_contact_number}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Email Address</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_email_id}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1" >Alternate Email Address</Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.alternate_email_id}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> Availibility Month for jobs </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>
                        {partnerData.month_for_jobs.map((month, index) => (
                          <span key={index}>{month}{index < partnerData.month_for_jobs.length - 1 ? ', ' : ''}</span>
                        ))}
                      </Typography>

                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> Count of Students Per Year </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.number_of_students}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> Would you like to help your Ex-students too? </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.help_ex_students}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Box className="form-control">
                      <Typography varaint="subtitle1"> What is the count of such Ex-students? </Typography>
                      <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.number_of_ex_students}</Typography>
                    </Box>
                  </Grid>

                </Grid>
              </Box>

            </Box>
          </Box>
        </Container>

      </Box >

  );
};

export default PartnerDetails;
