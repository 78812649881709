import "./partner.scss";
import { Box, Button, Grid, Typography, Container, Accordion, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Select ,MenuItem ,FormControl,Autocomplete,TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useContext, useEffect, useState } from "react";
import { getRequestWithToken, postRequest } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { read, utils } from "xlsx";
import { useLocation } from 'react-router-dom';
import BackBtn from '../../components/backButton/BackBtn';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const CandidateUpload = () => {

  const location = useLocation();
  const [partnerData, setPartnerData] = useState([]);
  const [candidateLists, setCandidateLists] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [fileData, setFileData] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(false);

  const [err, setErr] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");

  const columns = [
    {
      field: 'id', headerName: 'S.No.', width: 100, renderCell: (params) => {
        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },

    {
      field: 'full_name',
      headerName: 'Name',
      width: 250,
    },

    {
      field: 'mobile_number',
      headerName: 'Mobile no.',
      width: 200,

    },
    {
      field: 'specialisation',
      headerName: 'Specialisation',
      width: 400,

    },

  ];

  const detailList = [
    {
      id: 0,
      field_name: "full_name",
      desc: "(Mandatory) Enter full name of the candidate",
    },
    {
      id: 2,
      field_name: "mobile_number",
      desc: "(Mandatory) Enter primary whatsapp enabled mobile number of the candidate.",
    },
    {
      id:3,
      field_name:"date_of_birth",
      desc:"(Optional) Enter date of birth of the candidate in format (YYYY-MM-DD)"
    }
  ];

  const getAllPartnersDetails = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid?usertype=partner&userid=${location.state._id}`);
      setPartnerData(response.data.data);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);
    }
  }

  const getAllCandidateLists = async () => {
    try {
      let response = await getRequestWithToken(`admin/candidatelist?partnerId=${location.state._id}&page=${pageNumber+1}&limit=${pageSize}`);
      setCandidateLists(response.data.data);
      setRowCount(response?.data?.totalCount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);
    }
  }

  const [jobSpecialisationList, setJobSpecialisationList] = useState([]);
  const [jobSpecialisationValue,setJobSpecialisationValue] = useState('');
  const [jobSpecialisationError, setJobSpecialisationError] = useState(false);
  
  const getAllCertificationLists = async () => {
    try {
      let response = await getRequestWithToken(`/jobs/all-certificate`);
      let  convertedData = response?.data?.data.filter(item => item.full_form !== "no certificates required" && item.full_form !== "Any certification");
      convertedData = convertedData.map(item => ({
        label: item.full_form,
        id: item._id
      }));
      setJobSpecialisationList(convertedData);

    } catch (error) {
      setJobSpecialisationList([]);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Partner";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)

  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Partner"))
  }

  const handleUpload = async () => {
    if(!jobSpecialisationValue) {
      setJobSpecialisationError("This field is required");
      return;
    }
    if (fileData && fileData.length > 0 && !uploadStatus) {
      setUploadStatus(true);
      let file = fileData[0];
      const reader = new FileReader();
      reader.onload = async (event) => {
        let wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]],{
            dateNF: 'yyyy-mm-dd', // Custom date format
            cellDates: true,
            raw: false
          });
          const stringifiedData = rows
            .filter((entry) => {
              if (entry.full_name 
                && entry.mobile_number 
                && /^\d{10}$/.test(entry.mobile_number.toString())
                && (!entry.date_of_birth || /^\d{4}-\d{2}-\d{2}$/.test(entry.date_of_birth))  
               ) return entry;
              else setErrorFields((prev) => [...prev, entry]);
            })
            .map(
              (entry) => (
                {
              ...entry,
              mobile_number: entry.mobile_number.toString(),
              date_of_birth: entry.date_of_birth,
            }
            ));

          let rowData = {
            partnerId: partnerData?._id,
            data: stringifiedData,
            specialisation: jobSpecialisationValue
          };

          try {
            let response = await postRequest(
              "/admin/candidatecsv",
              rowData
            );
            if (
              response.data.status === "Success" &&
              response?.data?.duplicates?.length > 0
            ) {
              setErr("Few duplicates found in this file.");
              setUploadStatus(false);
            } else if (response.data.status === "Success") {
              setUploadStatus(false);
              setFileData(null);
              setTimeout(() => {
                getAllCandidateLists();
              }, 3000);
              setErr("");
              handleSnackBar(true, "Your file is under process. We will notify you via email once completed.", "success");
              setSuccessMsg(
                "Your file is under process. We will notify you via email once completed."
              );
              setJobSpecialisationValue('')

            }
          } catch (e) {
            console.log("3rd False time : " + uploadStatus);
            setUploadStatus(false);

            setFileData(null);
            let errMsg = e?.response?.data?.msg || e?.message;
            setErr(errMsg);
            console.log(e);
            handleSnackBar(true, errMsg, "error");
          }
        }
      };
      if (file) reader.readAsArrayBuffer(file);
    }
  };


  useEffect(() => {
    getAllPartnersDetails();
    checkPermissionFunc();
    againCheckPermissions();
    getAllCandidateLists();
    getAllCertificationLists();
  }, [pageSize, pageNumber, isIncludesValue, uploadStatus])

  return ((isIncludesValue) ? showLoader ? <ReusableLoader /> :
    <Box className="partner-section"> 
      <Container>

        <Box className="create-candidates">
          <Box className="headingPlusBackBtn">
            <BackBtn />
            <h1 className='formHeadings' id="topEle" >Partner Details</h1>
          </Box>
          <Box className="create-candidates-form-wrapper">

            <Box className="backgroundStyle">
              <Typography variant="h7" component="h3" gutterBottom className='formSubSectionHeading'>Contact Person Details</Typography>
              <Grid container spacing={2} className='cstmFormGridEmployer'>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography variant="subtitle1">First Name</Typography>
                    <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_person_first_name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography variant="subtitle1">Middle Name</Typography>
                    <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_person_middle_name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography variant="subtitle1">Last Name</Typography>
                    <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_person_last_name}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1">Phone Number</Typography>
                    <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_number}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Box className="form-control">
                    <Typography varaint="subtitle1" >Email Address</Typography>
                    <Typography variant="subtitle2" style={{ paddingLeft: "5px" }}>{partnerData.contact_email_id}</Typography>
                  </Box>
                </Grid>

              </Grid>
            </Box>

          </Box>
        </Box>
      </Container>

      <Container> 
        <Box className="backgroundStyle">
          <FormControl  size="small" style={{width:"60%"}}>
            <label htmlFor="specialisation" className="form-label"> Specialisation * </label>
          
            <Autocomplete
                disablePortal
                id="specialisation"
                size="small"
                options={jobSpecialisationList || []} // Ensure options is an array
                sx={{ width: 300, marginTop: "10px" }}
                value={jobSpecialisationValue || null} 
                onChange={(event, newValue) => {
                  setJobSpecialisationValue(newValue?.label);
                  setJobSpecialisationError("");
                }}
              
                renderInput={(params) => <TextField {...params} placeholder="Enter specialisation"/>}
              />
            {jobSpecialisationError && <p className="text-danger" style={{
              margin:"10px 0px 0px",
              color:"#ad3030"
            }}>{jobSpecialisationError}</p>}
          </FormControl>
          <div className="marg15">
            <input
              type="file"
              name="file"
              className="mt-3 input-width"
              id="inputGroupFile"
              value={fileData ? fileData.name : ""}
              required
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                 application/vnd.ms-excel"
              onChange={(event) => {
                setFileData(event.target.files);
                setErr("");
                setSuccessMsg("");
                setErrorFields([])
              }}
            />
          </div>
          
          <Box>
            <Box xs={12} md={9}>
              {canCreate && <Button
                className={` mt-3`}
                variant="contained" color="success"
                type="submit"
                onClick={handleUpload}
                disabled={uploadStatus}
              >
                Upload & Submit &nbsp;
                {uploadStatus && (
                  <ReusableLoader />
                )}
              </Button>}

              <p>**Supported file formats: .xlsx</p>
            </Box>
            {errorFields.length > 0 && (
              <p className="text-danger">{`${errorFields.length} rows are invalid or blank.`}</p>
            )}
            {successMsg && <p className="text-success">{successMsg}</p>}
          </Box>
          {err && <p className="text-danger my-2">{err}</p>}

          <div className="marg15">
            <a
              href="https://jobyahanp.s3.ap-south-1.amazonaws.com/demo.xlsx"
              target="_blank"
            >
              Download sample file
            </a>
          </div>
          
          <Accordion className="marg15">
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Sample File Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table
                responsive
                striped
                bordered
                hover
                variant="light"
                className="mt-5" sx={{ minWidth: 650 }} aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell><strong> Field name</strong></TableCell>
                    <TableCell><strong>Description</strong></TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detailList.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>{data.field_name}</TableCell>
                      <TableCell>{data.desc}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>

        </Box>

      </Container>

      <Container>
        <Box className="partner-data">
          {showLoader ? <ReusableLoader /> : <DataGrid
            rows={candidateLists}
            columns={columns}
            getRowId={(partnerData) => partnerData._id}
            paginationMode="server"
            pagination
            rowsPerPageOptions={[10, 20, 30, 50, 100]}
            page={pageNumber}
            pageSize={pageSize}
            onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
            onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
            loading={loadingDataInGrid}
            rowCount={rowCount}
          />}
        </Box>
      </Container>

    </Box> : (<Unauthorized />)
  )
}

export default CandidateUpload