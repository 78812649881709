import { Box, Button, TextField, Typography } from '@mui/material'
import { Formik, useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { emailValidation } from '../../utils/Validations'
import { postRequestWithoutToken } from '../../utils/ApiRequests'
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider'
import { makeStyles } from '@material-ui/core/styles';
import '../login/login.scss'
import { useNavigate, Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    overflow: "hidden"
  },
  button: {
    backgroundColor: "#ff671f !important"
  },
  loginLink: {
    textDecoration: "none",
    fontSize: "14px"
  }
}));


const ForgotPassword = () => {
  const [loginBtn, setLoginBtn] = useState(false);

  const navigate = useNavigate();

  const classes = useStyles();
  const { handleSnackBar } = useContext(GlobalContext);

  const handleSendEmailForPasswordChange = async (email) => {

    try {
      const data = await postRequestWithoutToken("admin/forgetpassword", {
        email
      });
      const { msg } = data.data.data;
      if (data.data.status === "Success") {
        handleSnackBar(true, msg, "success");
        formik.resetForm();
        // navigate('/');
        setLoginBtn(true)
      }
      else {
        handleSnackBar(true, msg, "error");
      }
    }
    catch (error) {
      handleSnackBar(true, error?.response?.data?.msg, "error");
    }
  }

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: emailValidation,
    onSubmit: (values) => {
      handleSendEmailForPasswordChange(values.email);
    }
  })


  return (
    <Box className={classes.root} >
      <Box sx={{ padding: "10px" }}>
        <Link to="/">
          <img src="https://jobyahanp.s3.ap-south-1.amazonaws.com/images/logo/jobsyahan-logo-new.svg" width={200} alt="JY-Logo" />
        </Link>
      </Box>
      <Box className="login_outer_box">
        <Box className="gradient_border_login" sx={{ boxSizing: "border-box", padding: "20px", borderRadius: "10px", width: { xs: "95%", sm: "80%", md: "40%", lg: "35%" } }} display="flex" justifyContent="center" alignItems="center" flexDirection="column"  >
          <Typography variant="h5" textAlign="center" >
            Forgot Password
          </Typography>
          <form className="w-100" onSubmit={formik.handleSubmit} noValidate>
            <TextField
              id="email"
              sx={{ marginTop: 2 }}
              value={formik.values.email}
              label="Email"
              type="email"
              fullWidth
              required
              placeholder="Please enter your email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              name="email"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
            <Button sx={{ marginTop: 2 }} className={classes.button} type="submit" variant="contained" fullWidth>Submit</Button>
          </form>
          {loginBtn && <Box sx={{ width: "100%", marginTop: 2 }} display="flex" justifyContent="flex-end" >
            <Link to="/" className={classes.loginLink}>Go back to Login</Link>

          </Box>}

        </Box>
      </Box>
    </Box>
  )
}

export default ForgotPassword