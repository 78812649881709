import { Box, Button, FormControl, TextField, Container, Grid, InputLabel, Select, MenuItem, Autocomplete, Checkbox } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { CreateCandidateValidationSchema, CreateJobRolesValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { useContext, useEffect, useRef, useState, } from 'react';
import "./jobRoles.scss";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";



const CreateJobRoles = () => {


  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([])
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);

  const [canCreate, setCanCreate] = useState(true);
  const [canDelete, setCanDelete] = useState(true);
  const [canUpdate, setCanUpdate] = useState(true);
  const [canRead, setCanRead] = useState(true);
  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(true);
  const [occupationData, setOccupationData] = useState('');
  const [selectedOccupationData, setSelectedOccupationData] = useState('');


  const [initialValues, setInitialValues] = useState({
    jobRoleNameEn: "",
    jobRoleNameHi: "",
    jobRoleNameHn: "",
    jobRoleImageUrl: "",
    occupationType: "",
    occupationcoCodenType: "",
    nsqfLevel: null,

  });
  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    console.log(selectedOccupationData._id, "selectedOccupationData")
    // const selectedOccupationData = selectedSectors.map(sector => sector._id);

    let formData = new FormData();
    formData = {
      jobRoleNameEn: values.jobRoleNameEn,
      jobRoleNameHi: values.jobRoleNameHi,
      jobRoleNameHn: values.jobRoleNameHn,
      jobRoleImageUrl: values.jobRoleImageUrl,
      occupationType: selectedOccupationData?._id,
      ncoCode: values.ncoCode,
      nsqfLevel: values.nsqfLevel ? values.nsqfLevel : null,
    }
    try {
      let res = editForm ?
        await patchRequestWithToken('admin/job-roles', { userid: location.state._id, ...formData }) : await postRequest('admin/job-roles', formData);

      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!editForm) {
          resetForm()
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/job-roles');
          }, 1000);

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate('/job-roles');
          }, 1000);

        }

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  }

  const handleAutocompleteChange = (event, value) => {
    setOccupationData(value);
  };

  useEffect(() => {
    getAllOccupations();
  }, [])


  const getAllOccupations = async () => {
    setShowLoader(true);
    try {
      let response = await getRequestWithToken(`admin/occupations`);
      const sectors = response.data.data.map(sector => ({
        occupationNameEn: sector.occupationNameEn,
        _id: sector._id
      }));
      setOccupationData(sectors);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const getCandidateById = async () => {
    try {
      let response = await getRequestWithToken(`admin/userbyid/?usertype=job_seeker&userid=${location.state?._id}`);
      setDataById(response.data.data);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Candidate";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    // console.log("hello")
    const checkAccesInArray = localStorage.getItem("resourceNames");
    // console.log("resourceNames : " + checkAccesInArray);
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Candidate"))
  }


  useEffect(() => {

    checkPermissionFunc();
    againCheckPermissions();

    if (location.state?._id) {
      getCandidateById()
    }
    else {
      setShowLoader(false);
    }

    if (location.state?._id) {
      const { candidate = {}, deleted = {} } = dataById;
      const {
        jobRoleNameEn = "",
        jobRoleNameHi = "",
        jobRoleNameHn = "",
        jobRoleImageUrl = "",
        occupationType = "",
        occupationcoCodenType = "",
        nsqfLevel = "",

      } = candidate;

      setInitialValues({
        jobRoleNameEn,
        jobRoleNameHi,
        jobRoleNameHn,
        jobRoleImageUrl,
        occupationType,
        occupationcoCodenType,
        nsqfLevel,


      })

      setEditForm(true);
    } else {

      setInitialValues({
        jobRoleNameEn: "",
        jobRoleNameHi: "",
        jobRoleNameHn: "",
        jobRoleImageUrl: "",
        occupationType: "",
        occupationcoCodenType: "",
        nsqfLevel: null,

      });
      setEditForm(false);
    }
    ;
  }, [location.state, setInitialValues, dataById._id, isIncludesValue])


  const top100Films = [
    { title: 'Sector 1', year: 1994 },
    { title: 'Sector 2', year: 1972 },
    { title: 'Sector 3', year: 1974 },
    { title: 'Sector 4', year: 2008 },
    { title: 'Sector 5', year: 1957 },
    { title: "Sector 6", year: 1993 },
    { title: 'Sector 7', year: 1994 },
  ];

  return (

    (isIncludesValue) ? <>
      {showLoader ? <ReusableLoader /> : <Box className="create-candidates-section" ref={scrollTargetRef}>

        <Container>

          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >{editForm ? "Update Job Roles" : "Create Job Roles"}</h1>

            </Box>
            <Box className="create-candidates-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CreateJobRolesValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-candidates-form">

                    <Box className="backgroundStyle">
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="jobRoleNameEn"> Job Role Name English<span className='must-fill-fields'>*</span></label>
                            <Field id="jobRoleNameEn"
                              type="text" placeholder="Occupation Name English"
                              name="jobRoleNameEn"
                              className="form-input" size="small"
                            />
                            {errors.jobRoleNameEn && <Box className="form-error">{errors.jobRoleNameEn}</Box>}

                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jobRoleNameHn">Job Role Name Hinglish<span className='must-fill-fields'>*</span></label>
                            <Field id="jobRoleNameHn"
                              type="text" placeholder="Occupation Name Hinglish"
                              name="jobRoleNameHn"
                              className="form-input" />
                            {errors.jobRoleNameHn && <Box className="form-error">{errors.jobRoleNameHn}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jobRoleNameHi">Job Role Name Hindi <span className='must-fill-fields'>*</span></label>
                            <Field id="jobRoleNameHi"
                              type="text" placeholder="Occupation Name Hindi "
                              name="jobRoleNameHi"
                              className="form-input" />
                            {errors.jobRoleNameHi && <Box className="form-error">{errors.jobRoleNameHi}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jobRoleImageUrl">Job Role Image Url</label>
                            <Field id="jobRoleImageUrl"
                              type="text" placeholder="Paste Image URL here"
                              name="jobRoleImageUrl"
                              className="form-input" />
                            {errors.jobRoleImageUrl && <Box className="form-error">{errors.jobRoleImageUrl}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="ncoCode">NCO Code</label>
                            <Field id="ncoCode"
                              type="text" placeholder="Nco Id"
                              name="ncoCode"
                              className="form-input" />
                            {errors.ncoCode && <Box className="form-error">{errors.ncoCode}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} >
                          <Box className="form-control">
                            <label className="form-label" htmlFor="nsqfLevel">NSQF Level </label>
                            <Field id="nsqfLevel"
                              type="number" placeholder="nsqf Level"
                              name="nsqfLevel"
                              className="form-input" />
                            {errors.nsqfLevel && <Box className="form-error">{errors.nsqfLevel}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="occupationType">Occupations</label>

                            <Autocomplete
                              className='hello'
                              disablePortal
                              id="combo-box-demo2"
                              options={occupationData}
                              getOptionLabel={(option) => option.occupationNameEn}
                              sx={{ width: 300 }}
                              renderInput={(params) => <TextField className='hello2' {...params} label="Select Occupation" />}
                              onChange={(event, value) => { setSelectedOccupationData(value); }}
                            />
                            {errors.occupationType && <Box className="form-error">{errors.occupationType}</Box>}
                          </Box>

                        </Grid>


                      </Grid>
                    </Box>

                    <Box className="form-button-section">

                      <Link to='/candidate'>
                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                          }}
                          color="error"
                        >Cancel</Button>
                      </Link>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}

                      >{editForm ? "Update" : "Create"}</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>


      </Box >}
    </> : (<Unauthorized />)

  )

}
export default CreateJobRoles;