import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.request.use(async (config) => {
  // console.log(process.env.REACT_APP_API_BASE_URL, " base url");
  let token = localStorage.getItem("token");
  config.headers = {
    'Access-Control-Allow-Origin': "*",
    'authorization': 'Bearer ' + token
  }

  return config;
}, async (error) => {
  return Promise.reject(error);
});


axiosInstance.interceptors.response.use(async (response) => {
  return response;
}, async (error) => {
  throw error;
});


export { axiosInstance };