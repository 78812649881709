import "./partner.scss";
import { Box, Button, Tooltip, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteRequest, getRequestWithToken } from "../../utils/ApiRequests";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { checkPermission } from "../../utils/userPermissions";
import Unauthorized from "../../components/unauthorized/Unauthorized";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const Partner = () => {
  // const [userData, setUserData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(null);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);

  const [showDeletedRows, setShowDeletedRows] = useState(true);
  const [searchBarValue, setSearchBarValue] = useState("");

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const [columnPinning, setColumnPinning] = useState({
    left: [],
    right: [],
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const filteredRows = showDeletedRows
    ? partnerData
    : partnerData.filter(
        (partner) =>
          partner.hasOwnProperty("deleted") &&
          partner.deleted.is_deleted === false
      );

  const navigate = useNavigate();

  const createPartner = () => {
    navigate("/partner/create");
  };

  const columns = [
    {
      accessorKey: "id",
      header: "S.No.",
      size: 100,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: ({ row, table }) => {
        const pageIndex = table.getState().pagination.pageIndex;
        const pageSize = table.getState().pagination.pageSize;
        return <span>{row.index + 1 + pageIndex * pageSize}</span>;
      },
    },
    {
      accessorKey: "name_of_institute",
      header: "Institute Name",
      size: 250,
      id: "institutename",
    },
    {
      accessorKey: "institute_type",
      header: "Institute Type",
      size: 250,
      id: "institutetype",
    },
    {
      accessorKey: "contact_email_id",
      header: "Email",
      size: 300,
      id: "email",
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <span>
          {new Date(row.original.createdAt).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          })}
        </span>
      ),
    },
    {
      accessorKey: "last_login",
      header: "Last Login",
      size: 200,
      enableColumnFilter: false,
      Cell: ({ row }) => (
        <span>
          {row.original.last_login
            ? new Date(row.original.last_login).toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
              })
            : "--"}
        </span>
      ),
    },
    {
      accessorKey: "city",
      header: "City",
      size: 150,
      enableColumnFilter: false,
    },
    {
      accessorKey: "state",
      header: "State",
      size: 150,
      enableColumnFilter: false,
    },
    {
      accessorKey: "pincode",
      header: "Pincode",
      size: 180,
    },
    {
      accessorKey: "upload_candidate",
      header: "No. of candidate uploaded",
      size: 200,
      enableColumnFilter: false,
    },
    {
      accessorKey: "registered_user",
      header: "Candidate registered JY",
      size: 200,
      enableColumnFilter: false,
    },
    {
      accessorKey: "candidate",
      header: "Candidate",
      size: 150,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const openCandidate = () => {
          const url = `/partner/${row.original._id}/view-candidates`;
          window.open(url, "_blank");
        };
        return (
          <span
            style={{ textDecoration: "underline", color: "blue" }}
            onClick={openCandidate}
          >
            view candidate
          </span>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 220,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ row }) =>
        row.original.deleted?.is_deleted ? (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canUpdate && <EditIcon color="disabled" />}
            {canDelete && <DeleteIcon color="disabled" />}
          </Box>
        ) : (
          <Box style={{ display: "flex", gap: "2rem" }}>
            {canRead && (
              <Tooltip title="View">
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() =>
                    navigate(`/partner/detail/${row.original._id}`)
                  }
                />
              </Tooltip>
            )}
            {canUpdate && (
              <Tooltip title="Edit">
                <EditIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() =>
                    navigate("/partner/create", { state: row.original })
                  }
                />
              </Tooltip>
            )}
            {(canCreate || canUpdate) && (
              <Tooltip title="Candidate Upload">
                <FileUploadIcon
                  sx={{ cursor: "pointer" }}
                  color="primary"
                  onClick={() =>
                    navigate("/partner/candidate-upload", {
                      state: row.original,
                    })
                  }
                />
              </Tooltip>
            )}
            {canDelete && (
              <Tooltip title="Delete">
                <DeleteIcon
                  sx={{ cursor: "pointer" }}
                  color="error"
                  onClick={() => {
                    setCandidateId(row.original._id);
                    setOpenDeleteModal(true);
                  }}
                />
              </Tooltip>
            )}
          </Box>
        ),
    },
    {
      accessorKey: "isDeleted",
      header: "Deleted",
      size: 150,
      enableColumnFilter: false,
      enableColumnActions: false,
      Cell: ({ row }) => {
        return (
          row.original.deleted?.is_deleted && (
            <Chip variant="outlined" label="Deleted" color="error" />
          )
        );
      },
    },
  ];

  const deletePartner = async () => {
    try {
      let response = await deleteRequest(
        `admin?usertype=partner&&userid=${candidateId}`
      );
      handleSnackBar(true, response.data.msg, "success");
      setOpenDeleteModal(false);
      setShowLoader(true);
      getAllPartners();
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setOpenDeleteModal(false);
    }
  };

  const getAllPartners = async () => {
    setLoadingDataInGrid(true);
    try {
      let response = await getRequestWithToken(
        `admin?usertype=partner&page=${parseInt(
          pagination.pageIndex + 1
        )}&limit=${pagination.pageSize}&search=${searchBarValue}`
      );
      setPartnerData(response.data.data);
      setRowCount(response.data.totalCount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(
        true,
        error?.response?.data?.msg || error?.message,
        "error"
      );
      setShowLoader(false);
      setLoadingDataInGrid(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("fetchData", canRead);
      if (!partnerData.length) {
        setLoadingDataInGrid(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const filterParams = {
          // global: globalFilter,
          ...Object.fromEntries(
            columnFilters.map((filter) => [filter.id, filter.value])
          ),
        };

        const filterQueryString = new URLSearchParams(filterParams).toString();

        const response = await getRequestWithToken(
          `admin?usertype=partner&page=${pagination.pageIndex + 1}&limit=${
            pagination.pageSize
          }&${filterQueryString}`
        );
        setPartnerData(response?.data?.data);
        setRowCount(response?.data?.totalCount);
        setIsError(false);
      } catch (error) {
        setIsError(true);
        handleSnackBar(
          true,
          error?.response?.data?.message || error?.message,
          "error"
        );
      }
      setLoadingDataInGrid(false);
      setIsRefetching(false);
    };
    fetchData();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Partner";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate);
    setCanDelete(permissions.canDelete);
    setCanUpdate(permissions.canUpdate);
    setCanRead(permissions.canRead);
  };

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  };

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Partner"));
  };

  useEffect(() => {
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue, searchBarValue]);

  const table = useMaterialReactTable({
    columns,
    data: partnerData,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      color: "secondary",
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading: loadingDataInGrid,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderBottomToolbarCustomActions: ({ table }) => {
      const { pageIndex, pageSize } = table.getState().pagination;
      const totalPages = Math.ceil(rowCount / pageSize);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Page {pageIndex + 1} of {totalPages}
          </Typography>
        </Box>
      );
    },
    renderTopToolbarCustomActions: ({ table }) => {
      const pinnedColumns = table.getState().columnPinning;
      return (
        <Box>
          <Chip
            label={`Left Pinned: ${pinnedColumns.left.length}`}
            color="primary"
            sx={{ mr: 1 }}
          />
          <Chip
            label={`Right Pinned: ${pinnedColumns.right.length}`}
            color="secondary"
          />
        </Box>
      );
    },
  });

  return isIncludesValue ? (
    <Box className="partner-section">
      <Box
        className="partner-top-section"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {canCreate ? (
          <Button variant="contained" color="success" onClick={createPartner}>
            Create Partner
          </Button>
        ) : (
          "ddddddddddd"
        )}

        {/* <FormGroup>
          <FormControlLabel control={<Switch checked={showDeletedRows} onChange={handleToggleChange} />} label="Include Deleted Users" labelPlacement="start" />
        </FormGroup> */}
      </Box>

      <Box className="partner-data">
        {loadingDataInGrid ? (
          <ReusableLoader />
        ) : (
          <MaterialReactTable table={table} />
        )}
      </Box>

      <SureYouWantToDeletModal
        modalTitle=""
        dialogBody="Are you sure you want to delete this user"
        buttonText="Yes"
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        onYesClick={() => {
          deletePartner();
        }}
      />
    </Box>
  ) : (
    <Unauthorized />
  );
};
export default Partner;
