import "./createnewusers.scss";
import { Container, Button, Box } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import { userValidationSchema } from "../../utils/Validations";
import { useNavigate } from "react-router-dom";
const CreateNewUsers = () => {
    const initialValues = {
        full_name: '',
        user_type: '',
        email_address: '',
        password: ''
    }
    const navigate = useNavigate();
    const handleSubmit = async (values, { resetForm }) => {
        if (values.button_action === "cancel_submission") {
            resetForm();
        }

        if (values.button_action === "submit_data") {
            console.log(values)
            navigate('/manage-roles', { state: { form_submitted: true } });

        }

    }
    return (
        <Box className="create-new-users-section">

            <Container maxWidth="sm" className="create-new-users-form-section">
                <h1>Create New Users</h1>
                <Formik
                    initialValues={initialValues}
                    validationSchema={userValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, setFieldValue }) => (
                        <Form className="create-user-form">

                            <Box className="form-control">
                                <label className="form-label" htmlFor="full_name"> Name</label>
                                <Field id="full_name"
                                    type="text" placeholder="Enter Full Name"
                                    name="full_name"
                                    className="form-input" />
                                {errors.full_name && <Box className="form-error">{errors.full_name}</Box>}
                            </Box>

                            <Box className="form-control">
                                <label className="form-label" htmlFor="user_type"> User Type</label>
                                <Field id="user_type"
                                    type="text" placeholder="Enter User Type"
                                    name="user_type"
                                    className="form-input" />
                                {errors.user_type && <Box className="form-error">{errors.user_type}</Box>}
                            </Box>

                            <Box className="form-control">
                                <label className="form-label" htmlFor="email_address"> Email Address</label>
                                <Field id="email_address"
                                    type="text" placeholder="Enter Email Address"
                                    name="email_address"
                                    className="form-input" />
                                {errors.email_address && <Box className="form-error">{errors.email_address}</Box>}
                            </Box>

                            <Box className="form-control">
                                <label className="form-label" htmlFor="password"> Password</label>
                                <Field id="password"
                                    type="password" placeholder="Enter Password"
                                    name="password"
                                    className="form-input" />
                                {errors.password && <Box className="form-error">{errors.password}</Box>}
                            </Box>

                            <Box className="form-button-section">
                                <Button className="form-button"
                                    variant="contained"
                                    type="submit"
                                    color="success"
                                    onClick={() => setFieldValue("button_action", "submit_data")}>Submit</Button>
                                <Button
                                    className="form-button"
                                    variant="contained"
                                    type="submit"
                                    color="error"
                                    onClick={() => setFieldValue("button_action", "cancel_submission")}>Cancel</Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Container>

        </Box>
    )
}
export default CreateNewUsers;