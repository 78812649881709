import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../sidebar/Sidebar"
import Navbar from "../navbar/Navbar"
import { Paper, Grid, Button, Box } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import "./particularjob.scss"

const token = localStorage.getItem('token');
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json'
};
export default function Particularjob() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState({});
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const getJobById = async () => {
    await axios.post(`https://13.235.183.204:3001/api/job/getjobbyid?jobid=${id}`)
      // let data = await axios.get('http:s//13.235.183.204:3001/api/admin/getalljobs')
      .then((response) => {
        console.log(response.data.data);
        setJob(response.data.data)
        toast.success('Job Fetched successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        toast.error("No Jobs Found", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      });
  }
  useEffect(() => {
    getJobById()
  }, []);
  const approveJob = async (e) => {
    e.preventDefault();

    if (!value.trim()) {
      setError(true);
      toast.error("Please Add Remark ", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } else {

      await axios.post(`http:s//13.235.183.204:3001/api/admin/approvejobs`, { jobapprovalid: id, status: "Approved", reason: value }, { headers })
        // let data = await axios.get('http:s//13.235.183.204:3001/api/admin/getalljobs')
        .then((response) => {
          toast.success(response.data.data, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
          setTimeout(() => {
            navigate('/jobapprove')
          }, 1000)
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        });
    }
  };


  const rejectJob = async (e) => {
    e.preventDefault();

    if (!value.trim()) {
      setError(true);
      toast.error("Please Add Remark ", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    } else {
      await axios.post(`http:s//13.235.183.204:3001/api/admin/approvejobs`, { jobapprovalid: id, status: "Pending", reason: value }, { headers })
        // let data = await axios.get('http:s//13.235.183.204:3001/api/admin/getalljobs')
        .then((response) => {
          toast.success("Job Rejected SuccessFully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
          setTimeout(() => {
            navigate('/jobapprove')
          }, 1000)
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        });
    };
  };

  const handleChange = (event) => {
    console.log(event.target.value)
    setValue(event.target.value);
  };

  const handleBlur = () => {
    setError(validateName(value));
  };

  const validateName = (value) => {
    if (!value.trim()) {
      return 'Remark is required';
    }
    return '';
  };


  return (
    <>
      <div className='allcomponent'>
        <div className="home">
          <Sidebar />
          <div className="homeContainer">
            <Navbar />
            <IconButton
              className="back-button"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <div className='form'>
              <Paper elevation={0} className='formdata' sx={{ p: 3, border: 1 }}>
                <Grid container spacing={2}>
                  {Object.entries(job).map(([key, value]) => (
                    <Grid className='textbox' item xs={4} key={key}>
                      {typeof value === "object" ? (
                        <Grid container spacing={3}>
                          {Object.entries(value).map(([nestedKey, nestedValue]) => (
                            <Grid item xs={4} key={nestedKey}>
                              <TextField className="no-underline"
                                disabled={job.approve_jobs === "Approved"}
                                name={key}
                                label={key}
                                value={nestedValue}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <TextField
                          disabled={job.approve_jobs === "Approved"}
                          name={key}
                          label={key}
                          value={value}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
                <Box sx={{ width: "100%", border: 2 }}>
                  <TextField className="remark"
                    label="Remark"
                    placeholder="Remark"
                    required
                    multiline
                    disabled={job.approve_jobs === "Approved"}
                    rows={4}
                    maxRows={9}
                    onBlur={handleBlur}
                    error={job.approve_jobs === "Approved" ? false : Boolean(error)}
                    helperText={error}
                    value={value}
                    onChange={handleChange}
                  />


                  <div className='border' >
                    <Button className='button' disabled={job.approve_jobs === "Approved"} variant="contained" color="primary" type="submit" onClick={approveJob} >
                      Approve
                    </Button>
                    <Button className='button' disabled={job.approve_jobs === "Approved"} variant="outlined" color="secondary" type="submit" onClick={rejectJob}>
                      Reject
                    </Button>
                  </div>
                </Box>
              </Paper>

            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>

  );
};   