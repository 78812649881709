import { Box , Button, TextField, Typography} from '@mui/material'
import { Formik } from 'formik'
import React, { useContext } from 'react'
import { currentPasswordNewPasswordValidation } from '../../utils/Validations'
import { postRequest, postRequestWithoutToken } from '../../utils/ApiRequests'
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider'



const ChangePasswordPage = () => {

    const {handleSnackBar} = useContext(GlobalContext);

    const handleChangePassword =async (values)=>{
//         currentPassword
// : 
// "soempassword"
// newPassword
// : 
// "1234NewPass%%"
        const {currentPassword, newPassword} = values;
        console.log(values);
        try{
            const data = await postRequest("admin/changePassword", {
                existingpassword:currentPassword,
                newPassword
            });
            console.log(data);
        }catch(error){
            handleSnackBar(true, error?.response?.data?.msg, "error");
        }
    }

    const initialValues = {
        currentPassword:"",
        newPassword:""
    }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className="w-100 h-100vh" >
        <Box sx={{border:"2px solid #42a5f5" , boxSizing:"border-box", padding:"20px", borderRadius:"10px", width:{xs:"100%",sm:"80%", md:"40%", lg:"35%"}}} display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
       <Typography variant="h5" textAlign="center" >
        Enter your Current and New Password
        </Typography>
        <Formik validationSchema={currentPasswordNewPasswordValidation} initialValues={initialValues} onSubmit={(values)=>{
            console.log("handling submitted", values);
            handleChangePassword(values);
        }} >
         {({values, errors, handleChange, handleBlur, touched, handleSubmit})=>{
        return <form className="w-100" onSubmit={handleSubmit} noValidate>
        <TextField
          id="currentPassword"
          sx={{marginTop:3}}
          value={values.currentPassword}
          label="Current Password"
          type="password"
          fullWidth
          required
          placeholder="Please enter your current password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.currentPassword&&Boolean(errors.currentPassword)}
          helperText={touched.currentPassword&& errors.currentPassword}
          name="currentPassword"
          size="small"
          InputLabelProps={{shrink:true}}
        />
        <TextField
          id="newPassword"
          sx={{marginTop:2}}
          value={values.newPassword}
          label="New Password"
          type="password"
          fullWidth
          required
          placeholder="Please enter your new password"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.newPassword&&Boolean(errors.newPassword)}
          helperText={touched.newPassword&& errors.newPassword}
          name="newPassword"
          size="small"
          InputLabelProps={{shrink:true}}
        />
        
        <Button sx={{marginTop:2}} type="submit" variant="contained" fullWidth>Submit</Button>
    </form>
         }}   

        </Formik>
        
        </Box>
    </Box>
  )
}

export default ChangePasswordPage