import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Box,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { deleteRequest, getRequestWithToken, patchRequestWithToken, postRequest } from "../../utils/ApiRequests";
import "./jobcategory.scss";
import { blueGrey } from "@mui/material/colors";
import AddCategoryModal from "../../components/categorymodal/Modal";
import AddIcon from "@mui/icons-material/Add";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { Formik } from "formik";
import { validationSchemaForDepartment, validationSchemaForJobSector } from "../../utils/Validations";
import SureYouWantToDeletModal from "../../components/SureYouWantToDeleteModal/SureYouWantToDeletModal";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[50]),
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: blueGrey[50],
  },
}));

const Home = () => {
  const [sector, setSector] = useState([]);
  const [department, setDepartment] = useState([]);
  const [role, setRole] = useState([]);
  const [initialStateForJobSector, setInitialStateForJobSector] = useState({
    jobSector: ""
  })
  const [initialStateForDepartment, setInitialStateForDepartment] = useState({
    department: ""
  })
  const [editJobSectorId, setEditJobSectorId] = useState("");

  const { handleSnackBar } = useContext(GlobalContext);

  const [selectedSector, setSelectedSector] = useState("");
  const [selectedSectorId, setSelectedSectorId] = useState("");

  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [loadingDepartment, setLoadingDepartment] = useState(false);
  const [loadingRole, setLoadingRole] = useState(false);

  const [addModal, setAddModal] = useState(false);
  const [label, setlabel] = useState("");

  const [openSectorModal, setOpenSectorModal] = useState(false);
  const [openDepartmentModal, setOpenDepartmentModal] = useState("");
  const [openDeleteJobSectorModal, setOpenDeleteJobSectorModal] = useState(false);
  const [departmentId, setDepartmentId] = useState("");
  const [openDeleteModalOfDepartment, setOpenDeleteModalOfDepartment] = useState(false);
  const [showLoaderForSector, setShowLoaderForSector] = useState(false);
  const [showLoaderForDepartement, setShowLoaderForDepartement] = useState(false);
  // permissions
  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);


  const getSector = async () => {
    setShowLoaderForSector(true)
    try {
      let data = await getRequestWithToken("admin/jobSector");
      if (data.data.status === "Success") {
        setSector([...data.data.data]);
        setShowLoaderForSector(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setShowLoaderForSector(false);
    }
  };


  const checkPermissionFunc = () => {
    const resourceToUpdate = "Job Category";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)


  }

  useEffect(() => {
    getSector();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue]);


  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  useEffect(() => {
    if (selectedSectorId) {
      setSelectedDepartment("");
      setRole([]);
      getDepartment(selectedSectorId);
    }
  }, [selectedSector]);

  // useEffect(() => {
  //   if (selectedDepartment) {
  //     getRole(selectedDepartment);
  //   }
  // }, [selectedDepartment]);

  const getDepartment = async (jobsector) => {
    setShowLoaderForDepartement(true);
    try {
      let data = await getRequestWithToken(
        `admin/jobdepartment?id=${jobsector}`
      );
      if (data.data.status === "Success") {
        setDepartment([...data.data.data]);
        console.log(data.data.data, " this is data");
        console.log(department, " this is data2");
      } else {
        handleSnackBar(true, data.data.msg, "error");
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");

    }
    setShowLoaderForDepartement(false);
  };

  const handleJobSectorSubmit = async (values) => {

    const payload = editJobSectorId ? {
      id: editJobSectorId,
      jobsectorname: values.jobSector,
    } : {
      jobrolename: values.jobSector,
    }

    console.log(payload, " this is payload");

    try {
      const response = editJobSectorId ? await patchRequestWithToken("admin/jobSector", { ...payload }) : await postRequest("admin/jobSector", payload);
      const { status = "", msg = "" } = response.data;
      if (status === "Success") {
        await getSector()
        handleSnackBar(true, msg, "success");
        handleCloseJobSectorModal();
      }
      else
        handleSnackBar(true, msg, "error")
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
    }
  }

  const handleCloseJobSectorModal = () => {
    setEditJobSectorId("");
    setInitialStateForJobSector({ jobSector: "" });
    setOpenSectorModal(false);
  }

  const handleDeleteJobSector = async () => {
    try {
      const response = await deleteRequest(`admin/jobSector/?id=${editJobSectorId}`);
      const { status, msg } = response.data;
      console.log(response.data);

      if (status === "Success") {
        await getSector();
        handleCloseJobSectorModal();
        setOpenDeleteJobSectorModal(false);
        setEditJobSectorId("");
        handleSnackBar(true, msg, "success");
      } else {
        handleSnackBar(true, msg, "error");
      }

    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg, "error");
    }
  }

  const handleDeleteDepartment = async () => {
    try {
      const response = await deleteRequest(`admin/jobdepartment?id=${departmentId}`, { id: departmentId });
      const { status = "", msg = "" } = response.data;
      if (status === "Success") {
        await getDepartment(selectedSectorId);
        handleSnackBar(true, msg, "success");
        setOpenDeleteModalOfDepartment(false);
        setDepartmentId("");
      } else {
        handleSnackBar(true, msg, "error");
        // setShowLoaderForDepartement(false);
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.msg, "error");
      setShowLoaderForDepartement(false)
    }
  }

  const handleDepartmentSubmit = async (values) => {
    const payload = departmentId ? {
      id: departmentId,
      jobcategoryname: values.department,

    } : {
      // jobrolename: selectedSector,
      id: selectedSectorId,
      jobcategoryname: values.department,
    }
    try {
      const response = departmentId ? await patchRequestWithToken("admin/jobdepartment", payload) : await postRequest("admin/jobdepartment", payload);
      console.log(response, " this one is response");
      const { status, msg } = response.data;
      if (status === "Success") {
        await getDepartment(selectedSectorId);
        handleSnackBar(true, msg, "success");
        handleCloseDepartmentModal();
      } else {
        handleSnackBar(true, msg, "error")
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg, "error")
    }

  }



  const handleCloseDepartmentModal = () => {
    setInitialStateForDepartment({ department: "" });
    setDepartmentId("");
    setOpenDepartmentModal(false);
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Job Category"))
  }

  return (
    (isIncludesValue) ? (<Box marginX={2} marginY={2} className="job_sector">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card variant="outlined" sx={{ p: 1 }}>
            <h3>Job Sector</h3>
            {canCreate ? (<ColorButton
              variant="contained"
              onClick={() => {
                setEditJobSectorId("");
                setInitialStateForJobSector({ jobSector: "" })
                setOpenSectorModal(true)
              }
              }
              sx={{ width: "98%" }}
            >
              <Box display="flex" justifyContent="center" alignItems="center" >
                <AddIcon fontSize="small" />
                <Typography fontSize="14px" fontWeight="600" >Add Job Sector</Typography>
              </Box>
            </ColorButton>) : ""}

            <CardContent
              sx={{ height: "70vh", overflow: "scroll", width: "100%", px: 0 }}
            >
              {
                showLoaderForSector ? <Box display="flex" sx={{ width: "100%", height: "100%" }} justifyContent="center" alignItems="center" > <CircularProgress /> </Box> : sector.map((item, index) => (
                  <Paper
                    sx={{ my: 0.8 }}
                    fullWidth
                    key={index}
                    onClick={() => {
                      setSelectedSector(item.jobRoleName);
                      setSelectedSectorId(item._id);
                      console.log("sector id is : ", item._id);
                    }}
                    className="cursor-pointer"
                  >
                    <Box padding={2} >
                      <Grid container>
                        <Grid item xs={8}>
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {item.jobRoleName}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} >
                          <Box display="flex" sx={{ width: "100%" }} justifyContent="center">
                            {canUpdate ? (<Box>
                              <EditIcon
                                onClick={() => {
                                  setInitialStateForJobSector({
                                    ...initialStateForJobSector,
                                    jobSector: item.jobRoleName
                                  })
                                  setOpenSectorModal(true);
                                  setEditJobSectorId(item._id);
                                }}
                                color="primary" />
                            </Box>) : ""}

                            {canDelete ? (<Box marginLeft={1}>
                              <DeleteIcon onClick={() => {
                                setInitialStateForJobSector({
                                  ...initialStateForJobSector,
                                  jobSector: item.jobRoleName
                                });
                                setEditJobSectorId(item._id);
                                setOpenDeleteJobSectorModal(true);
                              }} color="error" />
                            </Box>) : ""}

                          </Box>
                        </Grid>
                        <Box>

                        </Box>
                        <Box>

                        </Box>
                      </Grid >
                    </Box >
                  </Paper >
                ))
              }

            </CardContent >
          </Card >
        </Grid >

        <Grid item xs={6} >
          <Card variant="outlined" sx={{ p: 1 }}>
            <h3>Job Department</h3>
            <h4>
              {selectedSector
                ? `Job Sector => ${selectedSector}`
                : "Select Sector"}
            </h4>
            {selectedSector && (

              <ColorButton
                variant="contained"
                onClick={() => {
                  setDepartmentId("");
                  setInitialStateForDepartment({ department: "" })
                  setOpenDepartmentModal(true);
                }}
                sx={{ width: "98%" }}
              >
                {canCreate ? (<Box display="flex" justifyContent="center" alignItems="center" >
                  <AddIcon fontSize="small" />
                  <Typography fontSize="14px" fontWeight="600" >Add Job Department</Typography>
                </Box>) : ""}

              </ColorButton>
            )}
            <CardContent
              sx={{ height: "70vh", overflow: "scroll", width: "100%", px: 0 }}
            >
              {showLoaderForDepartement ? <Box display="flex" sx={{ width: "100%", height: "100%" }} justifyContent="center" alignItems="center" > <CircularProgress /> </Box> : department.map((item, index) => (
                <Paper
                  sx={{ my: 0.8 }}
                  fullWidth
                  key={index}
                  // onClick={() => setSelectedSector(item.jobRoleName)}
                  className="cursor-pointer"
                >
                  <Box padding={2} >
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {item.key}
                          {/* {item.jobRoleName} */}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} >
                        <Box display="flex" sx={{ width: "100%" }} justifyContent="center">
                          {canUpdate ? (<Box>
                            <EditIcon
                              onClick={() => {
                                setInitialStateForDepartment({
                                  ...initialStateForDepartment,
                                  department: item.key
                                })
                                setOpenDepartmentModal(true);
                                setDepartmentId(item._id);
                              }}
                              color="primary"
                            />
                          </Box>) : ""}

                          {canDelete ? (<Box marginLeft={1}>
                            <DeleteIcon
                              onClick={() => {
                                setInitialStateForDepartment({
                                  ...initialStateForDepartment,
                                  department: item.key
                                });
                                setDepartmentId(item._id);
                                setOpenDeleteModalOfDepartment(true);
                              }}
                              color="error"
                            />
                          </Box>) : ""}

                        </Box>
                      </Grid>
                      <Box>

                      </Box>
                      <Box>

                      </Box>
                    </Grid>
                  </Box>
                </Paper>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid >

      < ModalComponent
        openModal={openSectorModal}
        setOpenModal={setOpenSectorModal}
        modalTitle={editJobSectorId ? "Update New Job Sector" : "Create New Job Sector"}
        modalWidth="35%"
        handleOnCloseBtnClick={() => {
          handleCloseJobSectorModal();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialStateForJobSector}
          validationSchema={validationSchemaForJobSector}
          onSubmit={(values) => {
            setShowLoaderForSector(true);
            handleJobSectorSubmit(values);
          }}
        >
          {({ errors, values, handleBlur, handleChange, handleSubmit }) => {

            return <form onSubmit={handleSubmit} noValidate>
              <Box paddingX={2} paddingY={2} >
                <TextField
                  value={values.jobSector}
                  size="small"
                  label="Job Sector"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(errors.jobSector)}
                  helperText={errors.jobSector}
                  name="jobSector"
                />
              </Box>
              <Box sx={{ borderTop: "1px solid #bdbdbd" }} paddingY={1} display="flex" justifyContent="flex-end" >
                <Box marginRight={1} >
                  <Button onClick={() => {
                    handleCloseJobSectorModal();
                  }}
                    disableElevation
                    variant="contained"
                    color="error"
                    disabled={showLoaderForSector}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box marginRight={1}>
                  <Button type="submit"
                    disableElevation
                    variant="contained"
                    endIcon={showLoaderForSector ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : ""}
                    disabled={showLoaderForSector}
                  >
                    {editJobSectorId ? "Update" : "Create"}
                  </Button>
                </Box>
              </Box>
            </form>
          }}
        </Formik>
      </ModalComponent >

      <SureYouWantToDeletModal
        openDeleteModal={openDeleteJobSectorModal}
        setOpenDeleteModal={setOpenDeleteJobSectorModal}
        modalTitle="Delete Job Sector"
        dialogBody={`Are you sure you want to delete ${initialStateForJobSector.jobSector}`}
        onYesClick={() => {
          setShowLoaderForSector(true);
          handleDeleteJobSector();
        }}
        endIcon={showLoaderForSector ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : ""}
        disabled={showLoaderForSector}
      />

      <ModalComponent
        openModal={openDepartmentModal}
        setOpenModal={setOpenDepartmentModal}
        modalTitle={departmentId ? "Update Department" : "Create Department"}
        modalWidth="35%"
        handleOnCloseBtnClick={() => {
          handleCloseDepartmentModal();
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialStateForDepartment}
          validationSchema={validationSchemaForDepartment}
          onSubmit={(values) => {
            setShowLoaderForDepartement(true);
            handleDepartmentSubmit(values);
          }}
        >
          {({ errors, values, handleBlur, handleChange, handleSubmit }) => {

            return <form onSubmit={handleSubmit} noValidate>
              <Box paddingX={2} paddingY={2} >
                <TextField
                  value={values.department}
                  size="small"
                  label="Department"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(errors.department)}
                  helperText={errors.department}
                  name="department"
                />
              </Box>
              <Box sx={{ borderTop: "1px solid #bdbdbd" }} paddingY={1} display="flex" justifyContent="flex-end" >
                <Box marginRight={1} >
                  <Button onClick={() => {
                    handleCloseDepartmentModal();
                  }}
                    disableElevation
                    variant="contained"
                    color="error"
                    disabled={showLoaderForDepartement}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box marginRight={1}>
                  <Button
                    type="submit"
                    disableElevation
                    variant="contained"
                    endIcon={showLoaderForDepartement ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : ""}
                    disabled={showLoaderForDepartement}
                  >
                    {departmentId ? "Update Department" : "Create Department"}</Button>
                </Box>
              </Box>
            </form>
          }}
        </Formik>
      </ModalComponent>

      <SureYouWantToDeletModal
        openDeleteModal={openDeleteModalOfDepartment}
        setOpenDeleteModal={() => {
          setDepartmentId("");
          setOpenDeleteModalOfDepartment(false);
        }
        }
        modalTitle="Delete Department"
        dialogBody={`Are you sure you want to delete ${initialStateForDepartment.department} department`}
        onYesClick={() => {
          setShowLoaderForDepartement(true);
          handleDeleteDepartment();
        }}
        endIcon={showLoaderForDepartement ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : ""}
        disabled={showLoaderForDepartement}
      />
    </Box >) : (<Unauthorized />)

  );
};

export default Home;
