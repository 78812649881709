import { Alert, Snackbar } from '@mui/material'
import React, { useContext } from 'react'
import { GlobalContext } from '../GlobalStateProvide/GlobalStateProvider';

const GlobalSnackBar = () => {

    const {openSnackBar, snackBarMessage, snackBarSeverity, setOpenSnackBar} = useContext(GlobalContext);


  return (
    <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackBar}
        autoHideDuration={2500}
        onClose={() => setOpenSnackBar(false)}
    >
        <Alert severity={snackBarSeverity} >{snackBarMessage}</Alert>
</Snackbar>
  )
}

export default GlobalSnackBar