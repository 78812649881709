import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './backBtn.scss'

const BackBtn = () => {
  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <ArrowBackIcon className="backBtn" onClick={handleBackClick} />
  );
};

export default BackBtn;