
import * as React from 'react';
import "./announcement.scss";

import { Box, FormGroup, FormControlLabel, Switch, Tooltip, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState, useContext, useEffect } from "react";
import { getRequestWithToken, patchRequestWithToken } from "../../utils/ApiRequests";
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import { GlobalContext } from "../../components/GlobalStateProvide/GlobalStateProvider";
import { checkPermission } from '../../utils/userPermissions'
import Unauthorized from "../../components/unauthorized/Unauthorized";
import { useNavigate } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import WorkIcon from '@mui/icons-material/Work';
import WorkOffIcon from '@mui/icons-material/WorkOff';

const JobSevaAllJobs = () => {
  const [allJobsData, setAllJobsData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const { handleSnackBar } = useContext(GlobalContext);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loadingDataInGrid, setLoadingDataInGrid] = useState(true);

  const [canCreate, setCanCreate] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canRead, setCanRead] = useState(false);

  const [isAccess, setisAccess] = useState([]);
  const [isIncludesValue, setisIncludesValue] = useState(null);
  const navigate = useNavigate();

  const handleAction = async (row, btnName) => {
    setLoadingDataInGrid(true);
    try {
      let api_url;
      if (btnName === 'managed')
        api_url = `jobs/toggle-ismanaged?job_id=${row?.job_id}&action=${!row?.is_managed}`;

      if (btnName === 'featured')
        api_url = `jobs/toggle-isfeatured?job_id=${row?.job_id}&action=${!row?.is_featured}`;

      let res = await patchRequestWithToken(api_url)

      if (res.data.status === 'Success') {
        handleSnackBar(true, res.data.msg, "success");
        setAllJobsData((prev) => {
          let res = prev?.map(prevjob => {
            if (prevjob?.job_id === row?.job_id) {
              return ({
                ...prevjob,
                is_managed: btnName === "managed" ? !prevjob.is_managed : prevjob.is_managed,
                is_featured: btnName === "featured" ? !prevjob.is_featured : prevjob.is_featured
              })
            } else return prevjob;
          })
          return res;
        })
        setLoadingDataInGrid(false);
      }
      else {
        handleSnackBar(true, res.data.msg, "error");
        setLoadingDataInGrid(false);
      }
    } catch (err) {
      handleSnackBar(true, err?.response?.data?.msg, "error");
      setLoadingDataInGrid(false);
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: 'S.No.',
      width: 100,
      renderCell: (params) => {

        let addNumber = pageNumber * pageSize;
        let rowIndex = params.api.getRowIndex(params.row._id);
        return (<span>{rowIndex + 1 + addNumber}</span>)
      },
    },
    {
      field: 'job_id',
      headerName: 'Job Id',
      width: 100,

    },

    {
      field: 'job_role',
      headerName: 'Job Title',
      width: 300,
      renderCell: (params) => (
        <div style={{ textTransform: 'capitalize' }}>
          {params.value}
        </div>
      ),
    },

    {
      field: 'job_descriptions',
      headerName: 'Job Description',
      width: 150,
      renderCell: (params) => {
        const openDescription = () => {
          navigate("/jobseva/job-description", {
            state: params?.row?.job_descriptions[0],
          });
        }
        return (<span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={openDescription}>view</span>)
      },
    },

    {
      field: 'state',
      headerName: 'State',
      width: 200,
    },


    {
      field: 'city',
      headerName: 'City',
      width: 200,
    },

    {
      field: 'is_managed',
      headerName: 'Managed',
      width: 200,
      renderCell: (params) => {

        return (<span>{params?.row?.is_managed ? "Yes" : "No"}</span>)
      },
    },
    {
      field: 'is_featured',
      headerName: 'Featured',
      width: 200,
      renderCell: (params) => {

        return (<span>{params?.row?.is_featured ? "Yes" : "No"}</span>)
      },
    },

    {
      field: 'apply_candidate_count',
      headerName: 'View Candidate',
      width: 200,
      renderCell: (params) => {
        return (<span>{params?.value} {params.value !== 0 && <span style={{ color: "blue", textTransform: "underline", cursor: "pointer" }}
          onClick={() => navigate("/create-new-task",
            {
              state: params.row,
            })}> View</span>}</span>)
      }
    },

    {
      field: 'createdAt',
      headerName: 'Date & Time',
      width: 250,
      renderCell: (params) => {
        let createdAt = "--";
        if (params?.row?.createdAt)
          createdAt = params?.row?.createdAt;
        return (<span>{new Date(createdAt).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}</span>)
      },
    },

    {
      field: 'company_name',
      headerName: 'Company Name',
      width: 300,
    },
    (canUpdate || canCreate) && {
      field: 'action',
      headerName: 'Action',
      width: 250,
      renderCell: (params) => {
        return (
          <Box style={{ display: "flex", gap: "2rem" }}>

            <Tooltip title={params?.row?.is_managed ? "Mark Unmanaged" : "Mark Managed"} placement="top">
              <IconButton onClick={() => {
                handleAction(params?.row, "managed")
              }} >
                {params?.row?.is_managed ? <WorkOffIcon color="error" /> : <WorkIcon color="primary" />}
              </IconButton>
            </Tooltip>

            <Tooltip title={params?.row?.is_featured ? "Mark Unfeatured" : "Mark Featured"} placement="top">
              <IconButton onClick={() => {
                handleAction(params?.row, "featured")
              }} >
                {params?.row?.is_featured ? <StarBorderIcon color="error" /> : <StarIcon color="primary" />}

              </IconButton>
            </Tooltip>
          </Box >
        );
      },

    }

  ]

  const getAllJobs = async () => {
    try {
      let response = await getRequestWithToken(`jobs/jobseva-jobs?page=${parseInt(pageNumber + 1)}&limit=${pageSize}`);
      setAllJobsData(response.data.data);
      setRowCount(response.data.totalCount);
      setLoadingDataInGrid(false);
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.message || error?.message, "error");
      setShowLoader(false);
      setLoadingDataInGrid(false);

    }
  }

  const checkPermissionFunc = () => {
    const resourceToUpdate = "Jobseva";
    const permissions = checkPermission(resourceToUpdate);
    setCanCreate(permissions.canCreate)
    setCanDelete(permissions.canDelete)
    setCanUpdate(permissions.canUpdate)
    setCanRead(permissions.canRead)
  }

  const againCheckPermissions = () => {
    const checkAccesInArray = localStorage.getItem("resourceNames");
    setisAccess(checkAccesInArray);
    isIncludes();
  }

  const isIncludes = () => {
    setisIncludesValue(isAccess.includes("Jobseva"))
  }

  useEffect(() => {
    getAllJobs();
    checkPermissionFunc();
    againCheckPermissions();
  }, [isIncludesValue, pageSize, pageNumber])

  /*Toogle Filter State*/
  const [showFeaturedJob, setShowFeaturedJob] = useState(false);
  const [showManagedJob, setShowManagedJob] = useState(false);
  const [showAllJob, setShowAllJob] = useState(true);
  /*Show Job Toggle Button*/
  const handleShowFeaturedJobToggle = () => {
    setShowAllJob(false)
    setShowFeaturedJob(!showFeaturedJob)
  }

  const handleShowManagedJobToggle = () => {
    setShowAllJob(false)
    setShowManagedJob(!showManagedJob)
  }

  const handleAllJobToggle = () => {
    setShowAllJob(!showAllJob)
    setShowFeaturedJob(showAllJob)
    setShowManagedJob(showAllJob)

  }

  const filteredRows = allJobsData.filter(job => {
    if (showAllJob)
      return allJobsData;
    else
      return job?.is_managed === showManagedJob && job?.is_featured === showFeaturedJob;
  });

  return (
    (isIncludesValue) ? <Box className="jobseva-jobs-section">
      <Box className="jobseva-job-section ">
        <h3>JobSeva / All Jobs</h3>
        <FormGroup className="jobseva-job-button-sections">
          <FormControlLabel control={<Switch checked={showAllJob} onChange={handleAllJobToggle} />} label="All Jobs" labelPlacement="start" />
          <FormControlLabel control={<Switch checked={showFeaturedJob} onChange={handleShowFeaturedJobToggle} />} label="Show Featured jobs" labelPlacement="start" />
          <FormControlLabel control={<Switch checked={showManagedJob} onChange={handleShowManagedJobToggle} />} label="Show Managed jobs" labelPlacement="start" />
        </FormGroup>
      </Box>
      <Box className="jobs-section-data">
        {showLoader ? <ReusableLoader /> : <DataGrid
          // rows={allJobsData}
          rows={filteredRows}
          columns={columns}
          getRowId={(allJobsData) => allJobsData._id}
          paginationMode="server"
          pagination
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          page={pageNumber}
          pageSize={pageSize}
          onPageChange={(newPage) => { setLoadingDataInGrid(true); setPageNumber(newPage) }}
          onPageSizeChange={(newPageSize) => { setLoadingDataInGrid(true); setPageSize(newPageSize); }}
          loading={loadingDataInGrid}
          rowCount={rowCount}
        />}
      </Box>
    </Box> : (<Unauthorized />)

  )
}

export default JobSevaAllJobs;