import { Box, Button, Radio, RadioGroup, FormControl, FormControlLabel, Container, TextField, Snackbar, Alert, Grid, ThemeProvider, InputLabel, Select, MenuItem } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { CandidateKycValidationSchema } from '../../utils/Validations';
import { patchRequestWithToken, postRequest, getRequestWithToken, getRequest } from '../../utils/ApiRequests';
import { useContext, useEffect, useRef, useState, } from 'react';
import "./candidates.scss";
import { useLocation, Link, useNavigate, json } from 'react-router-dom';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import FocusError from '../../components/FocusError/FocusError';
import ReusableLoader from "../../components/ReusableLoader/ReusableLoader";
import BackBtn from '../../components/backButton/BackBtn';

const CandidateKyc = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dataById, setDataById] = useState([])
  const [jsDataById, setJsDataById] = useState([])
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(true);
  const [candidateUUID, setCandidateUUID] = useState(true);
  const [jobSahayakUUID, setJobSahayakUUID] = useState("");
  const [filteredJsData, setFilteredJsData] = useState([]);

  const [selectedSahayakData, setSelectedSahayakData] = useState(null);

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    pincode: "",
    state: "",
    city: "",
    jobshayakName:" "

  });
  const [editForm, setEditForm] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);

  const scrollTargetRef = useRef(null);
  const navigate = useNavigate();

  const {candidateId ,_id} = location.state;

  const handleSubmit = async (values, { resetForm }) => {
    setFormSubmitted(true);
    let formData = new FormData();
    formData = editForm ? {
      usertype: "job_seeker",
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      pincode: values.pincode,
      mobile: values.mobile_number,
      state: values.state,
      city: values.city,
      whatsapp_available: values.whatsapp_available,
      email: values.email_address,

    } : {
      usertype: "job_seeker",
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      pincode: values.pincode,
      mobile: values.mobile_number,
      state: values.state,
      city: values.city,
    }
    let kycRequired = false;
    
    try {
      let res = await patchRequestWithToken(`candidate/assign-jobsahayak-send-jobseva?candidateUUID=${dataById.id}&jobSahayakUUID=${values.jobshayakName}&kycRequired=${kycRequired}`);

      if (res.data.status === 'Success') {
        setFormSubmitted(false);
        if (!editForm) {
          resetForm()
          if (scrollTargetRef.current) {
            scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
          }
          handleSnackBar(true, res.data.msg, "success");

        }
        else {
          handleSnackBar(true, res.data.msg, "success");
          setTimeout(() => {
            navigate(-1);
          }, 1000);

        }

      }
      else {
        setFormSubmitted(false);
        handleSnackBar(true, res.data.msg, "error")
      }
    } catch (err) {
      setFormSubmitted(false);
      handleSnackBar(true, err?.response?.data?.msg, "error");
    }
  }

  const getCandidateById = async () => {
    try {
      let candidate_id = candidateId ? candidateId :_id 
      let response = await getRequestWithToken(`admin/userbyid/?usertype=job_seeker&userid=${candidate_id}`);
      setDataById(response.data.data);
      setCandidateUUID(response.data.data.id)
      setShowLoader(false);
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
      setShowLoader(false);
    }
  }


  const getAllJobSahayak = async () => {
    let [lng,lat] = location.state?.candidate?.location?.coordinates;
    try {
      let response = await getRequestWithToken(`admin?usertype=jobsahayak&page=1&limit=500&lat=${lat?.toFixed(2)}&lng=${lng?.toFixed(2)}`);
      if (response.data.status === "Success") {
        setJsDataById(response.data.data);
        let allJsData = response.data.data;
        const filteredJSData = allJsData.map(({ id, first_name, middle_name, last_name, location ,distance }) => ({
          id,
          first_name,
          middle_name,
          last_name,
          location,
          distance
        }));
        setFilteredJsData(filteredJSData);
      }
    }
    catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error?.message, "error");
    }

  }

  useEffect(() => {

    if (location.state?._id) {
      getCandidateById();
      getAllJobSahayak();
    }
    else {
      setShowLoader(false);
    }

    if (location.state?._id) {
      const { candidate = {}, deleted = {} } = dataById;
      const { first_name = "",
        middle_name = "",
        last_name = "",
        pincode = "",
        mobile = "",
        state = "",
        city = "",
        job_sahayak_uuid,

      } = candidate;
      setInitialValues({
        first_name,
        middle_name,
        last_name,
        pincode: pincode,
        mobile_number: mobile,
        state,
        city,
        jobshayakName: job_sahayak_uuid 

      })
      setEditForm(true);
    } else {

      setInitialValues({
        first_name: "",
        middle_name: "",
        last_name: "",
        pincode: "",
        mobile_number: "",
        state: "",
        city: "",
        jobshayakName:" "
      });
      setEditForm(false);
    }
    ;
  }, [location.state, setInitialValues, dataById._id, candidateUUID])

  useEffect(()=>{
    let selectedName = location.state?.candidate?.job_sahayak_uuid;
    const sahayak = filteredJsData.find(job => job.id === selectedName);
    setSelectedSahayakData(sahayak);
  },[initialValues?.jobshayakName,filteredJsData])
  
  return (

    <>
      {showLoader ? <ReusableLoader /> : <Box className="create-candidates-section" ref={scrollTargetRef}>

        <Container>
          <Box className="create-candidates">
            <Box className="headingPlusBackBtn">
              <BackBtn />
              <h1 className='formHeadings' id="topEle" >{`JobSeva / ${!initialValues.jobshayakName ? "Assign Job Sahayak" : "Reassign Job Sahayak"}` }</h1>
            </Box>

            <Box className="create-candidates-form-wrapper">
              <Formik
                initialValues={initialValues}
                validationSchema={CandidateKycValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, values, setFieldValue, resetForm }) => (

                  <Form className="create-candidates-form">

                    <Box className="backgroundStyle">
                      <p className='formSubSectionHeading'>Candidate Details</p>
                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control" >
                            <label className="form-label" htmlFor="first_name"> Candidate Name </label>
                            <Field id="first_name"
                              type="text" placeholder="Enter First Name"
                              name="first_name"
                              className="form-input" size="small"
                              disabled
                            />
                            {errors.first_name && <Box className="form-error">{errors.first_name}</Box>}

                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="mobile_number">Mobile Number </label>
                            <Field id="mobile_number"
                              type="text"
                              placeholder="Enter mobile number"
                              name="mobile_number"
                              className="form-input"
                              disabled
                            />
                            {errors.mobile_number && <Box className="form-error">{errors.mobile_number}</Box>}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} className='cstmFormGridEmployer'>
                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="pincode"> Pincode </label>
                            <Field id="pincode"
                              type="text" placeholder="Enter pincode"
                              name="pincode"
                              className="form-input"
                              disabled
                            />
                            {errors.pincode && <Box className="form-error">{errors.pincode}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="city"> City </label>
                            <Field id="city"
                              type="text" placeholder="Enter city"
                              name="city"
                              className="form-input"
                              disabled
                            />
                            {errors.city && <Box className="form-error">{errors.city}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="state"> State </label>
                            <Field id="state"
                              type="text" placeholder="Enter state"
                              name="state"
                              className="form-input"
                              disabled
                            />
                            {errors.state && <Box className="form-error">{errors.state}</Box>}
                          </Box>
                        </Grid>

                      </Grid>
                    </Box>
                    <Box className="backgroundStyle">
                      <p className='formSubSectionHeading'>Job Sahayak Details</p>
                      <Grid container spacing={2} className='cstmFormGridEmployer'>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jobshayakName">
                              Job Sahayak Name <span className='must-fill-fields'>*</span>
                            </label>

                            <FormControl name="jobshayakName" fullWidth size="small">
                              <Select
                                placeholder='Please Select Jobshayak'
                                name="jobshayakName"
                                id="jobshayakName"
                                value={values.jobshayakName}
                                onChange={(event) => {
                                  const selectedName = event.target.value;
                                  setFieldValue("jobshayakName", selectedName);

                                  // Find the selected sahayak data from the filtered data
                                  const sahayak = filteredJsData.find(job => job.id === selectedName);
                                  setSelectedSahayakData(sahayak);
                                }}
                              >  
                              <MenuItem value=" ">Select JobShayak</MenuItem>
                                {filteredJsData.map((job, index) => (
                                  <MenuItem key={index} value={job.id}>
                                    {`${job.first_name} ${job.middle_name ? job.middle_name : ''} ${job.last_name ? job.last_name : ''}  -`}
                                    <span style={{ color: "green",marginLeft:"2px" }}>{`${job.distance.toFixed(2)} km`}</span>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            {errors.jobshayakName && <Box className="form-error">{errors.jobshayakName}</Box>}
                          </Box>
                        </Grid>


                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jspincode"> Pincode </label>
                            <Field id="jspincode"
                              type="text" placeholder="Enter jspincode"
                              name="jspincode"
                              className="form-input"
                              disabled
                              value={selectedSahayakData?.location.postal_code || ''}
                            />
                            {errors.jspincode && <Box className="form-error">{errors.jspincode}</Box>}
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <Box className="form-control">
                            <label className="form-label" htmlFor="jsstate"> State </label>
                            <Field id="jsstate"
                              type="text" placeholder="Enter state"
                              name="jsstate"
                              className="form-input"
                              disabled
                              value={selectedSahayakData?.location.state || ''}
                            />
                            {errors.jsstate && <Box className="form-error">{errors.jsstate}</Box>}
                          </Box>
                        </Grid>

                      </Grid>
                    </Box>

                    <Box className="form-button-section">

                        <Button className="form-button"
                          variant="contained"
                          onClick={() => {
                            resetForm();
                            navigate(-1);
                          }}
                          color="error"
                        >Cancel</Button>

                      <Button className="form-button"
                        variant="contained"
                        type="submit"
                        color="success"
                        disabled={formSubmitted}

                      >{!initialValues.jobshayakName ? "Assign Job Sahayak" : "Reassign Job Sahayak"}</Button>

                    </Box>
                    <FocusError handleSnackBar={handleSnackBar} />
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Container>


      </Box >}
    </>

  )

}
export default CandidateKyc;
