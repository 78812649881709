
import React, { useContext, useState } from 'react';
// import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
// import { TextField, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { json, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Typography, Link } from '@mui/material';
import { postRequestWithoutToken } from '../../utils/ApiRequests';
import { GlobalContext } from '../../components/GlobalStateProvide/GlobalStateProvider';
import { Formik } from 'formik';
import { loginEmailAndPasswordValidation } from '../../utils/Validations';
// import { QrCodeScannerOutlined } from '@mui/icons-material';
import './login.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    overflow: "hidden"
  },
  form: {
    width: '100%',
    maxWidth: 400,
    marginTop: theme.spacing(1),
    '& > *': {
      marginBottom: theme.spacing(1.5),
    },
  },
  button: {
    backgroundColor: "#ff671f !important"
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSnackBar } = useContext(GlobalContext);
  // const [loading, setLoading] = useState(false);
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false
  };

  const handleSubmit = async (values) => {
    try {
      const data = await postRequestWithoutToken("admin/login", {
        email: values.email, password: values.password
      })
      const { msg, status, token, accessRole } = data.data;
      if (status === "Success") {
        if (token) {
          localStorage.setItem("token", token);
          localStorage.setItem("user_type", "admin");
          // const roleDataString = JSON.stringify(roleData)
          localStorage.setItem("roleData", JSON.stringify(accessRole));
          handleSnackBar(true, msg, "success");
          // if (window.myFunctionToTrigger) {
          //   window.myFunctionToTrigger();
          // }
          navigate("/dashboard")
        } else {
          handleSnackBar(true, msg, "error");
        }
      }
    } catch (error) {
      handleSnackBar(true, error?.response?.data?.msg || error.message, "error");
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (

    <div className={classes.root}>
      <Box sx={{ padding: "10px" }}>
        <Link to="/">
          <img src="https://jobyahanp.s3.ap-south-1.amazonaws.com/images/logo/jobsyahan-logo-new.svg" width={200} alt="JY-Logo" />
        </Link>
      </Box>

      <Box className="login_outer_box">
        <Box className="gradient_border_login" sx={{ border: "none", boxSizing: "border-box", padding: "20px", borderRadius: "10px", width: { xs: "95%", sm: "80%", md: "40%", lg: "35%" } }} display="flex" justifyContent="center" alignItems="center" flexDirection="column" >

          <Typography variant="h6" style={{ color: "#060381" }} textAlign="center" >
            Admin Login
          </Typography>
          <Formik
            validationSchema={loginEmailAndPasswordValidation}
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ errors, values, setFieldValue, handleBlur, handleSubmit, handleChange, touched }) => {
              const { email, password, rememberMe } = values;
              return <form style={{ width: "100%" }} onSubmit={handleSubmit} noValidate>
                <TextField
                  name="email"
                  sx={{ marginTop: 2 }}
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  name="password"
                  sx={{ marginTop: 2 }}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  required
                  value={password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && errors.password}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
                <Box sx={{ marginTop: 1 }}>
                  <FormControlLabel
                    control={<Checkbox name="rememberMe" checked={rememberMe} onChange={() => {
                      setFieldValue("rememberMe", !rememberMe);
                    }} />}
                    label="Remember me"
                    value={rememberMe}
                  />
                </Box>
                <Box marginTop={2} >
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    className={classes.button}
                  // disabled={!isValidEmail || !isValidPassword}
                  >
                    Log In
                    {/* {loading && <CircularProgress size={24} className={classes.loader} />} */}
                  </Button>
                </Box>
              </form>
            }}

          </Formik>
          <Box sx={{ width: "100%", marginTop: 2 }} display="flex" justifyContent="flex-end" >
            <Link onClick={() => {
              navigate("/forgotpassword");
            }} fontSize="14px" className="cursor-pointer" >Forgot Password</Link>
            {/* <Link onClick={()=>{
          navigate("/changepassword");
        }} fontSize="14px" className="cursor-pointer" marginLeft={2}>Change Password</Link> */}
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default Login;